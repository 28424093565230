$(document).ready(function() {
    function changeBodyLanguage(language) {
        var languages = DisneyABC.Config.Get('languages');
        $('body').removeClass(languages.join(' '));
        $('body').addClass(language);
    };

    $('.languagebar input[name="language"]').on("change", function() {
        var language = $('.languagebar input[name="language"]:checked').val();
        changeBodyLanguage(language);
    });

    $('input[name="language_switch"]').click(function() {
        var languages = DisneyABC.Config.Get('languages');
        changeBodyLanguage($(this).attr('data-language'));
    });
});

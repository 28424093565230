var NotificationDetailModal = {
    statusYesNoOptionsModal: function() {
        var descriptionEmailDeliveryModal = ' NO';
        if($('#notification-email-delivery').is(':checked')){
            $('#modal-deliver-immediately').hide();
            $('#modal-scheduled-delivery').hide();
            descriptionEmailDeliveryModal = ' YES';
            if($('#deliver-immediately').is(':checked')){
                $('#modal-deliver-immediately').show();
            }
            if($('#schedule').is(':checked')){
                $('#modal-scheduled-delivery').show();
            }
        }
        $('#status-modal-emaildelivery').text(descriptionEmailDeliveryModal);
        $('#status-modal-appbanner').text($('#notification-appbanner').is(':checked') ? ' YES' : ' NO');
    },
    changeNotificationTitleModal: function() {
        $('#title-notification-modal').data('element-related','notification-subject');
    },
    changeTitleMessageModal: function(selectorModal,title,message) {
        var $elementTitle = $(selectorModal).find('.modal-title');
        var $elementMessage = $(selectorModal).find('.modal-body span');
        var defaultTitle = 'Saving notification...';
        var defaultMessage = 'Please fill out the Subject field in order to save a Notification';
    
        $elementTitle.text(defaultTitle);
        $elementMessage.text(defaultMessage);
    
        if(typeof title != 'undefined'){
            $elementTitle.text(title);
        }
        if(typeof message != 'undefined'){
            $elementMessage.text(message);
        }
    }
}
//Handles dispatching instructions to the Metadata controller and detecting clicks.
(function ($, document, window, undefined) {
    var Metadata = {
        triggerElement : {},
        uiTriggerElements: '[data-role="cart-metadata"][disabled!="disabled"]',
        blockingElements: '[data-role^="cart-"], .asset_categories',
        count : 0,
        scope : 'body', //change this!
        baseRequestUrl : $('[data-post-url]').attr('data-post-url') + '/metadata',

        init : function(){
            Metadata.bindButtons();
        },

        bindButtons: function () {
            // listeners determine whether we want to process a Metadata Request
            // on various events
            $(Metadata.scope).on('change', Metadata.uiTriggerElements, function(){
                if (this.tagName === 'INPUT') {
                    if ($(this).not(':radio') || $(this).is(':radio').is(':checked')) {
                        Metadata.processUiTrigger(this);
                    }
                }
                if (this.tagName === 'TEXTAREA' || this.tagName === 'SELECT') {
                    Metadata.processUiTrigger(this);
                }
            });
	        $('.datetimepicker').on('change', function () {
		        Metadata.processUiTrigger($(this).find('input'));
	        });
        },

        validateParams : function (params) {
            if (params.role !== 'metadata-purge-all' && typeof params.title_id === 'undefined' || params.title_id == ''){
                throw "must provide title_id";
            }
            return true;
        },

        urlFromParams : function (params) {
            var url = Metadata.baseRequestUrl;
            return url+'/'+params.title_id;
        },

        processUiTrigger : function (trigger){
            var params = Metadata.parseInputFromTrigger(trigger),
                url,
                requestType,
                $trigger = $(trigger);

            url  = Metadata.urlFromParams(params);
            requestType = Metadata.requestType(params);
            if (Metadata.validateParams(params)){
                Metadata.makeUpdateRequest(params, url, requestType)
            }
        },

        requestType : function (params) {
            return 'POST';
        },

        parseInputFromTrigger : function (trigger) {
            var $trigger = $(trigger);
            return {
                title_id : $trigger.attr('data-title-id'),
                metadata_key : $trigger.attr('data-metadata-key'),
                spec_id : $trigger.attr('data-spec-id'),
                metadata_value : Metadata.parseValueFromTrigger(trigger)
            };

        },
        parseValueFromTrigger : function (trigger) {
            var $trigger = $(trigger);
            if($trigger.attr('data-metadata-datatype') == "boolean" || $trigger.is(':checkbox') ){ // ignore values and true/false based on checkmark
                if(!$trigger.is(':checked')){
                    return 0;
                }
                return 1;
            }else{
                return encodeURIComponent($trigger.val());
            }
        },

        getAllUITriggerElements : function() {
            return Metadata.uiTriggerElements.replace(/\[disabled!="disabled"\]/g, '') + ",.asset_categories";
        },

        getAllBlockingElements : function () {
            return $(Metadata.blockingElements);
        },

	    makeUpdateRequest : function (data, url, requestType){
		    //set _method for legacy browsers that do not support all HTTP verbs
		    // Disable page while request completes.
		    // TODO: THIS IS A TERRIBLE WAY OF DOING THIS AND LEADS TO BAD UX.  WE SHOULD QUEUE THEIR REQUEST AND SEND IT WHEN THE LAST ONE RETURNS.

		    //because of weird change conditions when we click out of textareas we (Damon) are not blocking these for race conditions.
            //$(Metadata.getAllBlockingElements()).attr('disabled', 'true');
            window.Cart.ajaxQueueStarted();
            data._method = requestType;
            var request = $.ajax({
                url: url,
                data: data,
                type: requestType,
                dataType: 'json',
                queue: true,
                success: function() {
                    window.Cart.ajaxQueueFinished();
                }
            });

            request.done( function(data) {
                if (data.error){
                    Metadata.handleRequestFailure(data)
                }
                // Enable Page
                // TODO: THIS IS A TERRIBLE WAY OF DOING THIS AND LEADS TO BAD UX.  WE SHOULD QUEUE THEIR REQUEST AND SEND IT WHEN THE LAST ONE RETURNS.
                //$(Metadata.getAllBlockingElements()).not('[data-disabled-by-preset]').removeAttr('disabled');
            });
            request.fail( function(data) {
               Metadata.handleRequestFailure(data);
            });

        },
        broadcastEvent : function(event) {
            $('body').trigger('cartEvent', event);
        },
        handleRequestFailure : function(data) {
            console.log(data.error);
            console.log('Error updating Metadata');
        },
        handleRequestSuccess : function(data) {
            if ( data.hasOwnProperty('event') ){
                Metadata.broadcastEvent(data.event);
            }
        },
    };
    if($('#cart_form').length){ // only execute if this is the cart page
        Metadata.init();
        window.Metadata = Metadata;
    }
})(jQuery, document, window);

var VisibilityToggler = ( function( window, $, undefined ) {

  //TODO: config options -- disable/do not disable inputs. rethink variable names.
  //TODO: some kind of bind on ajax load functionality.. registered with something global and checked by ajax load class?

  init = function($toggle, $controller, displayValue){
    if (!$controller){
      throw 'Missing Controller parameter';
    }
    $controller.on('change', function(){
      update($toggle, $controller, displayValue);
    });
    update($toggle, $controller, displayValue);
  }

  calculateShowCondition = function($controller) {
    return $controller.attr('data-controller-value') ? $controller.attr('data-controller-value') : true;
  }

  bind = function(scope){
    $(scope).find('[data-visibility-based-on]').each(function(){
      var visibilityController = $(this).attr('data-visibility-based-on');
      var controllerValue = $(this).attr('data-controller-value') ? $(this).attr('data-controller-value') : true;
      VisibilityToggler.init($(this), $('[name="'+visibilityController+'"]').first(), controllerValue);
    });
  }

  update = function($toggle, $controller, displayValue) {
     var controllerValue = getInputValue($controller, $toggle);
      if (checkValue(controllerValue, displayValue)){
        $toggle.find('[data-disabled-from-hiding="true"]').prop('disabled', false);
        $toggle.show();
      } else {
        $toggle.hide();
        $toggle.find(':input').prop('disabled', true).attr('data-disabled-from-hiding', 'true');
      }
  }

  getInputValue = function($controller, $toggle){
    if ( $controller.is('input:checkbox') || $controller.is('input:radio') ){
      if ($toggle.is('[data-visibility-reverse-boolean]')){
        return ! $controller.is(':checked');
      }
      return $controller.is(':checked');
    }
    return $controller.val();
  }

  checkValue = function(currentValue, displayValue){
    if (typeof displayValue === "boolean"){
      if (currentValue){
        return true;
      }
      return false
    }
    if (typeof displayValue === "boolean"){
      if (currentValue){
        return true;
      }
      return false
    }
    if (typeof displayValue === "array"){
      if (displayValue.indexOf(currentValue) > -1){
        return true;
      }
    }
    if (currentValue === displayValue){
      return true;
    }
    return false;
  }

  // explicitly return public methods when this object is instantiated
  return {
    init : init,
    bind : bind
  };

} )( window, jQuery );

VisibilityToggler.bind('body');

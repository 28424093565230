var NotificationsInbox = (function() {
    var initDatatable = function() {
        var createColumn = function(data,title,width,searchable) {
            return { 
                'data' : data,
                'title' : title,
                'defaultContent' : '',
                'width' : width,
                'searchable': searchable == false ? searchable : true
            }
        }
        var configDatatable = {
            idDatatable : 'notifications-datatable-inbox',
            ajax : '/alerts/my-inbox',
            columns : [
                { 
                    'data' : 'subject',
                    'title' : 'Title',
                    'width' : '30%',
                    'render' : function ( data, type, row, meta ) {
                        var link = [
                            '<a',
                            'href="/alerts/inbox/id/' + row.id + '">',
                            data,
                            '</a>'
                        ].join(' ');
                        return link;
                    },
                },
                createColumn('template_name','Type','15%'),
                createColumn('updated_at','Date sent','15%'),
                createColumn('status','Status','17%')
            ]
        };

        var datatablesGeneral = DatatablesGeneral(
            configDatatable,[
                setDataToSelects,
                initFilters
            ]
        );
        
        NotificationsAdmin.notificationsInboxDatatable = datatablesGeneral.tableInitializated;   
    };

    var setDataToSelects = function() {
        $('.box-filters select.filter-notifications').each(function() {
            if($(this).data('filter-position')) {
                var select = $(this);
                NotificationsAdmin.notificationsInboxDatatable
                .columns($(this)
                .data('filter-position')).data().eq(0).unique().sort()
                .each( function ( option, j ) {
                    select.append( '<option value="' + option + '">' + option + '</option>' );
                });
                $(this).val('');
            }
        });
    };

    var initFilters = function() {
        var filterTable = function () {
            var filterPosition = $(this).data('filter-position');
            NotificationsAdmin.notificationsInboxDatatable
                .column(filterPosition).search(this.value).draw();
        };

        $('#notifications-search-title').on('keyup change', filterTable);
        $('.box-filters select.filter-notifications').on('change', filterTable);

        $.fn.dataTable.ext.search.push(
            function( settings, data, dataIndex ) {
                var element = $('#notifications-search-sentdate');
                if(!element.val()){
                    return true;
                }
                var currentDate = new Date();
                var daysToMiliseconds = 1000 * 60 * 60 * 24 * element.val();
                var pastDate = new Date((currentDate.getTime() - daysToMiliseconds));
                var dateDataTable = data[element.data('filter-position')];
                var dateTable = new Date(dateDataTable);
                if (pastDate.getTime() && dateTable.getTime() >= pastDate.getTime()) {
                    return true;
                }
                return false;
            }
        );
        $('#notifications-search-sentdate').change(function () {
            NotificationsAdmin.notificationsInboxDatatable.draw();
        });
    };

    return {
        initInbox : function() {
            initDatatable();
        }
    }
})();
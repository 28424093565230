DisneyABC.AjaxDataTables = {

    /**
     * Page object used to cache the route and
     * the datatable.
     */
    page: {
        url_prefix: '/table',
        ajax_datatables: $('.ajax-datatables')
    },

    /**
     * Function used to grab the datatables on the page and initialize them.
     * @param query_string
     * @private
     */
    _initTable: function (query_string) {
        this.page.ajax_datatables.each(function () {
            var table = $(this);

            // must be destroyed and emptied in case column amounts change
            if (table.hasClass('dataTable')) {
                table.dataTable().fnDestroy();
                table.empty();
            }
            var data_url = table.attr('data-url');
            var ajax_url = DisneyABC.AjaxDataTables.page.url_prefix + data_url;

            // allow adding variables to the data url before appending the query string
            if (data_url.indexOf("?") !== -1) {
                ajax_url += '&' + query_string;
            } else {
                ajax_url += '?' + query_string;
            }
            DisneyABC.AjaxDataTables._makeAjaxCall(ajax_url, table);
        })
    },

    /**
     * Loads table data from backend.
     * @param ajax_url
     * @param table
     * @private
     */
    _makeAjaxCall: function (ajax_url, table) {
        $.ajax({
            "url": ajax_url,
            beforeSend: function () {
                $('#search-loader').show();
            },
            complete: function () {
                $('#search-loader').hide();
            },
            "success": function (json) {
                $.fn.dataTable.ext.classes.sPageButton = 'button primary_button';
                json.stateSave = false;
                json.bStateSave = false;

                if($.isArray(json.drawCallback) && json.drawCallback.length) {
                    if(json.drawCallback.length == 1) {
                        json.drawCallback = window[json.drawCallback[0]];
                    } else {
                        json.drawCallback = window[json.drawCallback[0]][json.drawCallback[1]];
                    }
                } else {
                    delete(json.drawCallback);
                }

                table.dataTable(json);
            },
            "dataType": "json"
        });
    },

    /**
     * Calls initDatatable and drawDatatable.
     * @private
     */
    _initHandlers: function () {
        this._initDatatable();
        this._drawDatatable();
    },

    /**
     * On initialization of the datatable,
     * @private
     */
    _initDatatable: function () {
        this.page.ajax_datatables.on('init.dt', function (e, settings, data) {

            var datatables_table = '#' + $(this).attr('id');
            // get the page count minus the next and prev buttons
            var pages = $('li', $(datatables_table + '_paginate')).length - 2;

            if (typeof data.customDisplayStart !== 'undefined' && pages > 1) {
                $(this).dataTable().fnDisplayStart(parseInt(data.customDisplayStart));
            }
        });
    },

    /**
     * Draws the dataTable to the DOM
     * @private
     */
    _drawDatatable: function () {
        this.page.ajax_datatables.on('draw.dt', function () {
            var tableId = $(this).attr('id');
            var datatables_table = '#' + tableId;
            // get the page count minus the next and prev buttons
            var pages = $(this).dataTable().fnPagingInfo().iTotalPages;
            // hide the pagination and page info
            $(datatables_table + '_wrapper')
                .find('.dataTables_paginate, .dataTables_info')
                .css('display', pages <= 1 ? 'none' : 'block');
            $(datatables_table + '_wrapper').find('.dataTables_filter label input').attr("placeholder", "Search");
            $(datatables_table + '_wrapper').find('.dataTables_filter label input').addClass("form-control");
            $(datatables_table + '_wrapper').find('.dataTables_filter label input').addClass("search");

            // Create the bootstrap modal
            $('#goToPageModal_' + tableId).remove();
            html =  '<div id="goToPageModal_' + $(this).attr('id') + '" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
            html += '<form class="modal-dialog" role="document" id="goToPageModalForm_' + tableId + '">';
            html += '<div class="modal-content">';
            html += '<div class="modal-header">';
            html += '<a class="close" data-dismiss="modal">×</a>';
            html += '<h4>Go to page</h4>';
            html += '</div>';
            html += '<div class="modal-body">';
            html += '<div class="alert alert-danger" id="goToPageAlert_' + tableId + '" style="display: none;">Selected page is not within the range of available pages.</div>';
            html += '<div class="form-group"><input class="form-control" type="int" id="goToPageInput_' + tableId + '" size="20" placeholder="Page number" /></div>';
            html += '</div>';
            html += '<div class="modal-footer">';
            html += '<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>';
            html += '<button type="submit" class="btn btn-primary">Go to page</button>';
            html += '</div>';  // content
            html += '</form>';  // dialog
            html += '</div>';  // footer
            html += '</div>';  // modalWindow
            $('body').append(html);

            $('#' + tableId + '_wrapper').find('.go_to_page_button').remove();
            $('#' + tableId + '_wrapper').find('.pagination').each(function (index, item) {
                $(item).prepend('<li class="button primary_button go_to_page_button"><a href="javascript:void(0);" onclick="$(\'#goToPageModal_' + tableId + '\').modal(\'show\');">Go to...</a></li>');
            });

            $('#goToPageModalForm_' + tableId).submit(function (event) {
                event.preventDefault();

                requestedPage = $('#goToPageInput_' + tableId).val();
                $('#goToPageAlert_' + tableId).hide();

                if (requestedPage.trim() == '') {
                    $('#goToPageModal_' + tableId).modal('hide');
                    return;
                }

                if (requestedPage < 1 || isNaN(requestedPage) || requestedPage > pages) {
                    $('#goToPageAlert_' + tableId).show();
                    return;
                }

                $('#goToPageModal_' + tableId).modal('hide');
                $(datatables_table).DataTable().page(requestedPage - 1).draw( 'page' );
            });

            $('#goToPageModal_' + tableId).on('shown.bs.modal', function () {
                $('#goToPageAlert_' + tableId).hide();
                $('#goToPageInput_' + tableId).val('');
                $('#goToPageInput_' + tableId).focus();
            });

            DisneyABC.AjaxDataTables._initPopOver();
        });
        this._hideEmptyCircles();
    },

    /**
     * Calls _initTable and _initHandlers
     * @param query_string
     */
    init: function (query_string) {
        DisneyABC.AjaxDataTables._initTable(query_string);
        DisneyABC.AjaxDataTables._initHandlers();
    },

    /**
     * Inititalizes the popovers for the page.
     * @private
     */
    _initPopOver: function () {
        if ($('.popover_info').length) {
            $('tbody tr', $(this)).each(function () {
                var row = $(this);
                $('.name a', row).popover({
                    trigger: 'hover',
                    html: true,
                    title: 'Information',
                    content: $(this).find('.popover_info').html()
                });
            });
        }
    },

    /**
     * This is the 'Hide Empty Circles' checkbox
     * If checked, show only circles with users
     * If unchecked, show all circles
     */
    _hideEmptyCircles: function () {
        $("input[name='withUsers']:checkbox").click(function (e) {
            if (!$(this).prop('checked')) {
                DisneyABC.AjaxDataTables.init('form[all]=true');
            } else {
                DisneyABC.AjaxDataTables.init('');
            }
        });
    },

    /**
     * The execute function that can be called automatically or from button click.
     * @param search_form
     * @private
     */
    _searchExcecute: function (search_form) {
        $('.new-results').show();
        DisneyABC.AjaxDataTables.init(search_form);
    },

    /**
     * On Ajax complete, listen for a click and find the page number, or else set default to 1
     * and append value to the URL.
     * @param search_form
     * @private
     */
    _setURL: function (search_form) {
        $(document).ajaxComplete(function () {
            var tempURL = location.origin + DisneyABC.AjaxDataTables._slashPath() + '?' + search_form;
            $('.ajax-datatables a').on('click', function () {
                if ($('.pagination').find('.active').find('a').text().length) {
                    var pageNum = $('.pagination').find('.active').find('a').text();
                    pageNum = pageNum.slice(0, pageNum.length / 2);
                    tempURL += '&page=' + pageNum;
                    window.history.pushState({path: tempURL}, '', tempURL);
                } else {
                    tempURL += '&page=' + 1;
                    window.history.pushState({path: tempURL}, '', tempURL);
                }
            });
        });
    },

    /**
     * If the pathname has no trailing slash, add one to ensure the
     * queries work.
     * @returns {string}
     * @private
     */
    _slashPath: function () {
        var pathname = window.location.pathname;
        if (pathname.substr(pathname.length - 1) !== '/') {
            pathname += '/';
            return pathname;
        } else {
            return pathname;
        }
    },

    /**
     * If the pagination buttons are clicked, change the values in the query.
     * @private
     */
    _incrementPageNum: function (query) {
        var queryArray = query.split('&');
        var search = '';
        for ( var i = 0; i < queryArray.length -1; i++ ) {
            search += queryArray[i];
        }
        DisneyABC.AjaxDataTables._setURL(search);
    }
};

/**
 * On page load, run the search and default query load.
 */
$(document).ready( function() {
	if ($('.ajax-datatables').length) {
		DisneyABC.AjaxDataTables.init('');
	}
});

/*****
*
* CLIENT NAME FIELD
* SELECT2
*
* on page load search for a hidden input with an ID of client_name
* if found  convert it to a select2 dropdown list
*****/


var orderHead = []; // Array to hold information used to create the order header
var screener_request = []; // Array to hold order information between tabs

$(document).ready(function() {
	if ($('#screener_request_page').length > 0) {

		$( "#screener_request_form" ).submit(function( event ) {
		  event.preventDefault();
		  return false;
		});

		jQuery.validator.setDefaults({
		  debug: true,
		  success: "valid"
		});

		$( "#screener_request_form" ).validate({
			ignore: 'input[type=hidden], input[type=checkbox], input[class^=select2]'
		});

		// remove hash for page-reload
		$('.nav-tabs a').on('shown', function (e) {
		    window.location.hash = "";
		});
		//remove hash and do not preform default action.
		$('.nav-tabs a').click(function (e) {
		    window.location.hash = "";
			e.preventDefault();
		 });

		function checkForServerSuccess(data) {
			if (data !== undefined && data.success !== true) {
				var message = new Message("We have encountered an error. Please reload the page and try again.");
				message.type = 'error'; // message type
				message.title = ''; // message status
				message.showMessage();
				return false
			}
			return true;
		}


		DisneyABC.ScreenerRequest = {rowsInProgramTable:0};

		/*****
		*
		* CLIENT NAME FIELD
		* SELECT2
		*
		* on page load search for a hidden input with an ID of client_name
		* if found  convert it to a select2 dropdown list
		*****/
		$('#client_name').select2( {

			placeholder: 'Search for a client',
			minimumInputLength: 2, // min char to type before ajax request
			allowClear: false,	// allow user to clear selected item

			ajax: {
				// URL to be used in ajax request
				url: $('body').attr('data-baseurl')+'/orders/organization-list',
				dataType: 'json',

				// Format results
				data: function (term, page) {
					return {filter: term}
				},
				results: function (data, page) {
					if (checkForServerSuccess(data)) {
						return {results: data.organizations}
					} else {
						return {results: []}
					}
				}
			}
		});


		/*****
		*
		* CLIENT NAME FIELD
		* ON CHANGE
		*
		*****/
		// When the client name field changes execute this function
		$('#client_name').on('change', function() {

			// Reset Contact Name
			$('#contact_name').select2('data', null);
			$('#contactNameContainer').show();
			disableButton($('.btn-order'));

			if($('#s2id_contact_name'))
			{
				$('#s2id_contact_name').hide();
			}
			//set spinner
			$("#contactNameContainer .spinner").show();

			var client_name = $(this).val(); // get client_name value
			orderHead['client_name'] = $('#client_name').select2('data').text;

			screener_request['organization_id'] = $('#client_name').val();
			screener_request['request_type'] = $("input[name=screener_type]:radio:checked").val();

			$('.orderHead').text("Order for: "+$('#client_name').select2('data').text);

			var urlString = $('body').attr('data-baseurl')+'/orders/client-list?organization_id=' + $(this).val(); //+client_name;

			requestContact = $.ajax({
				url: urlString,
				type: 'GET',
				dataType: 'html',

				success : function (){
					// when request is complete
					requestContact.done(function(data) {

						data = $.parseJSON(data);

						if (checkForServerSuccess(data))
						{
							$('#contact_name').select2({data:data.results});
						}
						$("#contactNameContainer .spinner").hide();
					});

					// Fail
					requestContact.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
						$("#contactNameContainer .spinner").hide();
					});
				}
			});

		});

		$('body').on('click', '.btn[disabled="disabled"]', function() {
			return false;
		});

		/*****
		*
		* TITLE NAME FIELD
		* SELECT2
		*
		*****/
		// on page load search for a hidden input with an ID of title_name
		// if found  convert it to a select2 dropdown list

		$('#title_name').select2( {

			placeholder: 'Search for a title',
			minimumInputLength: 2, // min char to type before ajax request
			allowClear: false,	// allow user to clear selected item

			ajax: {
				// URL to be used in ajax request
				url: $('body').attr('data-baseurl')+'/orders/screener-title'+'?exact='+$('#exact_match').is(':checked'),
				dataType: 'json',

				// Format results
				data: function (term, page) {
					return {filter: term, exact: $('#exact_match').is(':checked')}
				},
				results: function (data, page) {
					if (checkForServerSuccess(data)) {
						return {results: data.titles}
					} else {
						return {results: []};
					}
				}
			},

			//formatResult: programFormatResult, // omitted for brevity, see the source of this page
			//formatSelection: programFormatSelection,  // omitted for brevity, see the source of this page
			escapeMarkup: function (m) { return m; }
		});


		/*****
		*
		* PROGRAM NAME FIELD
		* SELECT2
		*
		* on page load search for a hidden input with an ID of program_name
		* if found  convert it to a select2 dropdown list
		*****/
		$('#program_name').select2( {

			placeholder: 'Search for a TV Series',
			minimumInputLength: 2, // min char to type before ajax request
			allowClear: false,	// allow user to clear selected item

			ajax: {
				// URL to be used in ajax request
				url: $('body').attr('data-baseurl')+'/orders/screener-tv-series',
				dataType: 'json',

				// Format results
				data: function (term, page) {
					return {filter: term, exact : $('#exact_match_program').is(':checked') }
				},
				results: function (data, page) {
					if (checkForServerSuccess(data)) {
						return {results: data.programs}
					} else {
						return {results: []};
					}
				}
			},
			escapeMarkup: function (m) { return m; }
		});

		function addToOrder($button, suppressTableRecolouring) {
			$dataTD = $button.closest('td');
			var newId = $dataTD.attr('data-title-id');

			// If they've already added this title, don't let them readd it.
			if ($('.column-program[data-title-id="' + newId + '"]').length > 0) { return; }
			var $newRow = $('.programTV .programs-table .row-template').clone().appendTo('.programs-table tbody').css('display', '').removeClass('row-template');
			$newRow.children('.column-quantity').find('input').attr("name", "num["+newId+"]");
			$newRow.children('.column-program').text($dataTD.attr('data-program-name')).attr('data-title-id', $dataTD.attr('data-title-id'));

			$newRow.children('.column-season').text($dataTD.attr('data-season'));
			$newRow.children('.column-episode').text($dataTD.attr('data-title-name'));
			$newRow.children('.column-remove').find('a').on('click', function() {
				var titleId = $(this).closest('tr').find('.column-program').attr('data-title-id');
				var $seasonTable = $('td[data-title-id="' + newId + '"]').closest('tr').show().closest('table');
				$(this).closest('tr').remove();
				redoTableColouring($seasonTable);
				redoTableColouring($('.programs-table'));
				DisneyABC.ScreenerRequest.rowsInProgramTable -= 1;
				if (DisneyABC.ScreenerRequest.rowsInProgramTable <= 0) {
					$('.programTV .programs-table .row-placeholder').show();
					$('.to-order-details').attr('disabled', 'disabled');
				}
			});

			$newRow.children('.column-secured').on('click', function(ev) {
				$input = $(this).children('input')[0];
				if (ev.target !== $input) {
					$input.click();
				}
			});

			$button.closest('tr').hide();

			DisneyABC.ScreenerRequest.rowsInProgramTable += 1;
			$('.programTV .programs-table .row-placeholder').hide();
			$('.to-order-details').removeAttr('disabled');

			if (suppressTableRecolouring !== true) {
				redoTableColouring($button.closest('table'));
				redoTableColouring($('.programs-table'));
			}
		}

		$('body').on('click', '.add-to-order-button', function(){
			addToOrder($(this));
			setValidationRules();
		});

		$('body').on('click', '.add-all-episodes', function(){
			// Find our parent, which should then have a child that is the table with all episodes in it.
				// Then home in on the add-to-order button.
				$(this).closest('div').find('table tbody tr .add-to-order-button').each(function() {
					// Possibly slow because it will need to recalculate the style each time, but it doesn't feel slow so I'm not going to overoptomize
					if ($(this).is(':hidden') === false) {
						addToOrder($(this), true);
						redoTableColouring($(this).closest('table'));
						redoTableColouring($('.programs-table'));
					}
				});
		});

		$('#program_name').on('change', function() {
			var urlString = $('body').attr('data-baseurl') + '/orders/screener-tv-series?id=' + $(this).val();

			$('.program_name_container .spinner').show();

			requestProgram = $.ajax({
				url: urlString,
				type: 'GET',
				dataType: 'html',

					success : function (){
					// when request is complete
					requestProgram.done(function(data) {
						data = $.parseJSON(data);
						if (checkForServerSuccess(data)) {
							$('.programs-container').html(data.title_select_html);
						}
						$('.program_name_container .spinner').hide();
					});

					// Fail
					requestProgram.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
						$('.program_name_container .spinner').hide();
					});
				}
			});
		});

		/*****
		*
		* TITLE NAME FIELD
		* ON CHANGE
		*
		*****/
		// When the client name field changes execute this function

		$('#title_name').on('change', function() {
			var urlString = $('body').attr('data-baseurl') + '/orders/screener-title?id=' + $(this).val();

			$('.title_select_spinner_container .spinner').show();
			requestTitle = $.ajax({
				url: urlString,
				type: 'GET',
				dataType: 'json',

				success : function (){
					// when request is complete

					requestTitle.done(function(data) {
						console.log(JSON.stringify(data, null, 4));
						if (checkForServerSuccess(data)) {
							$('.row-placeholder').hide();
							$('#table_order > tbody:last').append('<tr data-season="'+data.season+'" data-program-id="'+data.id+'" data-title-id="'+data.id+'" data-title-name="'+data.name+'"><td data-title-id="'+data.id+'" class="column-name">'+data.name+'</td> <td class="column-banner">'+data.banner+'</td> <td class="column-release">'+data.original_release_date+'</td><td class="column-secured"><input type="checkbox" value="" class=""/></td><td class="column-quantity"><input id="num'+data.id+'" name="num'+data.id+'" value="1" class="input-mini"/></td><td><button type="button" class="pull-right btn btn-primary btn-default" onclick="removeTableRow(this)">Remove</button></td></tr>');
							redoTableColouring($('#table_order'));
							enableButton($('.to-order-details'));

							$('.to-order-details').removeClass('btn-default');
							$('.to-order-details').addClass('btn-primary');
							$('.to-order-details').removeAttr('disabled');
						}
						$('.title_select_spinner_container .spinner').hide();
						setValidationRules();
					});

					// Fail
					requestTitle.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
						$('.title_select_spinner_container .spinner').hide();
					});

				}
			});
		});

		$('#medium').on('click', function() {
			var medium_id = $(this).val();
			if (medium_id == '1') {
				$('#s2id_delivery_method_digital').show();
				$('.input-mini').attr('disabled', 'disabled');
				$('#s2id_delivery_method_physical').hide();
			} else if (medium_id == '2') {
				$('#s2id_delivery_method_digital').hide();
				$('.input-mini').removeAttr('disabled');
				$('#s2id_delivery_method_physical').show();
			} else {
				$('#s2id_delivery_method_digital').hide();
				$('.input-mini').removeAttr('disabled');
				$('#s2id_delivery_method_physical').hide();
			}
		});

		$('.to-order-details').on('click', function() {
			if($( "#screener_request_form" ).valid()){
				$('#orderDetails').empty();
				if ($('body').attr('section') == 'movieFeature') {
					$('#order-container').appendTo($('#orderDetails'));
				} else {
					$('#programs-order-container').appendTo($('#orderDetails'));
				}
			}
		});
		/*****
		*
		* CONTACT NAME FIELD
		* ON CHANGE
		*
		*****/
		// When the contact name field changes execute this function
		$('#contact_name').on('change', function() {

			$('.orderHead').text("Order for: " +
				$('#client_name').select2('data').text +
				" (" +
				$('#contact_name').select2('data').text +
				")");

			enableButton($('.btn-order'));

			var urlString = $('body').attr('data-baseurl')+'/orders/client-address?user_id=' + $(this).val(); //+client_name;
			requestContact = $.ajax({
				url: urlString,
				type: 'GET',
				dataType: 'json',

				success : function (){
					// when request is complete
					requestContact.done(function(data) {
						if (checkForServerSuccess(data))
						{
							$('#delivery_address').text(data['results']);
						}
					});

					// Fail
					requestContact.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
					});
				}
			});

		});

		function packageUpForm() {
			var data = {};
			data['titles'] = {};
			$('#order-details table tr').each(function() {
				if ($('body').attr('section') == 'movieFeature') {
					var title_id = $(this).attr('data-title-id');
					if (title_id !== undefined) {
						var quantity = $(this).find('.column-quantity input').val();
						var program = $(this).find('.column-program').text();
						var secured = $(this).find('.column-secured input').is(":checked");
						data['titles'][title_id] = {
								'quantity': $(this).find('.column-quantity input').val(),
								'name'    : $(this).find('.column-name').text(),
								'banner'  : $(this).find('.column-banner').text(),
								'release' : $(this).find('.column-release').text(),
								'secured' : $(this).find('.column-secured input').is(":checked")
							};
					}
				} else {
					var title_id = $(this).find('.column-program').attr('data-title-id');
					if (title_id !== undefined) {
						var quantity = $(this).find('.column-quantity input').val();
						var program = $(this).find('.column-program').text();
						var secured = $(this).find('.column-secured input').is(":checked");
						data['titles'][title_id] = {
								'quantity': $(this).find('.column-quantity input').val(),
								'program' : $(this).find('.column-program').text(),
								'season'  : $(this).find('.column-season').text(),
								'title'   : $(this).find('.column-episode').text(),
								'secured' : $(this).find('.column-secured input').is(":checked")
							};
					}
				}
			});
			data['due_date'] = $('#due_date').val();
			data['delivery_medium'] = $('#medium').val();
			data['delivery_method'] = $('select.delivery_method[data-medium-id="' + data['delivery_medium'] + '"]').val();
			data['delivery_address'] = $('#delivery_address').val();
			data['order_type'] = $('body').attr('section');
			data['comments'] = $('#comments').val();
			data['lob_ids'] = {};
			data['client_name'] = $('#contact_name').select2('data').text;
			data['org_name'] = $('#client_name').select2('data').text;
			$('#business_line_ids input').each(function() {
				if ($(this).is(":checked")) {
					data['lob_ids'][$(this).attr("value")] = $("label[for='"+$(this).attr('id')+"']").text();
				}
			});
			return data;
		};


		$('.toReviewOrderPage').on('click', function() {
			$('.review_order').html('');

			var urlString = $('body').attr('data-baseurl') + '/orders/screeners-email';
			var data = packageUpForm();

			$('#review-order .spinner').show();
			reviewOrder = $.ajax({
				url: urlString,
				type: 'POST',
				dataType: 'json',
				data: data,

				complete : function (data) {
					data = $.parseJSON(data.responseText);
					if (checkForServerSuccess(data)) {
						$('.review_order').html(data.email_html);
					}
					$('#review-order .spinner').hide();
				}
			});
		});

		$('#send-email').on('click', function() {

			var urlString = $('body').attr('data-baseurl') + '/orders/screeners-email';
			var data = packageUpForm();
			data['send_email'] = 1;

			$('#review-order .spinner').show();

			$('.nav.nav-tabs, .nav-buttons, h1, #review-order h3').hide();

			reviewOrder = $.ajax({
				url: urlString,
				type: 'POST',
				dataType: 'json',
				data: data,

				complete : function (data) {
					data = $.parseJSON(data.responseText);
					if (checkForServerSuccess(data)) {
						var message = new Message(data.message);
						message.type = 'success'; // message type
						message.title = ''; // message status
						message.showMessage();
					}
					$('#review-order .spinner').hide();
				}
			});
		});
	}
});


function removeTableRow(elem){
	var table = $(elem).closest('table');

	var tr = $(elem).closest("tr");
	tr.remove();
	/*

	if (tr.attr('data-title-id') !== undefined) {
		$('tr[data-title-id="' + tr.attr('data-title-id') + '"]').remove();
	} else {
		var titleId = tr.find('.column-program').attr('data-title-id');
		$('td[data-title-id="' + titleId + '"]').closest('tr').remove();
	}
	*/

	// Which table are we on?
	// One has an extra row-template row, one does not.
	// They both have a "There are no rows in this table" extra row.
	if($(table).find('.row-template').length == 0 && $(table).find('tbody tr').length <= 1 ||
		$(table).find('.row-template').length == 1 && $(table).find('tbody tr').length <= 2) {
		$('.row-placeholder').show();
		disableButton($('.to-order-details'));
		disableButton($('.toReviewOrderPage'));
	}


	redoTableColouring(table);
}

function disableButton(elem) {
	elem.removeClass('btn-primary');
	elem.addClass('btn-default');
	elem.attr('disabled', 'disabled');
}
function enableButton(elem) {
	elem.removeClass('btn-default');
	elem.addClass('btn-primary');
	elem.removeAttr('disabled');
}

function setValidationRules() {
 	$("input[name^=num]").each(function () {
        $(this).rules("add", {
            required: true,
            number: true,
            min: 1
        });
    });
}



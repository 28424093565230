/**
 * AdRestrictions is a self excecuting function that displays a disclaimer the
 * first time an asset is downloaded per login per item.
 */
DisneyABC.AdRestrictions = (function () {
    'use strict';

    /**
     * Do we want to show the debug messages in the console?
     * @type {boolean}
     */
    var debugging = false;

    /**
     * Has Ad Restrictions.
     * @type {boolean}
     */

    // not movie content
    var notModalAtAll = typeof $('.title-movie').data('adrestrictions') == 'undefined' ? true : false;
    var hasAdRestrictions = $('.title-movie').data('adrestrictions') === 1;

    /**
     * Debug to the console
     * @param message
     */
    function debug(message) {
        if (!debugging) {
            return;
        }
        console.info(message);
    }

    /**
     * Check if the current user is an admin, admins don't see this functionality
     * @returns {boolean}
     */
    function isAdmin() {
        debug('isAdmin ' + DisneyObjects.current_user.read_admin + '');
        return DisneyObjects.current_user.read_admin;
    }

    /**
     * Stores the data that AdRestriction uses
     * @type {{addIfDoesntHave, clear, doesntHave}}
     */
    var AdRestrictionAcceptances = (function () {

        /**
         * storage key
         * @type {string}
         */
        var key = 'ad-restrictions';

        /**
         * The array of data
         * @type {Array}
         */
        var data = [];

        /**
         * Load up the list
         */
        var load = function () {
            debug('AdRestrictionAcceptances - internal.load');
            data = JSON.parse(localStorage.getItem(key));
            debug(data);
            if (data === null) {
                data = [];
            }
            debug(data);
        };

        /**
         * Add a url to the list
         * @param url
         */
        var add = function (url) {
            debug('AdRestrictionAcceptances - internal.add');
            // they may have multiple tabs open
            load();
            data.push(url);
            debug(data);
            localStorage.setItem(key, JSON.stringify(data));
        };

        /**
         * Check if a value exists
         * @param url
         * @returns {boolean}
         */
        var exists = function (url) {
            debug('AdRestrictionAcceptances - internal.exists');
            load();

            if (data.length === 0) {
                return false;
            }
            for (var i in data) {
                if (!data.hasOwnProperty(i)) {
                    continue;
                }
                if (data[i] === url) {
                    debug('AdRestrictionAcceptances - internal.exists - found - ' + url);
                    return true;
                }
            }
            return false;
        };

        /**
         * Clear the data
         */
        var clear = function () {
            debug('AdRestrictionAcceptances - internal.clear');
            data = [];
            localStorage.removeItem(key);
        };

        return {
            /**
             * Add the url if it doesn't exist yet
             * @param url
             */
            addIfDoesntHave: function (url) {
                debug('AdRestrictionAcceptances - external.addIfDoesntHave');
                if (!exists(url)) {
                    add(url);
                }
            },
            /**
             * Clear the list
             */
            clear: function () {
                debug('AdRestrictionAcceptances - external.clear');
                clear();
            },
            /**
             * Check if something does not exist
             * @param url
             * @returns {boolean}
             */
            doesntHave: function (url) {
                debug('AdRestrictionAcceptances - external.doesntHave');
                return !exists(url);
            }
        };
    })();

    /**
     * Show the modal dialogue
     * @param $link
     */
    function showModal($link) {
        debug('showModal');

        var modalDiv = hasAdRestrictions ? '#ad-restriction-yes' : '#ad-restriction-no';
        var oldLink = $link.attr('href');

        if ($link.attr('old-link')) {
            debug('showModal - has old link');
            oldLink = $link.attr('old-link');
        }

        $link.attr('old-link', oldLink);

        $link.attr('href', modalDiv);

        if (hasAdRestrictions) {
            debug('showModal - content has ad restrictions');
            $(modalDiv + ' .ad-restriction-link').attr('href', adRestrictionsUrl());
            $(modalDiv + ' .accept').attr('href', oldLink);
        }
        debug('showModal - show the modal');
        $(modalDiv).modal('show');

        $(modalDiv + ' .accept').on('click', function () {
            debug('showModal - accept clicked');
            if (hasAdRestrictions) {
                debug('showModal - has ad restriction');
                $link.attr('href', $link.attr('old-link'));
                AdRestrictionAcceptances.addIfDoesntHave(window.location.pathname);
            }
            debug('showModal - hide modal');
            $(modalDiv).modal('hide');
        });
    }

    /**
     * Build the ad restrictions url from the current url
     * @returns {string}
     */
    function adRestrictionsUrl() {
        debug('adRestrictionsUrl');
        var href = /(\/\w+\/\d+)(?=\/asset\/\d+\/)/.exec(window.location.href)[0];
        return window.location.origin + '/download' + href + '/ad-restrictions';
    }

    /**
     * Check if we are on the assets page and that the asset is of the right type
     */
    function onAssetPage() {
        debug('onAssetPage');

        var adRestrictionsIgnored = {
            3: 'Disney Channel',
            4: 'Documents',
            6: 'Uncategorized'
        };

        var matches = /(\/\w+\/\d+)(?=\/asset\/(\d+)\/)/.exec(window.location.href);

        debug(matches);

        if(matches === null){
            debug('onAssetPage - false');
            return false;
        }

        debug(typeof adRestrictionsIgnored[matches[2]]);

        return typeof adRestrictionsIgnored[matches[2]] === 'undefined';
    }

    /**
     * Listen to all links in datatable and on click
     */
    function listenForDownload() {
        debug('listenForDownload');

        $('table a[href*="/downloads/"]').on('click', function (e) {
            debug('listenForDownload - click event');
            if (AdRestrictionAcceptances.doesntHave(window.location.pathname)) {
                debug('listenForDownload - should show the modal');
                if (!notModalAtAll) {
                    e.preventDefault();
                    showModal($(this));
                }
            }
        });
    }

    return {
        init: function () {
            debug('init');

            $('#login_form').on('submit', function(){
                debug('init - #login_form submit');
                AdRestrictionAcceptances.clear();
            });

            $('#active .admin').on('click', function(){
                debug('init - #active .admin click');
                AdRestrictionAcceptances.clear();
            });

            if(isAdmin()){
                debug('user is an admin, do nothing');
                return;
            }

            if (onAssetPage()) {
                listenForDownload();
                $(document).on('draw.dt', function () {
                    debug('init - draw-dt');
                    listenForDownload();
                });
            }
        }
    };
})();

$().ready(function () {
    'use strict';
    DisneyABC.AdRestrictions.init();
});

//Handles dispatching instructions to the Cart controller and detecting clicks.
(function ($, document, window, undefined) {

    function getPostUrl() {
        var postUrl = $('[data-post-url]').attr('data-post-url');
        if (!postUrl) {
            return '/cart';
        }
        return postUrl;
    }
    var Cart = {
        triggerElement : {},
        uiTriggerElements: '[data-role="cart-add"][disabled!="disabled"],[data-role="cart-remove"][disabled!="disabled"],[data-role^="cart-purg"][disabled!="disabled"]',
        blockingElements: '[data-role^="cart-"], .asset_categories',
        count : 0,
        scope : 'body', //change this!
        baseRequestUrl : getPostUrl(),
        ajaxRequests : 0,

        init : function(){
            Cart.bindButtons();
        },

        bindButtons: function () {
            // listeners determine whether we want to process a Cart Request
            // on various events
            $(Cart.scope).on('click', Cart.uiTriggerElements, function(){
                if (this.tagName === 'BUTTON' || this.tagName === 'A') {
                    Cart.processUiTrigger(this);
                }
            });

            $(Cart.scope).on('change', Cart.uiTriggerElements, function(){
                if (this.tagName === 'INPUT') {
                    if ($(this).not(':radio')) {
                        Cart.processUiTrigger(this);
                    }
                    else if ($(this).is(':radio').is(':checked')) {
                        Cart.processUiTrigger(this);
                    }
                }
            });
        },

        showInCart : function (trigger) {
            function switchButtonText(element){
                var buttonText = element.html();
                buttonText = buttonText.replace("Add to", "In");
                buttonText =  buttonText.replace("Add Title to", "In");
                element.html(buttonText);
                element.addClass('inCart');
            }
            if ( trigger.value == "addalltocart" ) { // loop through all buttons on all pages and apply the button text change
                var rows = $(".orderablelist").dataTable().fnGetNodes();
                for(var i=0;i<rows.length;i++)
                {
                    element = $(rows[i]).find('button[value="addtocart"]');
                    element.attr('disabled',true);
                    switchButtonText(element);
                }
            } else if(trigger.name == "addtocart"){ // apply button change to one item
                switchButtonText($(trigger));
            }
        },

        validateParams : function (params) {
            if (params.role !== 'cart-purge-all' && typeof params.title_id === 'undefined' || params.title_id == ''){
                throw "must provide title_id";
            }
            return true;
        },

        urlFromParams : function (params) {
            var url = Cart.baseRequestUrl;
            if (params.role === 'cart-purge')
            {
                return url + '/purge/'+ params.title_id;
            }
            if (params.role === 'cart-purge-title-assets')
            {
                return url + '/titles/' + params.title_id + '/assets';
            }
            if (params.role === 'cart-purge-title-category-assets'){
                return url + '/titles/' + params.title_id + '/categories/' + params.category_id + '/assets';
            }
            if (params.role === 'cart-purge-all'){
                return url + '/purge'
            }
            else {
                return url;
            }
        },

        processUiTrigger : function (trigger){
            var params = Cart.parseInputFromTrigger(trigger),
                url,
                requestType,
                $trigger = $(trigger);


            if ( $trigger.is(':checkbox') && ! $trigger.is(':checked') )
            {
                params.role = Cart.toggleRole(params.role);
            }

            url  = Cart.urlFromParams(params);

            requestType = Cart.requestType(params);
            if (Cart.validateParams(params)){
                Cart.makeUpdateRequest(params, url, requestType)
            }
            Cart.showInCart(trigger);
        },

        requestType : function (params) {
            if (params.role !== 'cart-add'){
                return 'DELETE';
            }
            return 'POST';
        },

        toggleRole : function (role) {
            if (role == 'cart-add'){
                return 'cart-remove';
            }  else {
                return 'cart-add';
            }
        },

        parseInputFromTrigger : function (trigger) {
            var $trigger = $(trigger);
            return {
                asset_id : $trigger.attr('data-asset-id'),
                category_id : $trigger.attr('data-category-id'),
                title_id : $trigger.attr('data-title-id'),
                role : $trigger.attr('data-role')
            };

        },

        getAllUITriggerElements : function() {
            return Cart.uiTriggerElements.replace(/\[disabled!="disabled"\]/g, '') + ",.asset_categories";
        },

        getAllBlockingElements : function () {
            return $(Cart.blockingElements);
        },

        ajaxQueueStarted : function() {
            Cart.ajaxRequests ++;
            $('[data-role="cart-submit-order"]').attr('disabled', 'disabled');
        },

        ajaxQueueFinished : function() {
            Cart.ajaxRequests --;
            if (Cart.ajaxRequests == 0) {
                $('[data-role="cart-submit-order"]').removeAttr('disabled');
            }
        },

        disableTriggerElements : function() {

            Cart.getAllBlockingElements().attr('disabled', true).addClass('cart-updating');
            Cart.getAllBlockingElements().removeClass('active');
            Cart.ajaxQueueStarted();
        },

        enableTriggerElements : function() {
            $(Cart.getAllBlockingElements()).filter('.cart-updating').not('.inCart').not('[data-disabled-by-preset]').removeAttr('disabled').removeClass('cart-updating');
            Cart.ajaxQueueFinished();

            //set the active button to presell if none is selected
            if($('.btn.btn-info.active').length === 0) {
                $("[data-spec-preset='Presell']").addClass('active');
            }
        },

        makeUpdateRequest : function (data, url, requestType){
            //set _method for legacy browsers that do not support all HTTP verbs
            // Disable page while request completes.
            // TODO: THIS IS A TERRIBLE WAY OF DOING THIS AND LEADS TO BAD UX.  WE SHOULD QUEUE THEIR REQUEST AND SEND IT WHEN THE LAST ONE RETURNS.
            $('#ajax_content').waitState();
            Cart.disableTriggerElements();

            data._method = requestType;
            var request = $.ajax({
                url: url,
                data: data,
                type: requestType,
                dataType: 'json'
            });

            request.done( function(data) {
                // Enable Page
                // TODO: THIS IS A TERRIBLE WAY OF DOING THIS AND LEADS TO BAD UX.  WE SHOULD QUEUE THEIR REQUEST AND SEND IT WHEN THE LAST ONE RETURNS.
                Cart.enableTriggerElements();
                $('#ajax_content').waitState();
                Cart.handleRequestSuccess(data);
            });

        },

        getCount : function (){
            var request = $.ajax({
                url: Cart.baseRequestUrl + '/count',
                type: 'GET',
                contentType: 'application/json; charset=utf-8',
                dataType: 'json'
            });
            request.done( function(data) {
                Cart.handleRequestSuccess(data);
            });
            request.fail( function(data) {
                Cart.handleRequestFailure(data);
            });
        },

        broadcastChange : function() {
            $('body').trigger('cartCountModified', [Cart.count]);
        },

        broadcastEvent : function(event) {
            $('body').trigger('cartEvent', event);
        },

        handleRequestSuccess : function(data) {
            if ( data.hasOwnProperty('event') ){
                Cart.broadcastEvent(data.event);
            }
            if (data.hasOwnProperty('count') && data.count != Cart.count)
            {
                Cart.count = data.count;
                Cart.broadcastChange();
            }
        },

        handleRequestFailure : function(data) {
            console.log('Error updating Cart');
        }

    };
    if($('.cart_link').length){ // only execute if there is a cart icon on the page
        Cart.init();
        window.Cart = Cart;
    }

    $(window).on('beforeunload', function(){
        if (Cart.ajaxRequests > 0) {
            return 'Your cart changes are currently being processed.';
        }
    });

})(jQuery, document, window);

(function ($, document, window, Cart, undefined) {

    var CartWidget = {
        widgetElement : '[data-role="cart-widget"]',
        counterElement : '[data-role="cart-widget-counter"]',
        labelElement : '[data-role="cart-widget-label"]',
        iconElement : '[data-role="cart-widget-icon"]',

        init : function(){
            CartWidget.$widgetElement = $( CartWidget.widgetElement );
            CartWidget.$counterElement = $( CartWidget.counterElement );
            CartWidget.$labelElement = $( CartWidget.labelElement );
            CartWidget.$iconElement = $( CartWidget.iconElement );
            CartWidget.$labelElement.hide();
            CartWidget.bindEvents();
            //cart getCount will fire a counter change event
            window.Cart.getCount();
        },

        updateCounter : function(count){
            //set cat counter value
            $(CartWidget.$widgetElement).find(CartWidget.$counterElement).text(count);

            // if the cart count is greater then zero then show label
            if(count > 0) {
                CartWidget.$widgetElement.attr("href", "/cart");
                CartWidget.$widgetElement.css("cursor", "pointer");

                CartWidget.$labelElement.show();
            } else {
                CartWidget.$widgetElement.attr("href", "");
                CartWidget.$widgetElement.css("cursor", "default");
                CartWidget.$labelElement.hide();
            }
        },
        bindEvents : function(){
            $('body').on('cartCountModified', function(e, cartCount){
                CartWidget.updateCounter(cartCount);
            });
        }
    };
    if($('.cart_link').length){ // only execute if there is a cart icon on the page
        CartWidget.init();
        window.CartWidget = CartWidget;
    }
})(jQuery, document, window);

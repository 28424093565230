$().ready(function() {
    'use strict';
    /**
     * The invited user table
     * @type {any}
     */
    var $invited = $('#invited');
    /**
     * Contains the text from the copy invitation button (first one that is clicked)
     * @type {string}
     */
    var copyInvitationText = '';

    /**
     * Add an invitation
     */
    $('#add_invitation').on('click', function() {
        // ajax url
        var urlString = DisneyABC.add_invitation;

        // get user id from btn
        var userId = $(this).data('userid');

        // get email from btn
        var email = $(this).data('email');

        // get originating org id from btn
        var orgId = $(this).data('circleid');

        // get org ids from btn (invitation circle list will not work without this)
        var orgIds = $('#add-multiple-circles').val();

        // get override state
        var override = $('#override').is(':checked') || false;

        var request = $.ajax({
            url: urlString,
            data: {
                'email': $(email).val(),
                'state': 'pending',
                'user_id': userId,
                'circle_id': orgId,
                'circle_list': orgIds,
                'override': override
            },
            type: 'POST', // type POST
            dataType: 'html'
        });

        // when request is complete
        request.done(function(data) {

            data = $.parseJSON(data);
            if (data.status === 'success') {

                $('#invited tr:last').after(data.view);

                var message = new Message(data.message);
                message.type = data.status;
                message.title = data.status;
                message.showMessage();

            } else {

                var message = new Message(data.message);
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
            }

        });
    });


    $('#invitation-search-results').on('click', '.resend_invitation', function() {
        //@TODO: add url to invitations
        var $row = $(this).parents('tr');
        var urlString = DisneyABC.resend_invitation;
        var userId = $(this).attr('data-token');

        // get user id from btn
        var token = $(this).data('token');

        // this user may need to set their email first
        if ($(this).data('allow')) {
            $(this).addClass('disabled');
            var request = $.ajax({
                url: urlString,
                data: {
                    'token': token
                },
                type: 'PUT',
                dataType: 'html'
            });

            //when request is complete
            request.done(function(data) {
                data = $.parseJSON(data);
                var message = new Message(data.message);
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
                $row.find('em').hide();
                $row.removeClass('expired-invitation');
                $('#invited' + userId).find('.resend_invitation').removeClass('disabled');
            });

            request.fail(function(jqXHR, data) {
                var message = new Message(data);
                message.title = 'Request failed';
                message.showMessage();
                $row.find('.resend_invitation').removeClass('disabled');
            });
        } else {
            $(this).parent().append('<br /><span style="color:red;">Please save an email address in your profile settings.</span>')
        }
    });

    /**
     * Revoke the invitation
     */
    $('#invitation-search-results').on('click', '.revoke_invitation', function() {
        // ajax url
        var urlString = DisneyABC.resend_invitation;

        // get token from btn
        var token = $(this).data('token');

        // get orgId from btn
        var orgId = $(this).data('orgId');

        var request = $.ajax({
            url: urlString,
            data: {
                'id': token,
                'circle_id': orgId
            },
            type: 'DELETE',
            dataType: 'html'
        });

        request.done(function(data) {
            data = $.parseJSON(data);
            var message = new Message(data.message);
            message.type = data.status;
            message.title = data.status;
            message.showMessage();

            if (data.status.toLowerCase() === 'success') {
                $('#invited' + data.token).remove();
                if (!$('#invited tr').length) {
                    $invited.replaceWith('<p>There are no pending invitations.</p>')
                }
            }
        });

        request.fail(function(jqXHR, textStatus) {
            var message = new Message(textStatus);
            message.title = 'Request failed';
            message.showMessage();

            //Remove invited user
            // @todo: this cannot be right
            $('#invited' + token).remove();
        });
    });

    /**
     * Copy Invitations.
     *
     * Copies invitation to clipboard
     */
    $('#invitation-search-results').on('click', '.copy-invitation', function() {
        if (copyInvitationText === '') {
            copyInvitationText = $(this).html();
        }

        var button = $(this);
        var inviteId = button.data('inviteid');
        var invitation = $('#invitation-text-' + inviteId);

        // need to display textarea to select and copy to clipboard
        invitation.removeClass('hide');

        var invitationText = document.querySelector('#invitation-text-' + inviteId);
        invitationText.select();
        document.execCommand('copy');

        invitation.addClass('hide');
        button.html('Copied!').addClass('copied-invitation');

        setTimeout(function() {
            button.html(copyInvitationText).removeClass('copied-invitation');
        }, 2000);
    });
});

// in the shopping cart
(function ($, document, window, DisneyABC, moment, undefined) {
    'use strict';
    var baseRequestUrl = $('[data-post-url]').attr('data-post-url');
    $.fn.extend({
        slideUpAndRemove : function(callback) {
            this.slideUp(400, function() {
                $(this).remove();
                if (callback !== undefined) {
                    callback();
                }
            });
        }
    });

    function checkForEmptyCart() {
        if ($('.cart-row').length <= 0) {
            $('#empty_cart').show();
            $('#cart_form').hide();
        }
    }
    var

        /**
         * assuming we are on the cart view page, get the current displayed page number
         * @returns {int}
         */
        getCurrentPage = function() {
            var $cartPaginator = $('[data-role="paginator-cart"]');
            if ($cartPaginator.length){
                return $cartPaginator.attr('data-page-number');
            }
            return 1;
        },

        /**
         * show / hide categories and assets
         * used on title assets bar in the cart, show the category list for 'select assets'
         *
         * @param val
         * @param title_id
         */
        checkType = function (val, title_id, adjust_checkboxes) {
            var $category_list = $('#category_list_' + title_id);
            if (val === 'some') {
                if (adjust_checkboxes) {
                    $('#title_' + title_id + ' .asset_categories.no_assets').prop('checked', true);
                }
                $category_list.slideDown();
            } else { // hide categories and assets for this title
                $category_list.find('table tr').removeClass('active');
                $category_list.slideUp(); // close categories
                var $category_asset_list = $('#category_asset_list_' + title_id);
                $category_asset_list.slideUp(); // close assets
            }
        },

        checkRemoveSpecBtns = function (title_id) {
            var numSpecs = $('[data-role="title-spec-metadata"][data-title-id="'+title_id+'"]').length,
                $removeBtns = $('[data-role="cart-removeSpec"][data-title-id="'+title_id+'"]');

            if ( numSpecs === 1) {
                $removeBtns.closest('div').hide();
            }
            if ( numSpecs > 1) {
                $removeBtns.closest('div').filter(':hidden').show();
            }
        },

        /**
         *
         * @param page
         * @returns {boolean}
         */
        initCart = function (page) {
            $.ajax({
                url : DisneyABC.baseUrl + baseRequestUrl + '?page=' + page,
                data : {},
                type : 'GET',
                dataType : 'json',
                success : function (data) {
                    $('#ajax_content').html(data.view);
                },
                fail : function (jqXHR, textStatus, error) {f
                    var message = new Message(error);
                    message.showMessage();
                }
            });
        },

        newSpec = function (title_id) {
            $('#ajax_content').waitState();
            window.Cart.disableTriggerElements();
            $.ajax({
                url : DisneyABC.baseUrl + baseRequestUrl + '/add-title-spec/' + title_id,
                type: 'POST',
                dataType : 'json',
                queue : true,
                success : function (data) {
                    $('[data-role="title-metadata-list"][data-title-id="' + title_id + '"]').append(data.view);
                    DisneyABC.SetupPlugins('[data-role="title-metadata-list"][data-title-id="' + title_id + '"]');
                    VisibilityToggler.bind('[data-role="title-metadata-list"][data-title-id="' + title_id + '"]')
                    checkRemoveSpecBtns(title_id);
                    $('#ajax_content').waitState();
                    window.Cart.enableTriggerElements();
                },
                fail : function() {
                    console.log('Error updating Cart');
                }
            });
        },

        removeSpec = function (title_id, spec_id) {
            if ( $('[data-role="title-spec-metadata"][data-title-id="'+title_id+'"]').length < 2 ) {
                throw 'title must have at least one spec';
            }
            $('[data-role="title-spec-metadata"][data-title-id="'+title_id+'"][data-spec-id="'+spec_id+'"]').remove();
            checkRemoveSpecBtns(title_id);
            $.ajax({
                url : DisneyABC.baseUrl + baseRequestUrl + '/remove-title-spec/' + title_id + "/" + spec_id,
                queue : true,
                type : 'DELETE',
                dataType : 'json',
                success : function (data) {
                    checkRemoveSpecBtns(title_id);
                },
                fail : function() {
                    console.log('Error updating Cart');
                }
            });
        },

        bulkApplyTitle = function (title_id) {
            $('#ajax_content').waitState();
            window.Cart.disableTriggerElements();
            $.ajax({
                url : DisneyABC.baseUrl + baseRequestUrl + '/bulk-apply-metadata/' + title_id,
                type: 'POST',
                data: {'page' : getCurrentPage()},
                dataType : 'json',
                queue : true,
                success : function (data) {
                    $('#ajax_content').html(data.view);
                    window.Cart.enableTriggerElements();
                    DisneyABC.SetupPlugins('#ajax_content');
                    VisibilityToggler.bind('#ajax_content');
                    $('#ajax_content').waitState();
                },
                fail : function (jqXHR, textStatus, error) {
                    console.log('Error updating Cart');
                }
            });
        },

        changePreset = function (title_id, spec_id, preset_key) {
            var elemSelector = '[data-role="title-spec-metadata-form"][data-title-id="' + title_id + '"][data-spec-id="' + spec_id + '"]',
                $container = $(elemSelector),
                params = {
                    'preset_key': preset_key,
                    'title_id': title_id,
                    'spec_id': spec_id
                };
            $container.waitState();
            window.Cart.disableTriggerElements();
            $.ajax({
                url : DisneyABC.baseUrl + baseRequestUrl + '/change-preset/',
                type: 'POST',
                data: params,
                dataType : 'json',
                queue : true,
                success : function (data) {
                    $container.html(data.view);
                    window.Cart.enableTriggerElements();
                    DisneyABC.SetupPlugins(elemSelector);
                    VisibilityToggler.bind(elemSelector);
                    $container.waitState();
                    if (preset_key == 'Presell'){
                        $('[data-category-name="trailers"][id^="asset_categories_some_' + title_id + '"]').click();
                    }
                },
                fail : function (jqXHR, textStatus, error) {
                    console.log('Error updating Cart');
                }
            });
        },

        checkDate = function (date_field) {
            var $dateField = $(date_field),
                title_id = $dateField.attr('data-title-id'),
                spec_id = $dateField.attr('data-spec-id'),
                $messageElem = $('[data-role="validation-message"][data-target="requested_delivery_date['+title_id+'_'+spec_id+']"]'),
                dateLimit = $dateField.attr('data-valid-start-date'),
                setDate = $dateField.val();
            if ( moment(setDate, 'MM/DD/YYYY').unix() < moment(dateLimit).unix() ){
                $messageElem.show();
            } else {
                $messageElem.hide();
            }
        },

        asset_cart = $('.asset_cart');


    if (asset_cart.length) {
        //submit button outside of form
        $('.subnavbar').on('click', '.cart-form-submit', function(){
            $('#cart_form').submit();
        });

        $('body').on('cartEvent', function(e, event){
            if (event === 'purged'){
                $('#empty_cart').show();
                $('#cart_form').hide();
            }
        });

        checkRemoveSpecBtns();

        /**
         * check title assets on load
         */
        $('.title_selected_assets:checked').each(function () {
            var $this = $(this);
            checkType($this.val(), $this.data('title-id'), false);
        });

        //DisneyABC.InitAssetPopup(asset_cart); removed as part of 3066-master-fix, is that ok?? */

        /**
         * Set up cart events
         */
        asset_cart

        /**
         * hide the removed row, should not need to disable the content
         */
            .on('click', '[id^="remove_title_"][disabled!="disabled"]', function (e) {
                $('#title_' + $(this).data('title-id')).slideUpAndRemove(checkForEmptyCart);
            })
        /**
         * check title assets on change (step 1)
         */
            .on('change', '.title_selected_assets[disabled!="disabled"]', function () {
                var $this = $(this);
                checkType($this.val(), $this.data('title-id'), true);
            })
        /**
         * show / hide the category title assets table (step 2)
         */
            .on('change', '.asset_categories', function () {
                var $this                = $(this),
                    title_id             = $this.data('title-id'),
                    category_id          = $this.data('category-id'),
                    $category_asset_list = $('#category_asset_list_' + title_id);

                // remove highlights
                $this.closest('.asset_category_list').find('.asset_category_item').removeClass('active');

                if ($this.val() === 'some') {

                    //replace assets with new category.
                    $category_asset_list.find('table').ajaxTable({
                        rowTemplate : 'category_asset_list',
                        ajaxUrl     : baseRequestUrl + '/titles/' + title_id + '/categories/' + category_id + '/assets/',
                        successCallback : function(response) {
                            if (response) {
                                window.Cart.enableTriggerElements();
                            }
                        },
                        queryStartCallback : function(ajaxTable) {
                            if (ajaxTable) {
                                window.Cart.disableTriggerElements();
                            }
                        }
                    });

                    $category_asset_list.slideDown();

                    $this.parents('tr').addClass('active'); // make this tr highlighted

                } else {

                    $category_asset_list.slideUp();

                }
            })
            //we call change on click for these items so that they do not hit before a change on a cart field.
            // .on('click', '[data-role="cart-bulkApplyTitle"]', function () {
            //     $(this).change();
            // })
            .on('click', '[data-role="cart-bulkApplyTitle"]', function () {
                var $this = $(this),
                    title_id  = $this.data('title-id');
                bulkApplyTitle(title_id);
            })

            // .on('click', '[data-role="cart-removeSpec"]', function () {
            //     $(this).change();
            // })
            .on('click', '[data-role="cart-removeSpec"]', function () {
                var $this = $(this),
                    title_id = $this.data('title-id'),
                    spec_id = $this.data('spec-id');
                removeSpec(title_id, spec_id);
            })

            // .on('click', '[data-role="cart-addSpec"]', function () {
            //     $(this).change();
            // })
            .on('click', '[data-role="cart-addSpec"]', function () {
                var $this = $(this),
                    title_id = $this.data('title-id');
                newSpec(title_id);
            })

            // .on('click', '[data-role="cart-changeMetadataPreset"]:not(.active)', function () {
            //     $(this).change();
            // })
            .on('click', '[data-role="cart-changeMetadataPreset"]:not(.active)', function () {
                var $this = $(this),
                    title_id = $this.data('title-id'),
                    spec_id = $this.data('spec-id'),
                    preset_key = $this.data('spec-preset');
                changePreset(title_id, spec_id, preset_key);
            });

        var datetimepicker = asset_cart.find('.datetimepicker');
        // container div fires the change event because of
        // datetimepicker behaviour
        datetimepicker.on('change', function () {
            checkDate($(this).find('input'));
        });

        /**
         * switch pages
         */
        // .on('click', '.nextPage, .previousPage', function (e) {
        //     e.preventDefault();
        //     initCart($(this).data('page'));
        // });
    }
} (jQuery, document, window, DisneyABC, moment));

DisneyABC.AsperaInit = function () {
    'use strict';

    /**
     * Number of loops completed
     * @type {number}
     */
    var aw4LoopChecks = 0;

    /**
     * Max number of loops
     * @type {number}
     */
    var maxAW4Loops = 30;

    /**
     *
     * @returns {number}
     * @constructor
     */
    function AW4isDefined(){
        // Make sure body exists, at least, in case IE gets a little overzealous (ticket #5443).
        if ( typeof AW4 === 'undefined' ) {
            aw4LoopChecks++;
            if(aw4LoopChecks > maxAW4Loops){
                // could not find AW4 here
                return;
            }
            return window.setTimeout( AW4isDefined() , 100);
        }
    }

    /**
     *
     */
    function initAW4() {
        AW4isDefined();
        if (typeof DisneyABC.asperaWeb === 'undefined') {
            DisneyABC.asperaWeb = new AW4.Connect();
            DisneyABC.asperaWeb.initSession();
        }
    }

    /**
     *
     * @param pathsArray
     */
    DisneyABC.setupDownload = function (pathsArray) {
        var request = $.ajax({                          // ajax call
            url: "/aspera/download-setup-json/download",
            data: {
                'paths': pathsArray
            },
            type: 'GET',                               // type POST
            dataType: 'html'
        });

        // when request is complete
        request.done(function (data) {
            DisneyABC.download($.parseJSON(data));
        });

        // Fail
        request.fail(function (jqXHR, data) {
            console.error("Failure downloading file.");
            var response = $.parseJSON(jqXHR.responseText);
            var message = new Message(response.error.message);
            message.showMessage();
        });
    };


    /**
     *
     * @param transferSpecs
     * @param connectSettings
     */
    DisneyABC.download = function (transferSpecs, connectSettings) {
        if (transferSpecs.status === 'error') {
            var error_message = 'There was an error downloading the selected file';
            var message = new Message(error_message);
            message.showMessage();
            return;
        }

        initAW4();
console.log(transferSpecs);
        for (var i = 0; i < transferSpecs.transfer_specs.length; i += 1) {
            var response = DisneyABC.asperaWeb.startTransfer(transferSpecs.transfer_specs[i].transfer_spec, connectSettings);
        }
    };

    /**
     *
     * @param callback
     */
    DisneyABC.getPathsToUpload = function (callback) {
        initAW4();

        DisneyABC.asperaWeb.showSelectFileDialog({
            success: callback,
            error: function asperaFileSelectorError(errorMessage) {
                console.log('Aspera error: ', errorMessage);
            }
        });
    };

    /**
     *
     */
    DisneyABC.setupUpload = function () {
        initAW4();
        DisneyABC.asperaWeb.showSelectFileDialog({success: DisneyABC.preUploadChecks});
        DisneyABC.asperaWeb.initSession();
        DisneyABC.asperaWeb.addEventListener('transfer', DisneyABC.handleTransferEvents);
    };

    /**
     *
     * @param pathArray
     * @param args
     */
    DisneyABC.preUploadChecks = function (pathArray, args) {

        initAW4();

        if (typeof DisneyABC.AsperaCredentials === 'undefined') {
            var request = $.ajax({                          // ajax call
                url: "/aspera/aspera-credentials/upload",
                data: {},
                type: 'GET',                               // type POST
                dataType: 'html'
            });

            // when request is complete
            request.done(function (data) {
                DisneyABC.AsperaCredentials = $.parseJSON(data);
                DisneyABC.uploadFiles(pathArray, args);
            });

            // Fail
            request.fail(function (jqXHR, data) {
                console.error("Failure uploading file.");
                var response = $.parseJSON(jqXHR.responseText);
                var message = new Message(response.error.message);
                message.showMessage();
            });
        } else {
            DisneyABC.uploadFiles(pathArray, args);
        }

    };

    /**
     *
     * @param pathArray
     * @param args
     */
    DisneyABC.uploadFiles = function (pathArray, args) {
        for (var i = 0, length = pathArray.length; i < length; i += 1) {
            var transferSpec = {
                "paths": [
                    {
                        "source": pathArray[i]
                    }
                ],
                "remote_host": DisneyABC.AsperaCredentials.ip,
                "remote_user": DisneyABC.AsperaCredentials.remote_user,
                "remote_password": DisneyABC.AsperaCredentials.remote_password,
                "direction": "send",
                "target_rate_kbps": 20000,
                "resume": "sparse_checksum",
                "authentication": "password"
            };
            if (typeof args !== 'undefined' && typeof args.save_name !== 'undefined') {
                transferSpec.destination_root = args.save_name;
            }

            var connectSettings = {
                "allow_dialogs": "no"
            };

            var request_id = DisneyABC.asperaWeb.startTransfer(transferSpec, connectSettings);
            $('#upload_progress').append("<div class='aspera_upload_info' id='" + request_id.request_id + "'><div class='aspera_progress_box'><div class='aspera_progress_bar' style='width:0'></div></div>" + pathArray[i] + "</div>");

            if (typeof args !== 'undefined' && typeof args.done_callback !== 'undefined') {
                args.done_callback();
            }
        }
    };

    /**
     *
     * @param event
     * @param obj
     */
    DisneyABC.handleTransferEvents = function (event, obj) {
        switch (event) {
            case 'transfer':
                $('#' + obj.aspera_connect_settings.request_id).find('.aspera_progress_bar').css('width', Math.max(Math.floor((obj.bytes_written / Math.max(obj.bytes_expected, 1)) * 100), 1) + "%");
                break;
        }
    };
};

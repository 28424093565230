$.fn.hasAttr = function(name) {
    return this.attr(name) !== undefined;
};

// example for setting the sort type using a data attribute on the th:
//var tableListColumnSettings = {
//    columns: []
//};
//
//tableColumns = $tableList.find('thead tr th').each(function(){
//    if($(this).hasAttr('data-sort-type')){
//        tableListColumnSettings.columns.push({'sType':$(this).attr('data-sort-type')});
//    } else {
//        tableListColumnSettings.columns.push(null);
//    }
//});
//
// $tableList.dataTable({
//    "aoColumns": mediaList.columns,
// });

//Sort by date plugin for data tables
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "datetime-us-pre": function ( date ) {
        var valid_date = new moment(date, "MM/DD/YYYY");
        return valid_date;
    },
    "datetime-us-asc": function ( a, b ) {
        return a.diff(b);
    },

    "datetime-us-desc": function ( a, b ) {
        return b.diff(a);
    }
});

/**
 * Add US date time detection to the list of auto-detected columns
 */
jQuery.fn.dataTableExt.aTypes.unshift(
    function ( sData )
    {
        if (sData !== null && sData.match(/\d{1,2}\/\d{1,2}\/\d{2,4}/)){
            return 'datetime-us';
        }
        return null;
    }
);

/*
 * Add full_numbers without ellipsis
 */
jQuery.fn.dataTableExt.pager.full_numbers = function(page, pages){
    var numbers = [];
    var buttons = jQuery.fn.dataTableExt.pager ? jQuery.fn.dataTableExt.pager.numbers_length : 0;
    var half = Math.floor( buttons / 2 );

    var _range = function ( len, start ){
        var end;

        if ( typeof start === "undefined" ){
            start = 0;
            end = len;

        } else {
            end = start;
            start = len;
        }

        var out = [];
        for ( var i = start ; i < end; i++ ){ out.push(i); }

        return out;
    };


    if ( pages <= buttons ) {
        numbers = _range( 0, pages );

    } else if ( page <= half ) {
        numbers = _range( 0, buttons);

    } else if ( page >= pages - 1 - half ) {
        numbers = _range( pages - buttons, pages );

    } else {
        numbers = _range( page - half, page + half + 1);
    }

    numbers.DT_el = 'span';

    return [ 'first', 'previous', numbers, 'next', 'last' ];
};

/**
 * sort by file size extension
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    'file-size-pre': function(data){
        var units = data.replace( /[\d\.]/g, '' ).toLowerCase().toString().trim();
        var number = data.replace(/[^\d\.]/g, '');

        var multipliers = {
            'b' : 1,
            'kb': 1024,
            'mb': Math.pow(1024, 2),
            'gb': Math.pow(1024, 3),
            'tb': Math.pow(1024, 4),
            'pb': Math.pow(1024, 5),
            'eb': Math.pow(1024, 6)
        };

        var multiplier = typeof multipliers[units] !== 'undefined'? multipliers[units] : multipliers.b;

        return parseFloat( number ) * multiplier;
    },
    "file-size-asc": function ( a, b ) {
        return a - b;
    },

    "file-size-desc": function ( a, b ) {
        return b - a;
    }
});

// auto sort columns that look like filesize columns using the filesize algo
jQuery.fn.dataTableExt.aTypes.unshift(
    function ( sData )
    {
        sData = sData.trim();
        // don't want to match something like DC 2014 Refresh On-Air GFX ... (380.1 KB)
        // so make sure that the start and end flags are in there
        if (sData !== null && sData.match(/^[0-9]*\.?[0-9]+\s?[a-zA-Z]?[bB]$/)){
            return 'file-size';
        }
        return null;
    }
);

/**
 * Sort by the data-order attribute
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "data-attribute-pre": function ( row ) {
        return $(row)[0].getAttribute('data-order');
    },
    "data-attribute-asc": function ( a, b ) {
        return a - b;
    },

    "data-attribute-desc": function ( a, b ) {
        return b - a
    }
});

// DEFAULT TABLE LAYOUT
// here is an example of what the generated code looks like.

// <div>
//   <div class="pull-left">
//    f = Search
//	 </div>
//   <div class="pull-left">
//    l = items per page
//	 </div>
//   <div class="pull-right">
//    p = pagination
//	 </div>

//   <table></table>

//   <div class="pull-left">
//    i = pagination information
//	 </div>
//   <div class="pull-right">
//    p = pagination
//	 </div>
// </div>

var sDom = "<'row'<'col-md-12 text-right'l>><'row'<'col-md-12'i><'col-md-12'p>r>t<'row'<'col-md-12'p><'col-md-12'i><'col-md-12 text-right'l>>";

var organizationTableLayout = "<'row'<'col-md-2'f><'col-md-10'p>r>t<'row'<'col-md-12'p><'col-md-12'i>>";

// CLEARED CIRCLES TABLE LAYOUT
// <div>
//   <div class="pull-left">
//    f = Search
//	 </div>
//   <div class="pull-left">
//    l = items per page
//	 </div>

//   <table></table>

//   <div class="pull-left">
//    i = pagination information
//	 </div>
// </div>

//var sDom = "<'row'<'pull-left'f><'pull-left'i><'pull-left'l><'pull-right'p>>t<'row'<'pull-left' i><'pull-right' p>>";
var sClearDom = "<'row'<'col-md-6'pl><'col-md-6'f>r>t<'row'<'col-md-12'i><'col-md-12'p>>";

function naturalSort (a, b) {
    a = $(a).text();
    b = $(b).text();
    var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
        sre = /(^[ ]*|[ ]*$)/g,
        dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
        hre = /^0x[0-9a-f]+$/i,
        ore = /^0/,
        i = function(s) { return naturalSort.insensitive && (''+s).toLowerCase() || ''+s },
    // convert all to strings strip whitespace
        x = i(a).replace(sre, '') || '',
        y = i(b).replace(sre, '') || '',
    // chunk/tokenize
        xN = x.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
        yN = y.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
    // numeric, hex or date detection
        xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
        yD = parseInt(y.match(hre)) || xD && y.match(dre) && Date.parse(y) || null,
        oFxNcL, oFyNcL;
    // first try and sort Hex codes or Dates
    if (yD)
        if ( xD < yD ) return -1;
        else if ( xD > yD ) return 1;
    // natural sorting through split numeric strings and default strings
    for(var cLoc=0, numS=Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
        // find floats not starting with '0', string or 0 if not defined (Clint Priest)
        oFxNcL = !(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc]) || xN[cLoc] || 0;
        oFyNcL = !(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc]) || yN[cLoc] || 0;
        // handle numeric vs string comparison - number < string - (Kyle Adams)
        if (isNaN(oFxNcL) !== isNaN(oFyNcL)) { return (isNaN(oFxNcL)) ? 1 : -1; }
        // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
        else if (typeof oFxNcL !== typeof oFyNcL) {
            oFxNcL += '';
            oFyNcL += '';
        }
        if (oFxNcL < oFyNcL) return -1;
        if (oFxNcL > oFyNcL) return 1;
    }
    return 0;
}

jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "natural-asc": function ( a, b ) {
        return naturalSort(a,b);
    },

    "natural-desc": function ( a, b ) {
        return naturalSort(a,b) * -1;
    }
} );

// table laoyouts
var multiPageTableLayout = sDom;
var singlePageTableLayout = "<'row'>t<'row'>";


// Pagination style
// Currentl;y set to support bootstrap
var sPaginationType = "bootstrap";

// Default number of records displayed.
var display_length = 25;
var iDisplayLength = display_length;

//LANGUAGE SETTINGS
var sSearch = "";
var sInfo = "&nbsp; Showing _START_ to _END_ of _TOTAL_ &nbsp;";

var sLengthMenu = '&nbsp;&nbsp;Items per page: <select class="select form-control ">'+
            '<option value="25">25</option>'+
            '<option value="50">50</option>'+
            '<option value="100">100</option>'+
        '</select>';

// Records per page
var oLanguage = {
    "sSearch":sSearch,
    "sInfo": sInfo,
    "sLengthMenu": sLengthMenu,
    "sInfoEmpty": "Nothing to display",
    "sInfoFiltered": " (filtering from _MAX_ files)",
    "oPaginate": {
        "sFirst": "<i class=\"glyphicon glyphicon-chevron-left icon-white\"></i><i class=\"glyphicon glyphicon-chevron-left icon-white\"></i>",
        "sPrevious": "<i class=\"glyphicon glyphicon-chevron-left icon-white\"></i>",
        "sNext": "<i class=\"glyphicon glyphicon-chevron-right icon-white\"></i>",
        "sLast": "<i class=\"glyphicon glyphicon-chevron-right icon-white\"></i><i class=\"glyphicon glyphicon-chevron-right icon-white\"></i>"
    }
};

// Define an standard table
var standardTable = {
    "iDisplayLength": 25,
    "sPaginationType": "bootstrap",
    "oLanguage": oLanguage,
    "pagingType": "full_numbers",
    "classes": {
        "sPageButton": "button primary_button"
    },
    "stateSave": false,
    "bStateSave": false,
    "drawCallback": function(settings) {
        var tableId = $(this).attr('id');
        var datatables_table = '#' + tableId;
        var pages = $(this).dataTable().fnPagingInfo().iTotalPages;

        // Create the bootstrap modal
        $('#goToPageModal_' + tableId).remove();
        html =  '<div id="goToPageModal_' + $(this).attr('id') + '" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
        html += '<form class="modal-dialog" role="document" id="goToPageModalForm_' + tableId + '">';
        html += '<div class="modal-content">';
        html += '<div class="modal-header">';
        html += '<a class="close" data-dismiss="modal">×</a>';
        html += '<h4>Go to page</h4>';
        html += '</div>';
        html += '<div class="modal-body">';
        html += '<div class="alert alert-danger" id="goToPageAlert_' + tableId + '" style="display: none;">Selected page is not within the range of available pages.</div>';
        html += '<div class="form-group"><input class="form-control" type="int" id="goToPageInput_' + tableId + '" size="20" placeholder="Page number" /></div>';
        html += '</div>';
        html += '<div class="modal-footer">';
        html += '<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>';
        html += '<button type="submit" class="btn btn-primary">Go to page</button>';
        html += '</div>';  // content
        html += '</form>';  // dialog
        html += '</div>';  // footer
        html += '</div>';  // modalWindow
        $('body').append(html);

        $('#' + tableId + '_wrapper').find('.go_to_page_button').remove();
        $('#' + tableId + '_wrapper').find('.pagination').each(function (index, item) {
            $(item).prepend('<li class="button primary_button go_to_page_button"><a href="javascript:void(0);" onclick="$(\'#goToPageModal_' + tableId + '\').modal(\'show\');">Go to...</a></li>');
        });

        $('#goToPageModalForm_' + tableId).submit(function (event) {
            event.preventDefault();

            requestedPage = $('#goToPageInput_' + tableId).val();
            $('#goToPageAlert_' + tableId).hide();

            if (requestedPage.trim() == '') {
                $('#goToPageModal_' + tableId).modal('hide');
                return;
            }

            if (requestedPage < 1 || isNaN(requestedPage) || requestedPage > pages) {
                $('#goToPageAlert_' + tableId).show();
                return;
            }

            $('#goToPageModal_' + tableId).modal('hide');
            $(datatables_table).DataTable().page(requestedPage - 1).draw( 'page' );
        });

        $('#goToPageModal_' + tableId).on('shown.bs.modal', function () {
            $('#goToPageAlert_' + tableId).hide();
            $('#goToPageInput_' + tableId).val('');
            $('#goToPageInput_' + tableId).focus();
        });

        if(typeof DisneyABC.DownloadMultipleAssets != 'undefined') {
            DisneyABC.DownloadMultipleAssets();
        }
    },
};

var getStandardTable = function(selectClass, settings) {
    var entries = $(selectClass).dataTable( {"aaSorting": [[ 0, "asc" ]]});
    var orderedTitles = entries.fnGetFilteredData();
    var tablelayout = singlePageTableLayout;

    if(orderedTitles.length > iDisplayLength) { // don't do this if there is only one page
        tablelayout = multiPageTableLayout;
    }
    entries.fnDestroy();

    var settingsTable = standardTable;
    settingsTable.sDom = tablelayout;

    var result = $(selectClass).dataTable( $.extend({}, settingsTable, settings));
    result.on('length.dt', function(e, settings, data){
        $(e.target).closest( ".dataTables_wrapper" ).each(function(index, item) {
            console.log($(item).find('select'));
            $(item).find('select').each(function(selectIndex, selectItem) {
                if ($(selectItem).data('select2')) {
                    $(selectItem).select2('val', data);
                }
            });
        });
    });
    return result;
}

/**
 * Build the mediaList object from data attributes in the table header
 *
 * data params that are used:
 * data-sortable: true or false (default is true)
 * data-sort-type: the special sort type to apply to this column
 * data-type: the type of data in this column
 *
 * @param $headers a jQuery object of headers from the table
 * @returns {{columns: Array}}
 */
function buildMediaList($headers){
    var mediaList = {
        columns : []
    };
    $headers.each(function(){
        var sort = {bSortable: true};
        var $th = $(this);
        if($th.data('sortable') === false){
            sort.bSortable = false;
        }
        if($th.data('sort-type')){
            sort.orderDataType = $th.data('sort-type');
        }
        if($th.data('type')){
            sort.type = $th.data('type');
        }

        mediaList.columns.push(sort);
    });
    return mediaList;
}

function countOfNextDateRow(table)
{
    var pastDates = table.find('.past-date').length,
        totalRows = table.find('tbody tr').length,
        iDisplayStart = 0;

    // don't do this if there is only one page or all dates past
    if ( totalRows > iDisplayLength
        || pastDates == 0
        || pastDates >= totalRows
    ) {
        iDisplayStart = table.find('.past-date').length;
    }
    return iDisplayStart;
}

function processMediaListTable(){

    var $mediaList = $('.medialist');
    if( $mediaList.length > 0 ){

        var files = getStandardTable('.medialist', {
            "autoWidth": false,
            "bSort": true,
            "bRetrieve": true
        });
        DisneyABC.filesDataTable = files;
    }

}

/* Table initialisation */
$().ready(function() {

    processMediaListTable();

    if( $('.datatable').length > 0 && $(".datatable tr:first td").length > 1 ){

        var files = getStandardTable('.datatable', {
            "aaSorting": [[ 0, "asc" ]],
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'options' ] }
            ]
        });
        DisneyABC.filesDataTable = files;
    }

    if ($('#datatable-deal').length > 0){

        var files = getStandardTable('#datatable-deal', {
            "aaSorting": [[ 0, "asc" ]],
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'options' ] }
            ]
        });
        DisneyABC.filesDataTable = files;
    }

    if ($('#deals_datatable').length > 0) {
        var deals_datatable = getStandardTable('#deals_datatable', {
            "bServerSide": true,
            "bProcessing": true,
            "bFilter": false,
            "sAjaxSource": "/orders/deals-datatables-data",
            "bSort": false,
            "fnServerData": function ( sUrl, aoData, fnCallback, oSettings) {
                if ($('.lob_checkbox:checked').length === 0) {
                    $('.lob_label').addClass("error");
                    $('.lob_error').show();
                    $('#deals_datatable_processing').css('visibility', 'hidden');
                    return;
                } else {
                    $('.lob_error').hide();
                    $('.lob_label').removeClass("error");
                }
                 aoData.push( { "name": "disney_contact_id", "value":$('#disney_contact_id').val() } );
                 aoData.push( { "name": "territory_id", "value":$('#territory_id').val() } );
                 aoData.push( { "name": "language_id", "value":$('#language_id').val() } );
                 aoData.push( { "name": "sale_status_id", "value":$('#sale_status_id').val() } );
                 aoData.push( { "name": "start_date", "value":$('#start_date').val() } );
                 aoData.push( { "name": "end_date", "value":$('#end_date').val() } );
                 aoData.push( { "name": "open_deals", "value":$('#open_deals').is(":checked") } );
                 aoData.push( { "name": "title", "value":$('input[name="title"]').val() } );
                 aoData.push( { "name": "product", "value":$('input[name="product"]').val() } );
                 aoData.push( { "name": "program", "value":$('input[name="program"]').val() } );
                 aoData.push( { "name": "client", "value":$('input[name="client"]').val() } );
                 var lobs = [];
                 $('.lob_checkbox:checked').each(function() { lobs.push($(this).attr('data-id')) });
                 aoData.push( { "name": "business_lines", "value":lobs } );
                 $('.datatables-error-div-container').hide();

                oSettings.jqXHR = $.ajax( {
                    "url":  sUrl,
                    "data": aoData,
                    "success": function (json) {
                        if ( json.status == 'error' ) {
                            //oSettings.oApi._fnLog( oSettings, 0, 'Error retrieving records' );
                            $('.datatables-error-div-container').show();
                            $('#deals_datatable_processing').css('visibility', 'hidden');
                        } else {
                            $(oSettings.oInstance).trigger('xhr', [oSettings, json]);
                            fnCallback( json );
                        }
                    },
                    "dataType": "json",
                    "cache": false,
                    "type": oSettings.sServerMethod,
                    "error": function (xhr, error, thrown) {
                        if ( error == "parsererror" ) {
                            oSettings.oApi._fnLog( oSettings, 0, "DataTables warning: JSON data from "+
                                "server could not be parsed. This is caused by a JSON formatting error." );
                        }
                    }
                });
            }
        });
        DisneyABC.deals_datatable = deals_datatable;
    }

    if( $('.circletable').length > 0){
        var files = getStandardTable('.datatable', {
            "aaSorting": [[ 0, "asc" ]]
        });
        DisneyABC.filesDataTable = files;
    }

    if( $('.nopagination').length > 0 ){
        var files = getStandardTable('.nopagination', {
            "aaSorting": [[ 1, "asc" ]],
            "bPaginate": false
        });
        DisneyABC.filesDataTable = files;
    }

    var invitation_search_results = $('#invitation-search-results');
    if( invitation_search_results.find('tbody').find('tr').length > 0 ) {
        getStandardTable('#invitation-search-results', {
            "aaSorting": [[ 0, "asc" ]],
        });
    }

    // Reports > Profile Activity
    if( $('.client-activity-report #orgs').length ){
        getStandardTable('#orgs', {"aaSorting": [[ 0, "asc" ]]});
    }


    if ($('.orderablefiles').length)
    {
        $('a[href="#title-tech-profile"]').on('shown', function (e) {
            DisneyABC.orderFilesTable.fnDraw();
        });

        DisneyABC.orderFilesTableHeightAdjusted = false;
        $('a[href="#title-tech-profile"]').click(function() {
            window.setTimeout(function() {
                if (DisneyABC.orderFilesTable !== undefined && DisneyABC.orderFilesTableHeightAdjusted === false) {
                    DisneyABC.orderFilesTable.fnDraw();
                    DisneyABC.orderFilesTableHeightAdjusted = true;
                }
            }, 0);
        });

        DisneyABC.orderFilesTable = getStandardTable('.orderablelist', {
            "autoWidth": false,
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'check','edit','action','download_multiple_assets' ] }
            ]
        });

        $('a[href="#asset-media"]').on('shown', function (e) {
            DisneyABC.orderFilesTable.fnDraw();
        });

        $('.orderablefiles').on('change', '.search_init', function () {
            var map_columns = {
                'search_type': 1,
                'search_language': 3,
                'search_runtime': 4
            };

            //this is the apparent only way to do an exact match with datatables fnFilter and also clear
            if (this.value.length > 0){
                DisneyABC.orderFilesTable.fnFilter( "^"+this.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")+"$", map_columns[this.name], true);
            } else {
                DisneyABC.orderFilesTable.fnFilter( this.value, map_columns[this.name] );
            }
        } );

    }

});

/* Set the defaults for DataTables initialisation */
$.extend( true, $.fn.dataTable.defaults, {
    "sDom": multiPageTableLayout,
    "iDisplayLength": iDisplayLength,
    "aaSorting": [[ 1, "asc" ]],
    "sPaginationType": sPaginationType,
    "oLanguage": oLanguage
} );

/* Default class modification */
$.extend( $.fn.dataTableExt.oStdClasses, {
    "sWrapper": "dataTables_wrapper form-inline"
} );



/**
 * [versions description]
 * @type {[type]}
 *
 * Strip, Strip *, Double Strip, Double Strip with Repeat, Double Strip *,
 * Weekend, Weekend *, Double Weekend, Double Weekend with Repeat, Double Weekend *
 *
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "version-pre" : function (a) {
        a = $(a).text().toLowerCase().replace(/schedule/, "").trim();
        var rawOrder = [
            "strip",
            "strip with repeat",
            "double strip",
            "double strip with repeat",
            "weekend",
            "weekend with repeat",
            "double weekend",
            "double weekend with repeat",
            "*"
        ];

        //flip the keys and values
        var key, order = {};
        for (key in rawOrder){
            if ( rawOrder.hasOwnProperty( key ) ){
                    order[rawOrder[key]] = parseInt(key, 10);
            }
        }

        //Check for a whole word match,
        //then pop words off then end until you get a match
        if(typeof order[a] === 'number'){
            return order[a];
        }
        return order['*'];
    },
    "version-asc" : function ( a, b ) {
        return a - b;
    },
    "version-desc" : function ( a, b ) {
        return b - a;
    }
});


/* API method to get paging information */
$.fn.dataTableExt.oApi.fnPagingInfo = function ( oSettings )
{
    return {
        "iStart":         oSettings._iDisplayStart,
        "iEnd":           oSettings.fnDisplayEnd(),
        "iLength":        oSettings._iDisplayLength,
        "iTotal":         oSettings.fnRecordsTotal(),
        "iFilteredTotal": oSettings.fnRecordsDisplay(),
        "iPage":          oSettings._iDisplayLength === -1 ?
            0 : Math.ceil( oSettings._iDisplayStart / oSettings._iDisplayLength ),
        "iTotalPages":    oSettings._iDisplayLength === -1 ?
            0 : Math.ceil( oSettings.fnRecordsDisplay() / oSettings._iDisplayLength )
    };
};

/**
 * Function: fnGetFilteredData()
 * Purpose:  Retrieve an array with all data that survived filtering
 * by mikej
 */
$.fn.dataTableExt.oApi.fnGetFilteredData = function (oSettings) {
    var a = [];
    if (!oSettings) {
        return a;
    }
    for (var i = 0, iLen = oSettings.aiDisplay.length; i < iLen; i++) {
        a.push(oSettings.aoData[oSettings.aiDisplay[i]]._aData);
    }

    return a;
}

/* Create an array with the values of all the input boxes in a column */
$.fn.dataTableExt.afnSortData['dom-text'] = function  ( oSettings, iColumn )
{
    return this.api().column( iColumn, {order:'index'} ).nodes().map( function ( td, i ) {
        return $('input', td).val();
    } );
}

/* Create an array with the values of all the input boxes in a column, parsed as numbers */
$.fn.dataTable.ext.order['dom-text-numeric'] = function  ( settings, col )
{
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return $('input', td).val() * 1;
    } );
}

/* Create an array with the values of all the select options in a column */
$.fn.dataTableExt.afnSortData['dom-select'] = function  ( oSettings, iColumn )
{
    return $.map( oSettings.oApi._fnGetTrNodes(oSettings), function (tr, i) {
        return $('td:eq('+iColumn+') select', tr).val();
    } );
}

/*
 * TableTools Bootstrap compatibility
 * Required TableTools 2.1+
 */
if ( $.fn.DataTable.TableTools ) {
    // Set the classes that TableTools uses to something suitable for Bootstrap
    $.extend( true, $.fn.DataTable.TableTools.classes, {
        "container": "DTTT btn-group",
        "buttons": {
            "normal": "btn",
            "disabled": "disabled"
        },
        "collection": {
            "container": "DTTT_dropdown dropdown-menu",
            "buttons": {
                "normal": "",
                "disabled": "disabled"
            }
        },
        "print": {
            "info": "DTTT_print_info modal"
        },
        "select": {
            "row": "active"
        }
    } );

    // Have the collection use a bootstrap compatible dropdown
    $.extend( true, $.fn.DataTable.TableTools.DEFAULTS.oTags, {
        "collection": {
            "container": "ul",
            "button": "li",
            "liner": "a"
        }
    } );
}

function filterDateClickHandler(jqThis, ev, recurse) {
    ev.preventDefault();
    jqThis.parent().addClass('active').siblings().removeClass('active');
    $('table.files [type=checkbox]').prop('checked', false);

    //if statement required for IE
    if(DisneyABC.filesDataTable){
        var mediaText = '';
        var mediaFilter = '';

        if (jqThis.attr('data-date')){
            mediaText = jqThis.attr('data-date');
            mediaFilter = 'a[data-media-filter="' + '#filter' + mediaText + '"]';
            DisneyABC.filesDataTable.fnFilter( mediaText + "|General" , null, true, false, true);
        } else {
            mediaText = '(>'+jqThis.text().trim()+'<)';
            mediaFilter = 'a[text-filter="' + jqThis.text().trim() + '"';
            DisneyABC.filesDataTable.fnFilter( mediaText + "|General" , null, true, false, true);
        }

        // $('.medialist_filter input').value(mediaText + "|General");

        if (recurse)
        {
            var jqOb = $(mediaFilter);
            toggleClickHandler($(jqOb), ev, false);
        }
    }
    return false;
}

/* Table initialisation */
$(document).ready(function() {


    $('[data-original-title="Check All Visible"]').click(function(event){
        event.stopPropagation();
        var me = this;
        var setAll = false;
        if($(me).is(':checked'))
        {
            setAll = true;
        }
        else {setAll = false;}
        //var setAll = $(me).prop('checked', !$(me).prop('checked').is(':checked'));
        var $table = $(me).closest('table');
        $table.find('[type=checkbox]:visible').prop('checked', setAll);
        if ($('button[name="order"]').length > 0) {
            DisneyABC.FilesSelectedForOrderChanged();
        }
        //$(me).siblings('.dropdown-toggle').dropdown('toggle');
        //console.log($(me).siblings('.dropdown-toggle'));
    });


    /*
     * CLEARANCES:
     * Used in admin/addclearances.blade
     */

    if( $('#clearances').length > 0 ) {

        var files = getStandardTable('#clearances', {
            "aaSorting": [[1, "asc"]],
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'no_sorting' ] }
            ]
        });
    }

    /**
     * full table right layout.
     *
     * This table layout puts the search in the top right
     * and the result count and pagination on the bottom right
     * */
    if( $('.fulltable').length > 0 ) {
        var files = getStandardTable('.fulltable', {
            "aaSorting": [[1, "asc"]]
        });
    }
    /**
     * full table right layout.
     *
     * This table layout puts the search in the top right
     * and the result count and pagination on the bottom right
     * */

    // Organizations - User search
    if( $('.user-search-results').length > 0 ) {
        var files = getStandardTable('.user-search-results', {
            "aaSorting": [[1, "asc"]],
            "aoColumns": [null, {"bSortable": false}]
        });
    }

    // used by title > Ad-VOD,VOD,SVOD... list

    if( $('.title-window-list').length > 0 ){
        var files = getStandardTable('.title-window-list', {
            "autoWidth": false,
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'no_sorting' ] }
            ],
            "aaSorting": [],
            "bFilter": false,
            "bLengthChange": true,
            "iDisplayStart": 0,
            "bRetrieve": true,
        });
    }

    // used by title > Ad-VOD,VOD,SVOD... list
    if( $('.no_sort').length > 0 ){
        var files = getStandardTable('.no_sort', {
            "bSort": false,
            "aaSorting": [],
            "bRetrieve": true
        });
    }

    if ($('table#title').length > 0)
    {
        var products = getStandardTable('#title', {
            "aoColumns": [ null, { "sType": "datetime-us" }, null, null, null, null, null ],
            "aaSorting": [[1, "desc"]]
        });

        $('.orderform').submit(function(){
           $(products.fnGetHiddenNodes()).find('input:checked').addClass('hidden').appendTo(this);
        });
    }

    // add product -> feature list
    if( $('#features_container').length > 0 ){
        titleContainer = getStandardTable('#features_container', {
            "aaSorting": [[ 1, "asc" ], [2, "asc"], [3, "asc"], [4, "asc"], [5, "asc"]],
            "aoColumns": [
                { "bSortable": false },
                null,
                null,
                null,
                null,
                null
            ],
            "bFilter": false,
            "bPaginate": false,
            "bLengthChange": true,
            "bRetrieve": true
        });
    }
    // add product -> season list
    if( $('#titles_container').length > 0 ){
        titleContainer = getStandardTable('#titles_container', {
            "bSort": true,
            "deferRender": true, // deferred rendering for speed
            "bFilter": false,
            "bLengthChange": true,
            "bRetrieve": true,
            "aoColumns": [null,
                { "orderDataType": "dom-text", type: 'string' },
                { "orderDataType": "dom-text", type: 'string' },
                { "orderDataType": "dom-text", type: 'string' },
                { "orderDataType": "dom-text", type: 'string' },
                { "bSortable": false},
                { "bSortable": false}],
            "aaSorting": [[4, "asc"]]
        });
    }
    /******************************************************************************
     * Do not remove this, it messes up the product schedule                       *
     * - adds paging when add copy / delete is done and removes clearances on save *
     * ****************************************************************************/
    // add/edit product -> clearance list (Note: copy of this is in site.js > DisneyABC.SetupPlugins_add_product)
    if( $('#titles_container_noratings').length > 0 ){
        titleContainer_noratings = getStandardTable('#titles_container_noratings', {
            "bSort": true,
            "aoColumns": [null, { "orderDataType": "dom-text", type: 'string' }, { "orderDataType": "dom-text", type: 'string' }, { "orderDataType": "dom-text", type: 'string' }, { "orderDataType": "dom-text", type: 'string' }],
            "aaSorting": [[3, "asc"]]
        });
    }

/******************************************************************************
* Do not remove this, it messes up the product schedule                       *
* - adds paging when add copy / delete is done and removes clearances on save *
* ****************************************************************************/
    // add/edit product -> clearance list (Note: copy of this is in site.js > DisneyABC.SetupPlugins_add_product)
    if( $('#titles_container_syndication').length > 0 ){

        var mediaList = buildMediaList($('#titles_container_syndication').find('thead th'));

        titleContainer_syndication = getStandardTable('#titles_container_syndication', {
            "bSort": true,
            "deferRender": true, // deferred rendering for speed
            "bFilter": false,
            "bLengthChange": true,
            "bRetrieve": true,
            "aoColumns": mediaList.columns,
            "aaSorting": [[2, "asc"]]
        });
    }

    // splitting this out because layout and sort orders keep changing for syndication
    if( $('#titles_container_syndication_edit_broadcast').length > 0 ){

        // IMPORTANT: if you change the sort order, update the clearanceLookup sort order in api/Disney/CsvImports/EditTvSyndicationClearancesImport
        var mediaList = {
            columns : [{ "bSortable": false }, { "orderDataType": "dom-text", type: 'date' }, { "bSortable": false}, null, null, { "bSortable": false}, { "bSortable": false}]
        };

        titleContainer_syndication = getStandardTable('#titles_container_syndication_edit_broadcast', {
            "bSort": true,
            "deferRender": true, // deferred rendering for speed
            "bFilter": false,
            "bPaginate": false,
            "bLengthChange": true,
            "bRetrieve": true,
            "aoColumns": mediaList.columns,
            "aoColumnDefs": [
                {"aTargets": mediaList.targets }
            ]
        });
    }
/******************************************************************************
* Do not remove this, it messes up the product schedule                       *
* - adds paging when add copy / delete is done and removes clearances on save *
* ****************************************************************************/
    // add/edit product -> clearance list (Note: copy of this is in site.js > DisneyABC.SetupPlugins_add_product)
    if( $('#titles_container_doa').length > 0 ){

        var mediaList = buildMediaList($('#titles_container_doa').find('thead th'));

        titleContainer_doa = getStandardTable('#titles_container_doa', {
            "bSort": true,
            "deferRender": true, // deferred rendering for speed
            "bFilter": false,
            "bPaginate": false,
            "bLengthChange": true,
            "bRetrieve": true,
            "aoColumns": mediaList.columns,
            "aaSorting": [[4, "asc"]],
        });
    }
/******************************************************************************
* Do not remove this, it messes up the product schedule                       *
* - adds paging when add copy / delete is done and removes clearances on save *
* ****************************************************************************/
    if( $('#titles_container_feature').length > 0 ){

        titles_container_feature = getStandardTable('#titles_container_feature', {
            "bSort": false,
            "deferRender": true, // deferred rendering for speed
            "bFilter": false,
            "bLengthChange": true,
            "bRetrieve": true
        });
    }
/******************************************************/
    if( $('#syndication').length > 0 ){
        if ($('.titleresults-program').length) {
            var index = 2;
            var sort = [
                [1,'asc']
            ];
        } else if( $('.search-title').length > 0 ){ //search moves the windows column (or removes it) depending on the user type
            var index = 1;
            var sort = [[ index, "asc" ]]; // index of the column that has the airdate or sortable content
        } else if ($('#notifications').length > 0) {
            var index = 2;
            var sort = [[ index, "asc" ]]; // index of the column that has the airdate, has a stable position
        } else {
            var index = 0;
            var sort = [[ index, "asc" ]]; // index of the column that has the airdate, has a stable position
        }
        // get Resultsp Per Page set in users cookie
        var cookieVal = DisneyABC.getCookie('syndicationResultsPerPage');

        var resultsPerPage;
        // if there is no value in the cooke use default value
        // else use saved value from cookie
        if (cookieVal == null) { resultsPerPage = display_length; }
        else { resultsPerPage = parseInt(cookieVal); }
        // if it all when wrong set value to default
        if (isNaN(resultsPerPage)) { resultsPerPage = display_length; }

        // Sort airdate coloumn in ascending order
        titleList = $('#syndication').dataTable( {"aaSorting": sort});

        // list of results after filtering
        var orderedTitles = titleList.fnGetFilteredData();
        var startingRecord = 0;
        var startingPage = 0;


        // for each record but not for title results as it is not sort
        if ( ! $('.titleresults-program').length ) {
            for (var i = 0; i < orderedTitles.length; i++)
            {
                // get date from record
                var date = orderedTitles[i][index];
                if (date.indexOf("/") != -1){
                    // this is a date
                }else{
                    date = orderedTitles[i][0]; // fallback for anyone using the older version
                }

                // seperate start date from string
                if (date.indexOf("-") != -1)
                {
                    date = date.split(" - ")[0];
                }
                date = date.split("/");

                //create new date
                var d = new Date();
                d.setHours(0, 0, 0, 0); // set time to 00:00:00
                if (new Date(date[2], parseInt(date[0], "10") - 1, date[1]) >= d)
                {
                    break;
                }
                startingRecord += 1;
            }

            if(startingRecord/resultsPerPage < 1) {
                startingPage = 0;
            }
            if (startingRecord >= resultsPerPage) {
                startingPage = (Math.floor(startingRecord / resultsPerPage) * resultsPerPage);
            }
        }
        titleList.fnDestroy();

        titleList = getStandardTable('#syndication', {
            "aaSorting": sort,
            "bLengthChange": true,
            "iDisplayStart": startingPage,
            "bRetrieve": true,
            //"bFilter": false,  // this will break the date range form
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'check','edit','no_sorting' ] }
            ]
        });

        $('.pull-right').bind('select2-selected', function(ev) {
            DisneyABC.setCookie('syndicationResultsPerPage', ev.val, '365');
        });

        /**
         * Bind the click filters up
         * @param {jQuery} titleList
         * @param {string} selector
         * @param {string} filter
         * @param {boolean} columnVisible
         */
        var bindTableFilterClickHandler = function (titleList, selector, filter, columnVisible) {
            $(selector).click(function(){
                $(this).parent().addClass('active').siblings().removeClass('active');
                titleList.fnFilter(filter);
                if(columnVisible !== null){
                    titleList.fnSetColumnVis(2, columnVisible);
                }
                $('table').trigger('table.reflow');
                return false;
            });
        };

        bindTableFilterClickHandler(titleList, '[href=#filterAlerts]', 'broadcast-alert', null);
        bindTableFilterClickHandler(titleList, '[href=#filterEpisodes]', 'broadcast-episode', true);
        bindTableFilterClickHandler(titleList, '[href=#filterFeatures]', 'broadcast-feature', false);
        bindTableFilterClickHandler(titleList, '[href=#filterAll]', '', true);
        bindTableFilterClickHandler(titleList, '[href=#filterActive]', 'active', null);
        bindTableFilterClickHandler(titleList, '[href=#filterExpired]', 'expired', true);

        //if we are loading the manage alerts page then set datatable filter to active.
        if ($('#notifications').length > 0) {
            $('[href=#filterActive]').click();
            $('table').trigger('table.reflow');
        }
    }

    if( $('.medialist-image').length > 0 ){
        var mediaList = {
            columns : [null, null, null, null, null, null, { "sType": "data-attribute" }, null, null]
        };
        var files = getStandardTable('.medialist-image', {
            "aoColumns": mediaList.columns,
            "aoColumnDefs": [
                { "bSortable": false, "aTargets": mediaList.targets }
            ]
        });
        DisneyABC.filesDataTable = files;
    }

    //fid all datatables seach fields and add search to the placeholder attribute
    $('.dataTables_filter label input').attr("placeholder", "Search");
    $('.dataTables_filter label input').addClass("form-control");
    $('.dataTables_filter label input').addClass("search");

    /*
     * Support functions to provide a little bit of 'user friendlyness' to the textboxes in
     * the footer
     */

    if( $('#importTable').length > 0 )
    {
        var files = getStandardTable('#importTable', {
            "aaSorting"       : [
                [ 0, "asc" ]
            ]
        });
        DisneyABC.filesDataTable = files;
    }

});



jQuery.fn.exists = function(){return this.length>0;}

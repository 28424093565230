DisneyABC.newDeal = {

    /**
     *
     */
    $addFeatureForm: null,
    /**
     *
     */
    $addFeatureFormSubmitButton: null,
    /**
     *
     */
    addFeatureTable: null,

    /**
     * Initialize this functionality
     */
    init: function(){
        var self = this;
        self._initPageObjects();
        self._initHandlers();
    },

    /**
     *
     * @private
     */
    _initPageObjects: function(){
        var self = this;
        self.$addFeatureForm = $('#addfeature_form');
        self.$addFeatureFormSubmitButton = $('#addfeature_form input[type=submit]').first();
        self.addFeatureTable = '#addfeature_form2 table:first';
    },

    /**
     *
     * @private
     */
    _initHandlers: function(){
        var self = this;
        self.$addFeatureForm.submit(function(e){
            e.preventDefault();
            self.$addFeatureFormSubmitButton.attr('disabled', 'disabled');
            $('#title-list').change();
            self._addTitle($('#title-list').val());
        });

        self.$addFeatureForm.find('input[type=text]').bind('keypress', function(e) {
            if ((e.keyCode || e.which) == 13) {
                e.preventDefault();
                return false;
            }
        });
    },

    /**
     * Add the title
     * @param title_id
     * @private
     */
    _addTitle: function(title_id){
        var self = this;

        if(!title_id){
            return;
        }
        var route = '/title/'+title_id+'/json';
        var request = $.ajax(route,{
            'cache' : true,
            'dataType': 'json'
        });
        // if success add the title to the table
        request.success(function(data){
            self._addTitleToTable(data);
        });

        //alway turn the button back on
        request.done(function( msg ) {

            self.$addFeatureFormSubmitButton.attr('disabled', false);
        });
    },

    /**
     * Add the title to the row
     * @param data
     * @returns {void}
     * @private
     */
    _addTitleToTable: function(data){
        var self = this;
        // didn't get a title back for whatever reason
        if(typeof data.id == 'undefined'){
            return;
        }
        // if the title already exists, just hit the checkmark
        if(self._alreadyInTable(data.id)) {
            $(self.addFeatureTable+ ' input[value='+data.id+']').prop('checked', true);
            return;
        }
        // null in one of the first added rows seems to break the datatable (cannot trim null)
        if(data.theatrical_release_date === null){
            data.theatrical_release_date = '';
        }
        if(data.radar_id === null){
            data.radar_id = '';
        }
        if(data.prospect_id === null){
            data.prospect_id = '';
        }
        // add the title to the datatable
        $(self.addFeatureTable).dataTable().fnAddData( [
            '<input checked="checked" class="toggle_hide" type="checkbox" name="title_ids[]" value="'+data.id+'">',
            data.name,
            data.banner,
            data.theatrical_release_date,
            data.radar_id,
            data.prospect_id
        ]);
        var $newRow = $(self.addFeatureTable).find('input[value='+data.id+']').parents('tr').first();
        // Animate the new row in so that the user can see that something happened
        DisneyABC.animations.newRow($newRow);
    },

    /**
     *
     * @param title_id
     * @returns {boolean}
     */
    _alreadyInTable: function(title_id){
        var self = this;
        return $(self.addFeatureTable + ' input[value='+title_id+']').length > 0;
    }
};

(function () {
    if($('#addfeature_form').length > 0){
        DisneyABC.newDeal.init();
    }
	if($('#addproduct_form').length > 0) {
		$('#addproduct_form .generate').on('change', function () { // update the product name when fields are updated
			if ($("#new_movie_name").is(":visible") && typeof $("#add-circle").val() !== "undefined") {// && $("#new_movie_name").val() == '')
				if ($("#add-circle").val() != '') { // problem here
					if ($('#add-circle').select2('data') != null) {
						var product_name = $('#add-circle').select2('data').name + " - " + $("#new_language_id option:selected").text() + "-" + $("#new_region_id option:selected").text() + " - " + $("#new_business_line_id option:selected").text();
						$("#new_movie_name").val(product_name);
					}
				}
			}
		});
	}
}());

DisneyABC.newCommentDialog = function(){

    var modalValues = {
        comment: '',
        id: ''
    };

    var checkRequiredData = function(){
        if(!modalValues.id && modalValues.id !== 0){
            throw 'ID is missing, cannot add a new comment.';
        }
    };

    return {
        'setComment':function(value){
            modalValues.comment = value;
        },
        'setId': function(value){
            modalValues.id = value;
        },
        'show': function(){
            checkRequiredData();
            var $modalWindow = $('#new_comments_dialog');
            $modalWindow.find('#new_comments').val(modalValues.comment);
            $modalWindow.find('#new_id').val(modalValues.id);
            $modalWindow.modal('show');
        },
        'hide': function(){
            $('#new_comments_dialog').modal('hide');
        }
    }
};

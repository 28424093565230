(function($, window) {
    window.DisneyABC.EventManager = {
        subscribe   : function (event, fn) {
            $(this).bind(event, fn);
        },
        unsubscribe : function (event, fn) {
            $(this).unbind(event, fn);
        },
        publish     : function (event) {
            $(this).trigger(event);
        }
    };
})(jQuery, window);


$(document).ready(function() {

	$("select[name='satellites']").on("change", function(e) {
		if($('#satdate').val()) {
			var selectedDate = $('#satdate').val().replaceAll('/', '-');

			var parts = selectedDate.split('-');// gives you: mm-dd-yyyy
			var month = parts[0];
			var day   = parts[1];
			var year  = parts[2];

			var target_date = year+'-'+month+'-'+day;// gives you yyyy-mm-dd
			window.location = "/satellite/"+target_date+"/"+$("select[name='satellites']").val();
		}else{
			var d = new Date();
			var month = d.getMonth()+1;
			var day = d.getDate();
			var today = d.getFullYear() + '-' +
			    ((''+month).length<2 ? '0' : '') + month + '-' +
			    ((''+day).length<2 ? '0' : '') + day;
			var target_date = today;
			window.location = "/satellite/"+target_date+"/"+$("select[name='satellites']").val();
		}

	});
	$("select[name='satellitesweek']").on("change", function(e) {
		if($('#satdate').val()) {
			var selectedDate = $('#satdate').val().replaceAll('/', '-');
			var parts = selectedDate.split('-');// gives you: mm-dd-yyyy
			var month = parts[0];
			var day   = parts[1];
			var year  = parts[2];

			var target_date = year+'-'+month+'-'+day;// gives you yyyy-mm-dd
			window.location = DisneyABC.baseUrl+"/satellite/week/"+target_date+"/"+$("select[name='satellitesweek']").val();
		}else{
			var d = new Date();
			var month = d.getMonth()+1;
			var day = d.getDate();
			var today = d.getFullYear() + '-' +
			    ((''+month).length<2 ? '0' : '') + month + '-' +
			    ((''+day).length<2 ? '0' : '') + day;
			var target_date = today;
			window.location = DisneyABC.baseUrl+"/satellite/week/"+target_date+"/"+$("select[name='satellitesweek']").val();

		}

	});

	$('a[data-toggle="tooltip"]').tooltip();
	//$('[data-rel="tooltip"]').tooltip();

	$('[data-toggle=popover]').popover({
	        html: true,
	        trigger: 'hover'
	    });


	/**
	 * Messages
	 */
	 $('.toggle-message').click(function(){
		$('.container .alert').toggle();
		return false;
	 });

	 $('.delete-message').click(function(){
		$(".alert").alert('close');
	 });


	/**
	 * TITLES
	 */

	$('a.edit-title').click(function(){
		$('.edit-title > div').toggle();
		return false;
	});
	$('.edit-title .close').click(function(){
		$('.edit-title > div').toggle();
		return false;
	});

	$('[data-toggle=row]').click(function(){
		var toggle_row = $(this).parent().parent().attr('data-id');
		if(toggle_row){
			$('tr[data-id='+toggle_row+']').toggle();
		}else{
			var toggle_row = $(this).attr('data-id');
			$('tr[data-id='+toggle_row+']').toggle();
		}
	});

	$('[data-toggle=revokeClearance]').click(function(){
		var toggle_row = $(this).attr('data-id');
		$('[data-id='+toggle_row+']').toggle();
	});

	$('[data-toggle=clearance]').click(function(){
		$("[href=#revokeClearance]:visible").parent().parent().toggle();
	});

	$('[data-toggle=addAsset]').click(function(){
		$(".addAsset").toggle();
	});

	$('[name="messageUsers"]').click(function(){
		$("#messageGroupSelect").hide();
	});

	$('#messageGroup').click(function(){
		$("#messageGroupSelect").show();
	});

	$('#addGroup').typeahead({
		source: ['Syndication', 'EST', 'Day of Air', 'PPV', 'SVOD', 'AdVOD', 'Pay Television', 'Library' ]/*function (typeahead, query) {
			return $.get('/typeahead', { query: query }, function (data) {
				return typeahead.process(data);
			});
		}*/
	});

	$('#addWindow').typeahead({
		source: ['African Cats, VOD, 4/4/2013-12/31/2013, EN, USA' ]/*function (typeahead, query) {
			return $.get('/typeahead', { query: query }, function (data) {
				return typeahead.process(data);
			});
		}*/
	});
} );



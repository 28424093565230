$(function() {
    $('#asset-upload-video #file_subtype_id').bind('change', function() {
        var trailerLinkDiv = $('#trailer_link_div');

        if ($('#file_subtype_id :selected').text().endsWith('(Link)')) {
            trailerLinkDiv.removeClass('hidden');
        } else {
            trailerLinkDiv.addClass('hidden');
        }
    });

    $('#asset-upload-video #file_subtype_id').trigger('change');
});

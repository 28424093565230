DisneyABC.assetPopup = function(popup_id, $element) {
	var fileId = $element.data('id');

	if (!fileId) {
		fileId = $element.closest('tr').data('id');
	}
	if (!fileId) {
		fileId = $element.closest('li').data('file_id');
	}

	var titleId = $('#title_id').val();
	if (!titleId) {
		titleId = $element.closest('table').data('title_id');
	}
	if (!titleId) {
		titleId = $element.data('titleId');
	}
	if (!titleId) {
		titleId = $element.closest('tr').data('title_id');
	}
	var seasonId = $('#season_id').val();
	var programId = $('#tv-series_id').val();
	var productId = $('#product_id').val();

	if ((!titleId && !seasonId && !programId && !productId) || !fileId) {
		throw 'Must provide file_id and title_id, season_id or tv-series_id to load video asset pop-up';
	}
	var tabId = $element.attr('data-tab');

	var currentId = parseInt($('#modal_file_id').val(), 10);
	if (!currentId || currentId !== fileId) {
		$('#' + popup_id + ' .modal-content').html('<div class="modal-body loading">Loading</div>');
		$('#' + popup_id + ' .loading').show();

		var request = $.ajax({
			url: DisneyABC.baseUrl + '/file/asset-details',
			data: {
				file_id: fileId,
				title_id: titleId,
				season_id: seasonId,
				program_id: programId,
				product_id: productId
			},
			type: 'GET',
			dataType: 'html'
		});

		// when request is complete
		request.success(function(data) {
			$('#' + popup_id + ' .modal-content').html(data);

			DisneyABC.InitFlowPlayer();
			DisneyABC.InitDownloadClicker();

			//switch to tab, if specified.
			if (typeof tabId !== 'undefined') {
				$('#asset-details a[href="#' + tabId + '"]').tab('show');
				flowplayer('DisneyABCVideoPlayer').play();
			}
		});

		// Fail
		request.fail(function(jqXHR, textStatus) {
			var response = $.parseJSON(jqXHR.responseText);
			message.title = 'Request failed:';
			message.showMessage();
		});
	} else {
		//switch to tab if required
		if (typeof tabId !== 'undefined') {
			$('#asset-details a[href="#' + tabId + '"]').tab('show');
			flowplayer('DisneyABCVideoPlayer').play();
		}
	}
};
DisneyABC.InitFlowPlayer = function() {
	if ($('.DisneyABCVideoPlayerContainer').length > 0) {
		flowplayer(
			'DisneyABCVideoPlayer',
			{
				src: '/flowplayer/flowplayer.commercial-3.2.15.swf',
				wmode: 'transparent' // This allows the HTML to hide the flash content
			},
			{
				clip: {
					autoPlay: false,
					fullscreen: true,
					scaling: 'fit'
				},
				key: '#$625d03b3380cd6ed988'
			}
		);
	}
};

DisneyABC.InitDownloadClicker = function() {
	$('.aspera_download_file').click(function() {
		DisneyABC.AsperaInit();
		var download_list = [ $(this).attr('data-filepath') ];
		DisneyABC.setupDownload(download_list);
	});
};
(function() {
	var initClickHandlers = function() {
		var $targets = $('[href="#asset_detail_tech"], [href="#asset-details"]');
		if ($targets.length) {
			$targets.on('click', function(e) {
				e.preventDefault();
				var whichPopup = $(this).attr('href').replace('#', '');
				DisneyABC.assetPopup(whichPopup, $(this));
			});
		}
		$('#delete_asset_button_modal').click(function(e) {
			$('#asset-delete-modal').show();
			e.preventDefault();
		});
		$('#delete-asset-submit').click(function() {
			$('#delete_asset_button_modal').closest('form').submit();
		});
	};

	initClickHandlers();

	//return;
})();

/**
 * The problem: https://github.com/twbs/bootstrap/issues/9881
 *
 * This script enhances jQuery's methods: show and hide dynamically.
 * If the element was hidden by bootstrap's css class 'hide', remove it first.
 * Do similar in overriding the method 'hide'.
 */

(function($) {
    var hide, show;
    show = $.fn.show;
    $.fn.show = function() {
        this.removeClass("hidden hide");
        return show.apply(this, arguments);
    };
    hide = $.fn.hide;
    $.fn.hide = function() {
        return hide.apply(this, arguments);
    };
})(jQuery);
var NotificationDetailButtons = (function() {
    var assignEventCancelButton = function() {
        $('#notifications-button-cancel').click(function() {
            var idNotification = $('#notification-id').val() ? $('#notification-id').val() : 0;
            window.location = '/admin/notifications/cancel/' + idNotification;
        });
    };
    var assignEventSaveButton = function() {
        $('#notifications-button-save').click(function() {
            if(validateSubject()) {
                $('#notification-create-form').submit();
            }
        });
    };

    var validateSubject = function(titleModalValidation) {
        if($('#notification-subject').is(':visible') && !$('#notification-subject').val()) {
            if(!$('#notification-subject').valid()){
                doScroll('#wrap');
            }
            NotificationDetailModal.changeTitleMessageModal('#notifications-validation-modal',titleModalValidation);
            $('#notifications-validation-modal').show();
            return false;
        }
        return true;
    };
    var assignEventSendTestButton = function() {
        var idNotification = $('#notification-id').val() ? $('#notification-id').val() : 0;
        $('#notifications-button-sendtest').click(function() {
            var titleModal = 'Send test...';

            if(!NotificationDetailFilters.validateAmountRecipients(titleModal)){
                return;
            }

            if(!$('#notification-email-delivery').is(':checked')) {
                var messageModal = 'Email delivery should be checked for sending a test';
                NotificationDetailModal.changeTitleMessageModal('#notifications-validation-modal',titleModal,messageModal);
                $('#notifications-validation-modal').show();
                return;
            }
        
            if(validateSubject(titleModal)) {
                $('#notification-create').waitState();
                var data = $('#notification-create-form').serialize();
                $.post('/admin/notifications/sendtest/' + idNotification,data,function(response){
                    var message = new Message(response.message,response.type);
                    message.showMessage();
                    $('#notification-create').waitState();
                    doScroll('#wrap');
                },"json");
            }
        });
    };
    var assignEventReviewSendButton = function() {
        $('#notifications-button-reviewsend').click(function() {
            var validForm = $('#notification-create-form').valid();
            if(validForm) {
                if(!NotificationDetailFilters.validateAmountRecipients('Send notification...')) {
                    return;
                }
                
                NotificationDetailModal.changeNotificationTitleModal();

                $('.data-preview-notification').each(function(){
                    var idElementRelated = $(this).data('element-related');
                    var valueToText = $('#' + idElementRelated).val();
                    if($('#' + idElementRelated).hasClass('select2')){
                        valueToText = $('#' + idElementRelated + ' option:selected').text();
                    }
                    $(this).text(valueToText);
                });
                NotificationDetailModal.statusYesNoOptionsModal();
                $('#notifications-preview-modal').show();
            }
        });
        $('#notifications-preview-modal-ok-button').click(function() {
            $(this).attr('disabled','disabled');
            
            var lastAction = $('#notification-create-form').attr('action');
            var idNotification = $('#notification-id').val() ? $('#notification-id').val() : 0;
            var newAction = '/admin/notifications/reviewsend/' + idNotification;
            $('#notification-create-form').attr('action',newAction);
            $('#notification-create-form').submit();
            $('#notification-create-form').attr('action',lastAction);
        });
    };

    var onFormSubmit = function () {
        $('#notification-create-form').submit(function () {
            if(!$('.error').length) {
                $('body').css('cursor', 'progress');
                var formNotificationDetail = $(this);
                var usersToSend = NotificationDetailFilters.getAllUsersIdsToSend();
                var inputUserToSend = {};
                for(var i = 0; i < usersToSend.length; i++) {
                    inputUserToSend[usersToSend[i]] = 'notneededcolumn';
                }
                var inputWithUsersToSend = 
                    $('<input/>',{ 'name': 'usersRecipients', 'type': 'hidden' })
                    .val(JSON.stringify(inputUserToSend));
                formNotificationDetail.append(inputWithUsersToSend);
            }
        });
    }

    return {
        init: function() {
            assignEventCancelButton();
            assignEventSaveButton();
            assignEventSendTestButton();
            assignEventReviewSendButton();
            onFormSubmit();
        }
    }
})();
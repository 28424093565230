DisneyABC.collapse = function(elem) {
    'use strict';

    // elem MUST BE a jQuery element
    var heading = elem;
    var elementToCollapse = elem.next();

    if (elementToCollapse.hasClass('closed-collapse')) {

        // open element
        elementToCollapse
          .removeClass('closed-collapse')
          .addClass('open-collapse');

        // change icon to chevron down
        heading.find('i.glyphicon')
          .removeClass('glyphicon-chevron-right')
          .addClass('glyphicon-chevron-down shift-right');

    } else if (elementToCollapse.hasClass('open-collapse')) {

        // close element
        elementToCollapse
          .removeClass('open-collapse')
          .addClass('closed-collapse');

        // chnage icon to chevron right
        heading.find('i.glyphicon')
          .removeClass('glyphicon-chevron-down shift-right')
          .addClass('glyphicon-chevron-right');
    }
};

$(document).ready(function() {
    'use strict';

    $('.collapse-heading').click(function() {
      DisneyABC.collapse($(this));
  });
});

/**
 * 
 * 
 * add a class of columns to an list element
 * add a data-column attribute to the list element 
 * with the number of required columns as shown below
 * 
 * <div>
 *   <ul class="columns" data-columns="5">
 *       <li>A</li>
 *       <li>B</li>
 *       <li>C</li>
 *       <li>D</li>
 *       <li>E</li>
 *       <li>F</li>
 *       <li>G</li>
 *       <li>H</li>
 *   </ul>
 * </div>
 *
 * 
 * @param  {[type]}
 * @return {[type]}
 */
$(function ordered_list_columnizer () {
   var initialContainer = $('.columns'),
       columnItems = $('.columns li'),
       columns = null,
       column = 1, // account for initial column
       itemsPerColumn = null; //to save calc on each itt

  /**
   * [updateColumns description]
   * @return {[type]}
   */
   function updateColumns() {
       column = 0;
       itemsPerColumn = (columnItems.length / columns.length);
       columnItems.each(function (idx, el) {
           if (idx !== 0 && idx > itemsPerColumn * (column + 1)) {
               column += 1;
           }
           $(columns.get(column)).append(el);
       });
   }

   /**
    * [setupColumns description]
    * @return {[type]}
    */
   function setupColumns() {
       columnItems.detach();
       column++;
       while (column <= initialContainer.data('columns')) {
           initialContainer.clone().insertBefore(initialContainer);
           column++;
       }
       columns = $('.columns');
   }
   setupColumns();
   updateColumns();
});

/**
 * Stuff that happens on the addProduct pages
 */
DisneyABC.addDoaProduct = function ($) {
    var page = {
        titlesContainer2Content: null
    };

    var _initPageObjects = function () {
        page.titlesContainer2Content = $('#titles_container_doa');
        page.row_count = parseInt(page.titlesContainer2Content.find('tbody tr').length, 10);
    };

    var _initHandlers = function () {

        page.titlesContainer2Content.on("click", ".new_doa_run", function (e) {
            e.preventDefault();

            var modal = new DisneyABC.newRunDialog();

            modal.setProductId($(this).data('product_id'));
            modal.setClearanceId($(this).data('clearance_id'));
            modal.setTitleId($(this).data('title_id'));
            modal.setDomesticNumber($(this).data('domestic_number'));

            title = $(this).data('title_name')
            modal.setTitle(title);

            if (title === 'Pre-emption') {
                modal.setHeader('Add New Pre-emption');
                modal.setProductId($('input[name=product_id]').val());
            } else {
                modal.setHeader('Add New Run');
            }

            modal.show();
            return false;

        });
    };

    return {
        /**
         * initialize the object
         */
        "init": function () {
            _initPageObjects();
            _initHandlers();
        }
    };
}(jQuery);

$(document).ready(function () {
    DisneyABC.addDoaProduct.init();
});

DisneyABC.newRunDialog = function(){

    var modalValues = {
        title: '',
        beginAt : '',
        beginAtTime : '',
        endAt : '',
        endAtTime : '',
        domesticNumber : '',
        productionNumber : '',
        foreignNumber : '',
        syndicationNumber : '',
        languageId : '',
        regionId : '',
        titleId : '',
        productId : '',
        headerText : '',
        clearanceId: '',
        buttonText : 'Save'
    };

    var checkRequiredData = function(){
        if(!modalValues.titleId){
            throw 'Title ID is missing, cannot add new run.';
        }
        if(!modalValues.titleId){
            throw 'Title Name is missing, cannot add new run.';
        }
    };

    return {
        'setTitle':function(value){
            modalValues.title = value;
        },
        'setTitleId': function(value){
            modalValues.titleId = value;
        },
        'setBeginAt': function(value){
            modalValues.beginAt = value;
        },
        'setBeginAtTime': function(value){
            modalValues.beginAtTime = value;
        },
        'setEndAt': function(value){
            modalValues.endAt = value;
        },
        'setEndAtTime': function(value){
            modalValues.endAtTime = value;
        },
        'setDomesticNumber': function(value){
            modalValues.domesticNumber = value;
        },
        'setProductionNumber': function(value){
            modalValues.productionNumber = value;
        },
        'setForeignNumber': function(value){
            modalValues.foreignNumber = value;
        },
        'setSyndicationNumber': function(value){
            modalValues.syndicationNumber = value;
        },
        'setLanguageId': function(value){
            modalValues.languageId = value;
        },
        'setRegionId': function(value){
            modalValues.regionId = value;
        },
        'setProductId': function(value){
            modalValues.productId = value;
        },
        'setClearanceId': function(value){
            modalValues.clearanceId = value;
        },
        'setButtonText': function(value){
            modalValues.buttonText = value;
        },
        'setHeader': function(value){
            modalValues.headerText = value;
        },
        'show': function(){
            checkRequiredData();
            // switch/clear values in modal
            $('#add_new_run').html(modalValues.buttonText)
                .attr('data-product_id', modalValues.productId);
            $('h2', '#new_run_dialog').html(modalValues.headerText);
            $('#new_title_id').val(modalValues.titleId);
            $('#add_product_title').html(modalValues.title + '<input type="hidden" name="title[name]" id="title_name" value="' + modalValues.title + '" />');
            if (modalValues.beginAt) {
                $('#begin_at').val(modalValues.beginAt);
            }
            if (modalValues.beginAtTime) {
                $('#begin_at_time').val(modalValues.beginAtTime);
            }
            if (modalValues.endAt) {
                $('#end_at').val(modalValues.endAt);
            }
            if (modalValues.endAtTime) {
                $('#end_at_time').val(modalValues.endAtTime);
            }
            $('#new_clearance_id').val(modalValues.clearanceId);
            $('#new_domestic_number').val(modalValues.domesticNumber);
            $('#new_production_number').val(modalValues.productionNumber);
            $('#new_foreign_number').val(modalValues.foreignNumber);
            $('#new_syndication_number').val(modalValues.syndicationNumber);
            $('#new_language_id').val(modalValues.languageId);
            $('#new_region_id').val(modalValues.regionId);

            $('#new_run_dialog').modal('show');
        },
        'hide': function(){
            $('#new_run_dialog').modal('hide');
        }
    }
};

$.fn.select2.defaults = $.extend($.fn.select2.defaults, {
    allowClear: true, // Adds X image to clear select
    closeOnSelect: true, // Only applies to multiple selects. Closes the select upon selection.
    placeholder: 'Select...',
    minimumResultsForSearch: 10, // Removes search when there are 15 or fewer options
    formatAjaxError: function (jqXHR, textStatus, errorThrown) {
        if(jqXHR.responseText) {
            try {
                var text = $.parseJSON(jqXHR.responseText);
                return text.error.messages;
            } catch (e) {}
        }
        return 'Loading failed';
    }
});

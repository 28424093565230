/*global Message:false, doa_id:false, syndication_id:false, confirm:false */
DisneyABC.adminTitles = function ($) {
    'use strict';
    /**
     *
     * @type {{btn_add_clearance: null, add_clearance_form: null, table: null, title_admin_add_clearance_page: null, add_product_id: null, div_admin_clearance: null, div_edit_clearances: null, add_circle: null}}
     */
    var page = {
        btn_add_clearance: null,
        add_clearance_form: null,
        table: null,
        title_admin_add_clearance_page: null,
        add_product_id: null,
        div_admin_clearance: null,
        div_edit_clearances: null,
        add_circle: null
    };

    /**
     * Show/hides input based on the business line
     * @param elem - needs to be the table or div containing the business line dropdown
     * @private
     */
    function _changeBusinessLine(elem) {
        elem.find('select.business-line').change();
    }

    /**
     *
     * @param elem
     * @param d
     * @returns {*}
     */
    function format(elem, d) {
        var selectedBtn = elem;
        var urlString = '/admin/edit-clearance-row/' + elem.closest('tr').attr('class').substring('clearance-'.length);
        var tr = $(selectedBtn).closest('tr');
        var editRow = $('.edit-clearance.clearance-' + tr.attr('class').substring('clearance-'.length) + ' td');

        return $.ajax({
            url: urlString,
            type: 'GET',
            dataType: 'html',

            success: function () {
                selectedBtn.removeClass('loading');
                selectedBtn.addClass('loaded');
            },

            complete: function (data) {
                //jQuery Calendar
                DisneyABC.SetupPlugins('.edit_clearances');
                _changeBusinessLine($('.child-parent')); // check if timepicker should be shown
                DisneyABC.EventManager.publish('loadTimePicker');

            },

            error: function (jqXHR, data) {
                selectedBtn.removeClass('loading');
                editRow.html('<p style="text-align:center; color: red;">An error occured</p>');
                var response = $.parseJSON(jqXHR.responseText);
                var message = new Message(response.error.message);
                message.showMessage();
            }
        });
    }

    /**
     * Objects that are part of this page
     * @private
     */
    function _initPageObjects() {
        page.table = $('.child-parent').DataTable();
        page.title_admin_add_clearance_page = $('#title_admin_add_clearance_page');
        page.btn_add_clearance = $(".btn.add_clearance");
        page.add_product_id = $('#add_product_id');
        page.div_admin_clearance = $(".edit_clearances, .new_clearance, .add_clearance");
        page.div_edit_clearances = $('.edit_clearances');
        page.add_clearance_form = $('#add-clearance-form');
        page.add_circle = $('#add-circle');
    }

    /**
     * Toggle the form inputs based on the type of business line selected
     * @param self
     */
    function toggleInputsForBusinessLine(self) {
        var form = $(self).closest('form');
        var val = $(self).val();
        if (val !== doa_id && val !== syndication_id) {
            form.find('.clearance_video_format').show();
            form.find('.clearance_video_format #add_video_format_id').removeAttr('disabled');
        } else {
            form.find('.clearance_video_format').hide();
            form.find('.clearance_video_format #add_video_format_id').attr('disabled', 'disabled');
        }

        form.find('.lob_specific').hide(); // hide all switching lob specific fields
        form.find('.lob' + val + ' input').attr('disabled', 'disabled');
        form.find('.lob' + val + ' select').attr('disabled', 'disabled');

        form.find('.lob' + val).show();
        form.find('.lob' + val + ' input').removeAttr('disabled');
        form.find('.lob' + val + ' select').removeAttr('disabled');
    }

    function buildErrorMessageString(messages) {
        var message = '<ul>';
        $.each(messages, function(key, value) {
            message += '<li>' + value + '</li>';
        });
        return message + '</ul>';
    }

    /**
     * Handlers that deal with things on this page
     * @private
     */
    function _initHandlers() {
        page.btn_add_clearance.click(function () {
            var urlString = DisneyABC.baseUrl + '/admin/clearance';
            var data = page.add_clearance_form.serialize();
            var request = $.ajax({
                url: urlString,
                data: data,
                type: 'POST',
                dataType: 'html'
            });

            // when request is complete
            request.done(function (data) {
                data = $.parseJSON(data);
                // create new message
                var message = new Message(data.message);
                message.type = data.status;                 // message type
                message.title = data.status;                // message status
                message.showMessage();                      // show message

                // Success
                if (data.status === 'success') {
                    var row_id = 'clearance-' + data.id;
                    $('.edit_clearances .' + row_id).hide(); // already exists
                    page.table.row.add($(data.view)).draw(); // allows you to insert in the html
                    $('.edit_clearances').show();           // show edit clearance div if hidden
                }
            });

            // Fail
            request.fail(function (jqXHR, data) {
                var response = $.parseJSON(jqXHR.responseText);

                var messageString = buildErrorMessageString(response.errors);
                var message = new Message(messageString);
                message.type = 'error';
                message.showMessage();
            });
        });

        // add a clearance and circle clearance
        if (page.title_admin_add_clearance_page.length > 0) {
            page.add_product_id.select2("readonly", true);
            page.add_circle.on('change', function (e) {
                $('select.business-line').change();
            });
            page.div_admin_clearance.on('change', '.business-line', function () {
                var $addCircle = $('#add-circle');
                if ($addCircle.val() !== "undefined" && $addCircle.val() > 0) {
                    var circle_id = $addCircle.val();
                    var program_id = $('#title_program_id').val();
                    var business_line_id = $('#add_business_line_id').val();
                    var urlString = $('body').attr('data-baseurl') + '/admin/title/add-clearance'; // DisneyABC.baseUrl isn't working
                    var request = $.ajax({
                        url: urlString,
                        data: {
                            'circle_id': circle_id,
                            'program_id': program_id,
                            'business_line_id': business_line_id
                        },
                        type: 'PUT',
                        dataType: 'html'
                    });
                    //$(this).closest('form').submit();

                    // when request is complete
                    request.done(function (data) {
                        data = $.parseJSON(data);

                        // Success
                        if (data.status === 'success') {
                            $('#product_list').html(data.html);
                            DisneyABC.SetupPlugins('#product_list');
                            $('#add_product_id').select2("readonly", false);
                        } else {
                            $('#product_list').html(data.html); //clear current list
                            DisneyABC.SetupPlugins('#product_list');
                            $('#add_product_id').select2("readonly", true);
                        }
                    });

                    // Fail
                    request.fail(function (jqXHR, data) {
                        var response = $.parseJSON(jqXHR.responseText);
                        var message = new Message(response.error.message);
                        message.showMessage();
                    });
                }
            });
        }

        //Line of Business dropdown change on Titles
        page.div_admin_clearance.on('change', '.business-line', function () {
            toggleInputsForBusinessLine(this);
        });

        toggleInputsForBusinessLine($('.business-line:first'));


//Save edited clearance
        page.div_edit_clearances.on('click', '.btn.save_clearance', function () {
            var urlString = DisneyABC.baseUrl + '/admin/clearance';
            var data = $(this).closest('form').serialize();

            var request = $.ajax({
                url: urlString,
                data: data,
                type: 'PUT',
                dataType: 'html'
            });

            // when request is complete
            request.done(function (data) {
                data = $.parseJSON(data);
                // create new message

                var message = new Message(data.message);
                message.type = data.status;                 // message type
                message.title = data.status;                // message status
                message.showMessage();

                // Success
                if (data.status === 'success') {
                    $('.edit_clearances .clearance-' + data.id).replaceWith('<tr id="placeholder-' + data.id + '"></tr>');        // show message
                    $('#placeholder-' + data.id).replaceWith(data.view);
                    DisneyABC.SetupPlugins('.edit_clearances .clearance-' + data.id);
                }
            });

            // Fail
            request.fail(function (jqXHR, data) {
                var response = $.parseJSON(jqXHR.responseText);
                var message = new Message(response.error.message);
                message.showMessage();
            });
        });

        //Delete Clearance
        page.div_edit_clearances.on('click', '.btn.delete_clearance', function () {

            if (confirm('Are you sure you want to delete this?')) {
                var urlString = DisneyABC.baseUrl + '/admin/clearance';
                var data = $(this).closest('form').serialize();
                var request = $.ajax({
                    url: urlString,
                    data: data,
                    type: 'DELETE',
                    dataType: 'html'
                });

                // when request is complete
                request.done(function (data) {
                    data = $.parseJSON(data);
                    // create new message
                    var message = new Message(data.message);
                    message.type = data.status;                 // message type
                    message.title = data.status;                // message status
                    message.showMessage();


                    // Success
                    if (data.status === 'success') {
                        var tr = $('.clearance-' + data.id);

                        var row = page.table.row(tr);
                        row.child.hide();

                        row.remove().draw();

                    }
                });

                // Fail
                request.fail(function (jqXHR, data) {
                    var response = $.parseJSON(jqXHR.responseText);
                    var message = new Message(response.error.message);
                    message.showMessage();
                });
            }


        });

        $('body').on('click', '.btn_edit_clearance', function () {
            var tr = $(this).closest('tr');
            var row = page.table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                format($(this), row.data()).success(function (data) {
                    row.child(data).show();
                });
                tr.addClass('shown');
            }

        });

        $('#add-clearance-form').on('change', '#add_business_line_id', function(){
            var $element = $('#product_id'); // the select element you are working with
            var businessLine = $(this).val();
            var type = null;
            try{
                type = DisneyObjects.title.title_type.name;
            } catch(e) {
                type = null;
            }

            var $request = $.ajax({
                url: '/product/business-lines/'+businessLine+'/type/'+type+'/list' // wherever your data is actually coming from
            });

            $element.prop('disabled',true);

            $request.then(function (data) {
                DisneyABC.select2.changeOptions($element, data);
            });
        });

    }

    return {
        /**
         * initialize the object
         */
        "init": function () {
            _initPageObjects();
            _initHandlers();
            _changeBusinessLine(page.div_admin_clearance);
        }
    };

}(jQuery);


$(document).ready(function () {
    'use strict';
    DisneyABC.adminTitles.init();
});


function ConfigInit() {
    (function ($, document, window, undefined) {
        function Config() {
            // A collection of the attributes we are likely to need throughout the app
            var attributes = {
                'languages' : ['en', 'fr']
            }

            this.Get = function(key, default_value) {
                if (attributes[key] != undefined) {
                    return attributes[key];
                }
                if (default_value != undefined) {
                    return default_value;
                }
                throw ('Config parameter not found: ' + key);
            }

        }

        DisneyABC.Config = new Config();

    })(jQuery, document, window);
}

$(function () {
	/*
	 **********************
	 ALERT MESSAGES CLOSE
	 **********************

	 */
	/**
	 * Alert Close Function.
	 *
	 * function used to close the alert messages.
	 */
	$('#messagearea').on('click', '.close', function () {
		$('#messagearea').hide();
	});

	// shows the text for alerts on title/product/media list sections
	$('table').on('click', '.notification', function () {
		$(this).popover('toggle');
	});

	// hide popover when off-click
	$('body').on('click', function (e) {

		$('.notification').each(function () {
			//the 'is' for buttons that trigger popups
			//the 'has' for icons and other elements within a button that triggers a popup
			if (!$(e.target).parents().is('.popover.in') && !$(this).is(e.target) && $(this).has(e.target).length === 0
				&& $('.popover').has(e.target).length === 0) {
					$(this).popover('hide');
					return;
			}
		});
	});

});

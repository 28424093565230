$(document).ready(function(){
	if ( $('#simple_output_management').length ) {
		$('#toggle_advanced_output_management').click( function() {
			$('#advanced_output_management, #simple_output_list, #toggle_advanced_output_management .action, #submit_simple_button, #simple_output_management .simple-only').toggle();
		});
	};

	//output data stuff::
	if( typeof output_specs === 'undefined') {
		output_specs = {};
		output_specs[-1] = {'audio_channels':{}};
	}
	DisneyABC.OutputSpecs = {
		output_specs : output_specs
	};

	// quick fix to disable/enable the hidden audio fields (12?). This is also used in form-presets.js
	DisneyABC.toggleDisabledAudioFields = function toggleDisabledAudioFields(){
		$('.channel.hide').find('input').attr('disabled', true);
		$('.channel.hide').find('select').attr('disabled', true);
		$('.channel').not(':hidden').find('input').attr('disabled', false);
		$('.channel').not(':hidden').find('select').attr('disabled', false);
	}

	DisneyABC.OutputSpecs.copyOutputFormatToSection = function copyOutputFormatToSection(sectionName) {
		var outputid = DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID;
		var section = $('.output.selected').closest('.' + sectionName);
		$('.output.selected').addClass('inherited');
		section.closest('.' + sectionName).find('.outputs').each(function() {
			DisneyABC.OutputSpecs.addOutputIfDoesntExist($(this), outputid);
		});
		section.find('[data-outputid=' + outputid + ']').addClass('inherited');
		section.find('[data-outputid=' + outputid + ']:first').removeClass('inherited');
	}

	DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection = function updateBonusMaterialDeliverySection() {
		$('#bonus_delivery').hide();
		if ($('#metadata_required').get(0).checked || $('#artwork_required').get(0).checked || $('#bonus_materials_requested').get(0).checked || $('#bonus_artwork_requested').get(0).checked) {
			$('#bonus_delivery').show();
		}
	}

	DisneyABC.OutputSpecs.addOutputIfDoesntExist = function addOutputIfDoesntExist(outputGroup, id) {
		if ($(outputGroup).find('[data-outputid=' + id + ']').length == 0)
		{
			var newButton = '<button onclick="DisneyABC.OutputSpecs.outputButtonClick(this)" type="button" data-outputid="' + id + '" class="label output inherited">' + output_specs[id]['name'] + '</button>';
			$(outputGroup).append(newButton);
			addButton = $(outputGroup).find('.add-output');
			addButton.parent().append(addButton.remove());
			addButton.click(DisneyABC.OutputSpecs.addOutputClick);
		}
	}

	DisneyABC.OutputSpecs.createNewOutputSpec = function createNewOutputSpec(id, specToCopy) {
		output_specs[id] = {};
		if (specToCopy != undefined) {
			output_specs[id] = $.extend(true, {}, specToCopy);
			if (output_specs[id]['spec_id'] != undefined) {
				delete output_specs[id]['spec_id'];
			}
		} else {
			output_specs[id]['name'] = 'New Output ' + id;
			output_specs[id]['delivery_method'] = 'file';
			output_specs[id]['audio_channels'] = {};

			output_specs[id]['codec_list'] = $('#codec_list').val();
			output_specs[id]['tapetype_list'] = $('#tapetype_list').val();
			output_specs[id]['delivery_method'] = $('#delivery_method').val();
			output_specs[id]['delivery_method_digital_list'] = $('#delivery_method_digital_list').val();
			output_specs[id]['asset_standard'] = $('#asset_standard').val();
			output_specs[id]['aspect_ratio'] = $('#aspect_ratio').val();
			output_specs[id]['asset_text'] = $('#asset_text').val();
			output_specs[id]['organization_circle_id'] = $('#organization_circle_id').val();
			output_specs[id]['video_bitrate'] = $('#video_bitrate').val();
			output_specs[id]['projection_type_id'] = $('#projection_type_id').val();
			output_specs[id]['frame_rate'] = $('#frame_rate').val();
			output_specs[id]['sampling_rate'] = $('#sampling_rate').val();
			output_specs[id]['audio_sampling_rate'] = $('#audio_sampling_rate').val();
			output_specs[id]['closed_captioning'] = $('#closed_captioning').val();
			output_specs[id]['closed_caption_languages'] = $('#closed_caption_languages').val();
			output_specs[id]['subtitle_languages'] = $('#subtitle_languages').val();
			output_specs[id]['audio_bitrate'] = $('#audio_bitrate').val();
			output_specs[id]['audio_codec'] = $('#audio_codec').val();
			output_specs[id]['metadata_required'] = $('#metadata_required')[0].checked;
			output_specs[id]['artwork_required'] = $('#artwork_required')[0].checked;
			output_specs[id]['bonus_materials_requested'] = $('#bonus_materials_requested')[0].checked;
			output_specs[id]['bonus_artwork_requested'] = $('#bonus_artwork_requested')[0].checked;
			output_specs[id]['asset_notes'] = $('#asset_notes').val();
			output_specs[id]['bonus_delivery_method'] = $('#bonus_delivery_method').val();
			output_specs[id]['file_subtype_type_id'] = $('#file_subtype_type_id').val();
		}
	}

	DisneyABC.OutputSpecs.addOutputClick = function addOutputClick() {
		DisneyABC.OutputSpecs.createNewOutputSpec(++DisneyABC.OutputSpecs.DATA_ID_INDEX);
		var newButton = '<button onclick="DisneyABC.OutputSpecs.outputButtonClick(this)" type="button" data-outputid="' + (DisneyABC.OutputSpecs.DATA_ID_INDEX) + '" class="label output inherited">' + output_specs[DisneyABC.OutputSpecs.DATA_ID_INDEX]['name'] + '</button>';
		//$(this).parent().append(newButton);
		$(this).parent().parent().find('.outputs .add-output').each(function() {
			$(this).parent().append(newButton);
			$(this).parent().append($(this).remove());
			$(this).click(DisneyABC.OutputSpecs.addOutputClick);
		});
		$(this).parent().find('.output:last').removeClass('inherited');
	}

	DisneyABC.OutputSpecs.SpecChanged = function SpecChanged() {
		var sel = $('.output.selected');
		var isAParent = sel.parent().parent().hasClass('parent');
		var isInherited = sel.hasClass('inherited');
		if (isInherited || isAParent) {
			sel.removeClass('inherited');
			oldId = sel.attr('data-outputid');
			if (isInherited) {
				DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = ++DisneyABC.OutputSpecs.DATA_ID_INDEX;
				DisneyABC.OutputSpecs.createNewOutputSpec(DisneyABC.OutputSpecs.DATA_ID_INDEX, output_specs[oldId])
				sel.attr('data-outputid', DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID).text(sel.text() + "*");
				sel.parent().parent().find(".outputs .output.inherited[data-outputid='" + oldId + "']").each(function() {
					$(this).attr('data-outputid', DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID);
					$(this).text($(this).text() + '*');
				});
				$('.parent-selected').removeClass('parent-selected');
			}
			DisneyABC.OutputSpecs.RemoveIfLast(oldId, sel.parent().parent());
		}
		DisneyABC.toggleDisabledAudioFields();
	}

	DisneyABC.OutputSpecs.RemoveIfLast = function RemoveIfLast(id, removedParent)
	{
		// The for .. in didn't work in IE, so I'm just going to do it this way.
		var remainder = removedParent.closest('.season').find("[data-outputid='" + id + "']");
		if (remainder.length == 1) { remainder.remove(); }

		var remainder = removedParent.closest('.program').find("[data-outputid='" + id + "']");
		if (remainder.length == 1) { remainder.remove(); }

		var remainder = removedParent.closest('.order').find("[data-outputid='" + id + "']");
		if (remainder.length == 1) { remainder.remove(); }
	}

	DisneyABC.OutputSpecs.outputButtonClick = function outputButtonClick(button) {
		if (!$(button).hasClass('selected')) {
			if ($(button).parent().parent().hasClass('order')) {
				$('.copy-to-order').addClass('visHidden');
				$('.copy-to-program').addClass('visHidden');
				$('.copy-to-season').addClass('visHidden');
				$('.copy-output-label').addClass('visHidden');
			} else if ($(button).parent().parent().hasClass('program')) {
				$('.copy-to-order').removeClass('visHidden');
				$('.copy-to-program').addClass('visHidden');
				$('.copy-to-season').addClass('visHidden');
				$('.copy-output-label').removeClass('visHidden');
			} else if ($(button).parent().parent().hasClass('season')) {
				$('.copy-to-order').removeClass('visHidden');
				$('.copy-to-program').removeClass('visHidden');
				$('.copy-to-season').addClass('visHidden');
				$('.copy-output-label').removeClass('visHidden');
				if ($('#is_movie').length == 0) {
					$('.copy-to-program').text($(button).closest('.program').children('.copy-to-program-text').text());
				}
			} else {
				$('.copy-to-order').removeClass('visHidden');
				$('.copy-to-program').removeClass('visHidden');
				$('.copy-to-season').removeClass('visHidden');
				$('.copy-output-label').removeClass('visHidden');
				if ($('#is_movie').length == 0) {
					$('.copy-to-season' ).text($(button).closest('.season').children('.copy-to-season-text').text());
					$('.copy-to-program').text($(button).closest('.program').children('.copy-to-program-text').text());
				}
			}

			$('#asset_template').select2('val', '0');

			var last_id = $('.output.selected').attr('data-outputid');
			$('.output.selected').removeClass('selected');
			$('.inherit-selected').removeClass('inherit-selected');
			$('.parent-selected').removeClass('parent-selected');

			$(button).addClass('selected');
			$('#output-specs').removeClass('hide').show();
			var outputid = $(button).attr('data-outputid');
			$(button).parent().parent().find("[data-outputid='" + outputid + "']").addClass('inherit-selected');

			$(button).closest('.season').find(".outputs:first").find("[data-outputid='" + outputid + "']:first").addClass('parent-selected');
			$(button).closest('.program').find(".outputs:first").find("[data-outputid='" + outputid + "']:first").addClass('parent-selected');
			$(button).closest('.order').find(".outputs:first").find("[data-outputid='" + outputid + "']:first").addClass('parent-selected');

			DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = outputid;
			DisneyABC.OutputSpecs.UpdateBroadcastSpecSection(last_id);
			if (output_specs[outputid] != undefined && output_specs[outputid]['name'] != undefined) {
				$('#template_name').val(output_specs[outputid]['name']);
			} else {
				$('#template_name').val('[[[[ERROR]]]]');
			}

		}
	}

	DisneyABC.OutputSpecs.UpdateBroadcastSpecSection = function UpdateBroadcastSpecSection(last_output_id) {
		if (last_output_id != DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID) {
			var dataObj = output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID];
			$('.channel').addClass('hide').hide();
			if (dataObj) {
				$('#output-specs .output-title .spec').text(dataObj['name']);
				if (dataObj['delivery_method'] == 'file') {
					$('#output-specs #delivery_file')[0].checked = true;
					$('#output-specs #delivery_tape')[0].checked = false;
					$('#tapetype').hide();
					$('#delivery_method').hide();
					$('#delivery_method_digital').show();
					$('#codec').show();
				} else {
					$('#output-specs #delivery_file')[0].checked = false;
					$('#output-specs #delivery_tape')[0].checked = true;
					$('#tapetype').show();
					$('#delivery_method').show();
					$('#delivery_method_digital').hide();
					$('#codec').hide();
				}
				$("#codec_list").select2("val", dataObj['codec_list']);
				$('#tapetype_list').select2('val', dataObj['tapetype_list']);
				$('#delivery_method_list').select2('val', dataObj['delivery_method_list']);
				$('#delivery_method_digital_list').select2('val', dataObj['delivery_method_digital_list']);
				$('#asset_standard').select2('val', dataObj['asset_standard']);
				$('#aspect_ratio').select2('val', dataObj['aspect_ratio']);
				$('#asset_text').select2('val', dataObj['asset_text']);
				$('#organization_circle_id').select2('val', dataObj['organization_circle_id']);
				$('#video_bitrate').select2('val', dataObj['video_bitrate']);
				$('#projection_type_id').select2('val', dataObj['projection_type_id']);
				$('#frame_rate').select2('val', dataObj['frame_rate']);
				$('#sampling_rate').select2('val', dataObj['sampling_rate']);
				$('#audio_sampling_rate').select2('val', dataObj['audio_sampling_rate']);
				$('#closed_captioning').select2('val', dataObj['closed_captioning']);
				$('#closed_caption_languages').select2('val', dataObj['closed_caption_languages']);
				$('#subtitle_languages').select2('val', dataObj['subtitle_languages']);
				$('#audio_bitrate').select2('val', dataObj['audio_bitrate']);
				$('#audio_codec').select2('val', dataObj['audio_codec']);
				$('#metadata_required')[0].checked = dataObj['metadata_required'];
				$('#artwork_required')[0].checked = dataObj['artwork_required'];
				$('#bonus_materials_requested')[0].checked = dataObj['bonus_materials_requested'];
				$('#bonus_artwork_requested')[0].checked = dataObj['bonus_artwork_requested'];
				$('#asset_notes').val(dataObj['asset_notes']);
				$('#bonus_delivery_method').select2('val', dataObj['bonus_delivery_method']);

				for (var channelId in dataObj['audio_channels'])
				{
					$('[data-channel-id=' + channelId + ']').removeClass('hide').show();
					$('[data-channel-id=' + channelId + '] .audio').select2('val', dataObj['audio_channels'][channelId]['audio']);
					$('[data-channel-id=' + channelId + '] .mix').select2('val', dataObj['audio_channels'][channelId]['mix']);
					$('[data-channel-id=' + channelId + '] .language').select2('val', dataObj['audio_channels'][channelId]['language']);
					dataObj['audio_channels'];
				}
				DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection();
			} else {
				$('#asset_template').select2('val', -1);
				$('#template_name_input').show();
				$('#output-specs #delivery_file')[0].checked = true;
				$('#output-specs #delivery_tape')[0].checked = false;
			}
			DisneyABC.toggleDisabledAudioFields();

		}
	}

	DisneyABC.OutputSpecs.UpdateOutputSpecName = function UpdateOutputSpecName(name) {
		$('#output-specs .output-title .spec').text(name);
		$("[data-outputid='" + DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID + "']").text(name);
	}

	if (!$('#output_management_2').length){
		DisneyABC.start_output_specs = 1; //added because start_output_specs used but never definied. not sure if this was wip.
	} else {
		DisneyABC.start_output_specs = start_output_specs
	}
	DisneyABC.OutputSpecs.DATA_ID_INDEX = DisneyABC.start_output_specs;
	DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = -1;
	$('.add-output').click(DisneyABC.OutputSpecs.addOutputClick);

	$('.remove-output').click(function() {
		var outputid = DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID;
		var sel = $('.output.selected');
		var parent = sel.parent().parent();
		sel.parent().parent().find('[data-outputid=' + outputid + ']').remove();
		$('#output-specs').addClass('hide').hide();
		DisneyABC.OutputSpecs.RemoveIfLast(outputid, parent);
	});

	$('.copy-to-order').click(function() {
		DisneyABC.OutputSpecs.copyOutputFormatToSection('order');
	});

	$('.copy-to-program').click(function() {
		DisneyABC.OutputSpecs.copyOutputFormatToSection('program');
	});

	$('.copy-to-season').click(function() {
		DisneyABC.OutputSpecs.copyOutputFormatToSection('season');
	});

	if (typeof output_specs == 'object'){

		$('#template_name').on('change keydown keyup', function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['name'] = $(this).val();
			DisneyABC.OutputSpecs.UpdateOutputSpecName($(this).val());
		});

		//used by OutputSpec management, Order Specs, and Add Video.
		$('#delivery_tape').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['delivery_method'] = 'tape';
			$('#tapetype').show();
			$('#delivery_method').show();
			$('#delivery_method_digital').hide();
			$('#codec').hide();
		});

		$('#delivery_file').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['delivery_method'] = 'file';
			$('#tapetype').hide();
			$('#delivery_method').hide();
			$('#delivery_method_digital').show();
			$('#codec').show();
		});

		// Codec
		$('#codec_list').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['codec_list'] = $('#codec_list').val();
		});

		// TapeType
		$('#tapetype_list').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['tapetype_list'] = $('#tapetype_list').val();
		});

		// Delivery Method
		$('#delivery_method_list').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['delivery_method_list'] = $('#delivery_method_list').val();
		});

		// Delivery Method Digial List
		$('#delivery_method_digital_list').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['delivery_method_digital_list'] = $('#delivery_method_digital_list').val();
		});

		// asset_standard
		$('#asset_standard').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['asset_standard'] = $('#asset_standard').val();
		});
		// asset_ratio
		$('#aspect_ratio').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['aspect_ratio'] = $('#aspect_ratio').val();
		});

		// asset_ratio
		$('#asset_text').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['asset_text'] = $('#asset_text').val();
		});
		// asset_ratio
		$('#organization_circle_id').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['organization_circle_id'] = $('#organization_circle_id').val();
		});

		// asset_ratio
		$('#video_bitrate').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['video_bitrate'] = $('#video_bitrate').val();
		});

		// asset_ratio
		$('#projection_type_id').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['projection_type_id'] = $('#projection_type_id').val();
		});

		// asset_ratio
		$('#frame_rate').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['frame_rate'] = $('#frame_rate').val();
		});

		// asset_ratio
		$('#sampling_rate').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['sampling_rate'] = $('#sampling_rate').val();
		});

		// asset_ratio
		$('#audio_sampling_rate').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_sampling_rate'] = $('#audio_sampling_rate').val();
		});

		// asset_ratio
		$('#closed_captioning').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['closed_captioning'] = $('#closed_captioning').val();
		});

		// asset_ratio
		$('#closed_caption_languages').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['closed_caption_languages'] = $('#closed_caption_languages').val();
		});

		// asset_ratio
		$('#subtitle_languages').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['subtitle_languages'] = $('#subtitle_languages').val();
		});

		// audio bitrate
		$('#audio_bitrate').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_bitrate'] = $('#audio_bitrate').val();
		});

		// audio codec
		$('#audio_codec').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_codec'] = $('#audio_codec').val();
		});


		$('.channel .audio').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			var id = $(this).parent().parent().attr('data-channel-id');
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['audio'] = $(this).val();
		});

		$('.channel .mix').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			var id = $(this).parent().parent().attr('data-channel-id');
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['mix'] = $(this).val();
		});

		$('.channel .language').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			var id = $(this).parent().parent().attr('data-channel-id');
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['language'] = $(this).val();
		});

		$('#metadata_required').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['metadata_required'] = $(this)[0].checked;
			DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection();
		});

		$('#artwork_required').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['artwork_required'] = $(this)[0].checked;
			DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection();
		});

		$('#bonus_materials_requested').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['bonus_materials_requested'] = $(this)[0].checked;
			DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection();
		});

		$('#bonus_artwork_requested').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['bonus_artwork_requested'] = $(this)[0].checked;
			DisneyABC.OutputSpecs.updateBonusMaterialDeliverySection();
		});

		$('#asset_notes').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['asset_notes'] = $(this).val();
		});

		$('#bonus_delivery_method').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['bonus_delivery_method'] = $(this).val();
		});

		$('#asset_template').click(function() {
			DisneyABC.OutputSpecs.SpecChanged();
			if ($('#call-add-new-output-spec-function').length > 0) {
				DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = $('#asset_template').val();
			}
			else
			{
				DisneyABC.OutputSpecs.createNewOutputSpec(DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID, output_specs[$('#asset_template').val()]);
			}
			DisneyABC.OutputSpecs.UpdateBroadcastSpecSection(-1);
			if ($('#asset_template').val() != '-1')  {
				var name = output_specs[$('#asset_template').val()].name;
			} else {
				var name = '';
			}

			DisneyABC.OutputSpecs.UpdateOutputSpecName(name);
			$('#template_name').val(name);
		});

	}

	$('#submit-button').click(function() {
		var files = {};
		var outputData = {};
		$('.file .output').each(function() {
			fileId = $(this).closest('.file').attr('data-file-id');
			if (files[fileId] == undefined) {
				files[fileId] = [];
			}
			files[fileId].push({'output_id':$(this).attr('data-outputid'), 'title_id':$(this).closest('.file').attr('data-title-id')});
			outputData[$(this).attr('data-outputid')] = output_specs[$(this).attr('data-outputid')];
		});
		$('#submit-button').attr('disabled', true).addClass('disabled');

		var urlString = DisneyABC.baseUrl+"/orders/specs-output";
		var request = $.ajax({
			url: urlString,
			type: 'POST',
			data: {files:files, form_id:$('input[name=form_id]').val(), outputData:JSON.stringify(outputData)}
		});

		request.done(function(data) {
			data = $.parseJSON(data);
			if (data.status == 'success') {
				window.location.href = '/orders/my-orders';
				//@TODO: Navigate to the next page
			} else {
				// Uh oh... something went wrong.  Let's just tell them about it and stare at them until something happens.

			}
		});

		// Fail
		request.fail(function(jqXHR, data) {
			var message = new Message(data.message);
			message.type = data.status;					// message type
			message.title = data.status;				// message status
			message.showMessage();					    // show message
			return false;
		});

		return false;

	});

	$('#file_subtype_type_id').click(function() {
		DisneyABC.OutputSpecs.SpecChanged();
		output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['file_subtype_type_id'] = $(this).val();
	});

	$('#asset_template').click(function() {
		DisneyABC.OutputSpecs.SpecChanged();
		if ($('#call-add-new-output-spec-function').length > 0) {
			DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = $('#asset_template').val();
		}
		else
		{
			DisneyABC.OutputSpecs.createNewOutputSpec(DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID, output_specs[$('#asset_template').val()]);
		}
		DisneyABC.OutputSpecs.UpdateBroadcastSpecSection(-1);
		if ($('#asset_template').val() != '-1')  {
			var name = output_specs[$('#asset_template').val()].name;
		} else {
			var name = '';
		}
	});

	$('.channel .remove').click(function() {
		var id = $(this).parent().parent().attr('data-channel-id');
		if (typeof output_specs === 'object'){
			delete output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id];
		}
		$(this).parent().parent().addClass('hide');
		DisneyABC.toggleDisabledAudioFields();
	});

	$('.btn.clearAllChannels').click(function() {
		if (typeof output_specs === 'object'){
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'] = {};
		}
		$('.channel_holder .channel').addClass('hide');
		DisneyABC.toggleDisabledAudioFields();
	});

	$('.addAnotherChannel').click(function() {

		var emptyChannels = $('.channel_holder .row.channel.hide');
		$(emptyChannels[0]).find(':input.audio_language_id').val($('#lang_preset').val()).change();

		var id = $('.channel.hide:first').attr('data-channel-id');
		if (typeof output_specs === 'object'){
			DisneyABC.OutputSpecs.SpecChanged();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id] = {};
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['audio'] = $('#channel_' + id + '_audio').val();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['mix'] = $('#channel_' + id + '_mix').val();
			output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['language'] = $('#channel_' + id + '_language').val();
		}
		//$('.channel.hide:first').addClass('show');
		$('.channel.hide:first').removeClass('hide');
		DisneyABC.toggleDisabledAudioFields();
	});

	// @TODO: DON'T HIDE ANY OF THESE ANYMORE, AND DON'T CALL THESE CLICKS
	$('.label.add-output:first').click();
	$('.label.output:first').click();

	if ($('#call-add-new-output-spec-function').length > 0) { // We're on the configure specs and outputs page
		DisneyABC.OutputSpecs.createNewOutputSpec(-1);
		DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID = -1;

		$('#save-output-spec-button').click(function() {
			if ($('#save-output-spec-button').hasClass("disabled")) { return false; }
			var isNewOutput = false;
			if (DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID == -1) {
				$('#save-output-spec-button').attr('disabled', true).addClass('disabled');
				isNewOutput = true;
			}

			var outputData = JSON.stringify(output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]);

			if (!isNewOutput) {
				var spec = output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID];
				var specIndex = DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID;
				$('option[value="' + specIndex + '"]').text(spec.name);
				$('#asset_template').select2('destroy');
				$('#asset_template').select2();
			}

			var urlString = DisneyABC.baseUrl+"/orders/view-output-specs";
			var request = $.ajax({
				url: urlString,
				type: 'POST',
				data: {outputData:outputData}
			});

			request.done(function(data) {
				$('#save-output-spec-button').removeAttr('disabled').removeClass("disabled");
				data = $.parseJSON(data);
				if (data.status == 'success') {
					var message = new Message(data.message);
					message.type = data.status;					// message type
					message.title = data.status;				// message status
					message.showMessage();						// show message
					if (isNewOutput) {
						var spec = JSON.parse(outputData);
						spec.id = data.new_id;
						output_specs[data.new_id] = spec;
						/* add the new term */
						$('<option value="' + data.new_id + '">'+spec.name+'</option>').appendTo('#asset_template');
						$('#asset_template').select2('val',data.new_id); // select the new term
						$("#asset_template").select2('close');		// close the dropdown
						//location.reload();
					}
					return false;
				} else {
					var message = new Message(data.message);
					message.type = data.status;					// message type
					message.title = data.status;				// message status
					message.showMessage();						// show message
					return false;
				}
			});

			// Fail
			request.fail(function(jqXHR, data) {
				var message = new Message(data.message);
				message.type = data.status;					// message type
				message.title = data.status;				// message status
				message.showMessage();						// show message
				return false;
			});

			return false;

		});
	}

	//similar to templates but it goes based on classes and doesn't care about specific channel number, just adds them.
	DisneyABC.applyAudioTemplate = function applyAudioTemplate(template){
		var emptyChannels = $('.channel_holder .row.channel.hide');

		var channelsSet = 0;
		for (i = 0; i < template.channels.length; ++i) {
			if (emptyChannels.length){
				//in the n template channel, find the n empty channel and apply the template's settings to it and unhide it.
				var channel = template.channels[i];

				$(emptyChannels[0]).find(':input.audio_type_id').val(channel.audio_type_id).change();
				$(emptyChannels[0]).find(':input.audio_mix_id').val(channel.audio_mix_id).change();
				$(emptyChannels[0]).find(':input.audio_language_id').val($('#lang_preset').val()).change();


				var id = $(emptyChannels[0]).data("channel-id");
				output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id] = {};
				output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['audio'] = channel.audio_type_id;
				output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['mix'] = channel.audio_mix_id;
				output_specs[DisneyABC.OutputSpecs.CURRENT_SELECTED_OUTPUT_ID]['audio_channels'][id]['language'] = channel.audio_language_id;

				$(emptyChannels[0]).removeClass('hide').show();
				//array pop?
				emptyChannels =	emptyChannels.slice(1);
				channelsSet++
			}
		}
		DisneyABC.OutputSpecs.SpecChanged();
		return channelsSet;
	}
});

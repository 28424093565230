var NotificationsAdmin = (function() {
	var initDatatable = function() {
		var createColumn = function(data, title, width, searchable) {
			return {
				data: data,
				title: title,
				defaultContent: '',
				width: width,
				searchable: searchable == false ? searchable : true
			};
		};
		var configDatatable = {
			idDatatable: 'notifications-datatable',
			ajax: '/admin/notifications',
			columns: [
				{
					data: 'subject',
					title: 'Title',
					width: '30%',
					render: function(data, type, row, meta) {
						var title = row.subject;
						var link = [ '<a', 'href="/admin/notifications/id/' + row.id + '">', title, '</a>' ].join(' ');
						return link;
					}
				},
				createColumn('template_name', 'Type', '15%'),
				{
					data: 'email_delivery',
					title: 'Email',
					searchable: false,
					defaultContent: '',
					width: '10%',
					render: function(data, type, row, meta) {
						return data ? '&#10003;' : '';
					}
				},
				{
					data: 'app_banner',
					title: 'Banner',
					searchable: false,
					defaultContent: '',
					width: '10%',
					render: function(data, type, row, meta) {
						return data ? '&#10003;' : '';
					}
				},
				createColumn('status_at', 'Status Date', '15%'),
				createColumn('creator', 'Created By', '17%'),
				createColumn('status', 'Status', '15%')
			],
			createdRow: function(row, data) {
				if (data.status == 'archived') {
					$(row).addClass('row-disabled');
				}
			}
		};

		var datatablesGeneral = DatatablesGeneral(configDatatable, [ setDataToSelects, initFilters ]);

		NotificationsAdmin.notificationsDatatable = datatablesGeneral.tableInitializated;
	};

	var setDataToSelects = function() {
		$('.box-filters select.filter-notifications').each(function() {
			if ($(this).data('filter-position')) {
				var select = $(this);
				NotificationsAdmin.notificationsDatatable
					.columns($(this).data('filter-position'))
					.data()
					.eq(0)
					.unique()
					.sort()
					.each(function(option, j) {
						select.append('<option value="' + option + '">' + option + '</option>');
					});
				$(this).val('');
			}
		});
	};

	var initFilters = function() {
		var filterTable = function() {
			var filterPosition = $(this).data('filter-position');
			NotificationsAdmin.notificationsDatatable.column(filterPosition).search(this.value).draw();
		};

		$('#notifications-search-title').on('keyup change', filterTable);
		$('.box-filters select.filter-notifications').on('change', filterTable);

		$.fn.dataTable.ext.search.push(function(settings, data, dataIndex) {
			var element = $('#notifications-search-createddate');
			if (!element.val()) {
				return true;
			}
			var currentDate = new Date();
			var daysToMiliseconds = 1000 * 60 * 60 * 24 * element.val();
			var pastDate = new Date(currentDate.getTime() - daysToMiliseconds);
			var dateDataTable = data[element.data('filter-position')];
			var dateTable = new Date(dateDataTable);
			if (pastDate.getTime() && dateTable.getTime() >= pastDate.getTime()) {
				return true;
			}
			return false;
		});
		$('#notifications-search-createddate').change(function() {
			NotificationsAdmin.notificationsDatatable.draw();
		});
	};

	return {
		initManager: function() {
			initDatatable();
		},
		initDetail: function() {
			NotificationDetail.initDetail();
		},
		initInbox: function() {
			NotificationsInbox.initInbox();
		}
	};
})();

$(document).ready(function() {
	var formsValidationGeneral = new FormsValidationGeneral({
		form: '#notification-create-form'
	});

	formsValidationGeneral.initValidate();

    $('form :input').change(function() {
        $(this).removeClass('error');

        if($(this).attr('id')) {
            var matchBrackets = $(this).attr('id').match(/\[\]/g);
            if (matchBrackets) {
                return;
            }
        }

		if ($(this).hasClass('select2')) {
			$('#s2id_' + $(this).attr('id') + ' a').removeClass('error');
		}
        $(this).closest('form').find('#' + $(this).attr('id') + '-error').remove();
    });

	NotificationsAdmin.initManager();
	NotificationsAdmin.initDetail();
	NotificationsAdmin.initInbox();
});

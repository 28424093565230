/**
 * Client: Deal Schedule Controls
 * Link startdate to auto-populate enddate
 * DABC-4893 / DABC-4946
 */
$('#client-deal-schedule-search-startdate').on('change', function(e) {
    var date = new Date($(this).val());
    date.setDate(date.getDate() + 7);
    date = (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
    $('#client-deal-schedule-search-enddate').val(date);
});

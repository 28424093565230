
// Accordion Text
//
// Example:
// <button type="button" data-toggle="collapse" data-alttext="Hide Filter" data-target=".toggle_filters">Show Filters</button>
// <div class="toggle_filters collapse">Hidden Text</div>
//
// This function uses the bootstrap accordian function to show hide the element. 
// http://getbootstrap.com/2.3.2/javascript.html#collapse
// this is an addon function should be used to change the button text.

$("[data-toggle='collapse']").on("click",function(event) {
	if (!!$(this).attr('data-collapsible-alternate-text')) {
		
		$(this).attr('disabled','disabled');

		var altText = $(this).data('collapsibleAlternateText');
		var oldText = $(this).text();

		event.stopPropagation();

		$( $(this).data('target') ).stop();
		$( $(this).data('target') ).toggle();

		$(this).text(altText);
		$(this).data('collapsibleAlternateText', oldText);

		$(this).removeAttr('disabled');
	}

});


// Tab Previous & Next
//
// Example:
//	<div class="row">
//		<div class="span6">
//			<a href="#" class="btn btn-default btnPrevious">Previous</a>
//		</div>
//		<div class="span6">
//			<a href="#" class="btn btn-primary btnNext pull-right">Next</a>
//		</div>
//	</div>
//
// This function is designed to work with the bootstrap tab control.
// http://getbootstrap.com/2.3.2/javascript.html#tabs
// this is an addon function should be used to add previous and next buttons that select hte correct tab.
$('.btnNext').click(function(){
		complete_tab($('.nav-tabs > .active'));
		activate_tab($('.nav-tabs > .active').next('li'));
  		$('.nav-tabs > .active').next('li').find('a').trigger('click');
		if ($(this).attr('data-next-section') !== undefined) {
			$('body').attr('section', $(this).attr('data-next-section'));
		}
		if($('.nav-tabs > .active a').text() == "3 Order Details") {
			if($('#medium').val() == "1") {
				$('.input-mini').attr('disabled', 'disabled');
			}
		}
		window.location.hash = "";
		//review_order();
});

$('.btnPrevious').click(function(){
	if($( "#screener_request_form" ).valid()){
	activate_tab($('.nav-tabs > .active').prev('li'));
	deactivate_tab($('.nav-tabs > .active'));
  	$('.nav-tabs > .active').prev('li').find('a').trigger('click');
  	$('.input-mini').removeAttr('disabled');

	if ($('body').attr('section') == 'movieFeature') {
		$('#table-container').empty();
		$('#order-container').clone().appendTo($('#table-container'));
	} else {
		$('#table-container-tv').empty();
		$('#programs-order-container').clone().appendTo($('#table-container-tv'));
	}

	if($('.nav-tabs > .active a').text() == "✓ Order Details") {
		if($('#medium').val() == "1") {
			$('.input-mini').attr('disabled', 'disabled');
		}
	}

  	window.location.hash = "";
  }
});


function activate_tab(elem) {
	if(elem.hasClass('disabled')) {
		elem.removeClass('disabled');
	}
	if(elem.hasClass('complete')) {
		elem.removeClass('complete');
	}

	elem.find('a').attr("data-toggle","tab");
}

function deactivate_tab(elem) {
	var navtab = $( "ul.nav-steps li" );
	var selectedTab = $( "ul.nav-steps li.active" );

	elem.find('.badge').html(navtab.index(selectedTab)+1);

	elem.addClass('disabled');
	elem.find('a').removeAttr("data-toggle");
}

function complete_tab(elem) {
	elem.find('.badge').html("&#10003;");
	elem.addClass('complete');
	//&#10003; &#10004;
	elem.find('a').removeAttr("data-toggle");
}

function redoTableColouring($table) {
	var even = false;
	$table.find('tbody tr').each(function() {
		$(this).removeClass('even').removeClass('odd');
		$(this).addClass(even ? 'even' : 'odd');
		if ($(this).is(':hidden') === false) {
			even = !even;
		}
	});
}

//toggles all checkboxes in the same fieldset
$('.checkall').on('click', function () {
	//$(this).closest('fieldset').find(':checkbox').prop('checked', this.checked);
	$(this).closest('fieldset').find('input:checkbox:not(:disabled)').prop('checked', this.checked);
});

//Removes empty tables
$('table[data-behaviour="removeEmpty"]').each(function(e){
	if (! $(this).find('tr').length ){
		$(this).remove();
	}
});

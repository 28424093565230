DisneyABC.animations = {

    /**
     * Slides a new row into the table, speed is based on the position of the row in the table
     * Usage:
     * DisneyABC.animations.newRow($('tr:first'));
     * DisneyABC.animations.newRow($('tr:last'));
     * DisneyABC.animations.newRow($('tr:nth(20)'));
     * @param $rowObject
     */
    newRow: function ($rowObject) {

        var $previousRow = $rowObject.prev();

        var rowPosition = $rowObject.position();

        if ($previousRow.length) {
            $previousRow.css({
                height: '200%'
            });
        }

        var startingCss = {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.5,
            width: '100%'
        };

        var endingCss = {
            'top': rowPosition.top,
            'opacity': 1
        };

        var animationSettings = {
            duration: rowPosition.top,
            complete: function () {
                $rowObject.attr('style', null);
                if ($previousRow.length) {
                    $previousRow.attr('style', null);
                }
            }
        };

        $rowObject.css(startingCss).animate(endingCss, animationSettings);
    }
};

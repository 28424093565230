var Select2General = function (config) {
    var defaultConfig = {
        allowClear: true,
        width: 'resolve'
    };
    
    if(typeof config.ajax != 'undefined') {
        var ajax = {
            dataType: 'json',
            quietMillis: 250,
            data: function (term, page) {
                return {
                    search: term, // search term
                };
            },
            cache: true
        };
        
        var newConfigAjax = $.extend(ajax,config.ajax);
        config.ajax = newConfigAjax;
    }

    function init() {
        var newConfig = $.extend(defaultConfig,config);
        var select2Initialized = newConfig.element.select2(newConfig);
        return select2Initialized;
    }

    return {
        init: function() {
            init();
        }
    };
};
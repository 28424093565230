var DatatablesGeneral = function (config, methods) {
    var totalRecordsPage = 25;
    var defaultConfig = {
        ajax : false,
        pagingType : 'full_numbers',
        sPaginationType: 'bootstrap',
        classes: {
            'sPageButton': 'button primary_button'
        },
        scrollY : '370px',
        scrollCollapse : true,
        dom : [
            '<"table-pagination text-right"',
                '<l><i>',
                '<"float-right"p>',
            '>',
            '<"table-search"',
                '<"float-left"f>',
            '>',
            't',
            '<"table-pagination bottom text-right"',
                '<l><i><p>',
            '>'
        ].join(''),
        language : {
            searchPlaceholder : 'Search...',
        },
        pageLength : totalRecordsPage,
        createdRow : config.createdRow ? config.createdRow : ''
    };

    function addClassToSearch(idTable) {
        $('#' + idTable + '_wrapper input[type="search"]')
            .addClass('search')
            .removeClass('input-sm');
    };

    function paginationTable(tableInitializated) {
        var totalRecordsTable = tableInitializated.page.info().recordsTotal;
        var $elementPagination = $('#' + tableInitializated.table().node().id + '_wrapper .table-pagination');
        if(totalRecordsTable > totalRecordsPage){
            $elementPagination.show();
        } else {
            $elementPagination.hide();
        }
    };

    function drawModalGoToPage(tableInitializated) {
        var idTable = tableInitializated.table().node().id;
        $('#goToPageModal_' + idTable).remove();
        html =  '<div id="goToPageModal_' + idTable + '" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
        html +=     '<form class="modal-dialog" role="document" id="goToPageModalForm_' + idTable + '">';
        html +=         '<div class="modal-content">';
        html +=             '<div class="modal-header">';
        html +=                 '<a class="close" data-dismiss="modal">×</a>';
        html +=                 '<h4>Go to page</h4>';
        html +=             '</div>';
        html +=             '<div class="modal-body">';
        html +=                 '<div class="alert alert-danger" id="goToPageAlert_' + idTable + '" style="display: none;">Selected page is not within the range of available pages.</div>';
        html +=                 '<div class="form-group"><input class="form-control" type="int" id="goToPageInput_' + idTable + '" size="20" placeholder="Page number" /></div>';
        html +=             '</div>';
        html +=             '<div class="modal-footer">';
        html +=                 '<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>';
        html +=                 '<button type="submit" class="btn btn-primary">Go to page</button>';
        html +=             '</div>';
        html +=         '</div>';
        html +=     '</form>';
        html += '</div>';
        $('body').append(html);

        $('#' + idTable + '_wrapper .table-pagination ul.pagination').find('.go_to_page_button').remove();
        $('#' + idTable + '_wrapper .table-pagination ul.pagination').each(function (index, item) {
            $(item).prepend('<li class="button primary_button go_to_page_button"><a href="javascript:void(0);" onclick="$(\'#goToPageModal_' + idTable + '\').modal(\'show\');">Go to...</a></li>');
        });

        var pages = tableInitializated.page.info().pages;

        $('#goToPageModalForm_' + idTable).submit(function (event) {
            event.preventDefault();
            requestedPage = $('#goToPageInput_' + idTable).val();
            $('#goToPageAlert_' + idTable).hide();

            if (requestedPage.trim() == '') {
                $('#goToPageModal_' + idTable).modal('hide');
                return;
            }

            if (requestedPage < 1 || isNaN(requestedPage) || requestedPage > pages) {
                $('#goToPageAlert_' + idTable).show();
                return;
            }

            $('#goToPageModal_' + idTable).modal('hide');
            tableInitializated.page(requestedPage - 1).draw( 'page' );
        });

        $('#goToPageModal_' + idTable).on('shown.bs.modal', function () {
            $('#goToPageAlert_' + idTable).hide();
            $('#goToPageInput_' + idTable).val('');
            $('#goToPageInput_' + idTable).focus();
        });
    }

    function init() {
        var newConfig = $.extend(defaultConfig,config);
        newConfig.initComplete = function(settings, json) {
            var table = settings.oInstance.api();
            paginationTable(table);
            if (methods) {
                methods.forEach(function(method) {  
                    method(table);
                });
            }
            $('.loading-spinner-with-overlay').fadeOut();
            drawModalGoToPage(table);
        }
        newConfig.drawCallback = function(settings) {
            var table = settings.oInstance.api();
            drawModalGoToPage(table);
        }
        var $elementTable = $('#' + config.idDatatable);
        $elementTable.parent().waitState();
        var tableInitializated = $elementTable.DataTable(newConfig);
        addClassToSearch(config.idDatatable);
        return tableInitializated;
    }

    return {
        tableInitializated: init(),
        pagination: paginationTable
    };
};

//DatatablesGeneral.prototype.paginationTable = fun
/**
 * Utility functions for select2 boxes
 * @type {{changeOptions: Function}}
 */
DisneyABC.select2 = {
    /**
     * Change the options in a selectbox, destroys the select2 element, refills the select box, then re-enables everything
     *
     * @param <jQuery object> $element
     * @param <object> data Looks like this: {key:value, key2:value2, key3:value3}
     * @return void
     */
    changeOptions: function ($element, data) {
        'use strict';
        $element.select2('destroy');

        // This assumes that the data comes back as an array of data objects
        // The idea is that you are using the same callback as the old `initSelection`
        $element.empty();
        var options = [new Option('', '', true, true)];

        var sorted = DisneyABC.select2.sortObject(data);

        for (var i in sorted) {
            if (sorted.hasOwnProperty(i)){
                options.push(new Option(sorted[i].value, sorted[i].key));
            }
        }

        $element.append(options);
        $element.val('');
        $element.prop('disabled', false);
        $element.select2();
    },
    /**
     *
     * @param obj
     * @returns {Array}
     */
    sortObject: function (obj) {
        'use strict';
        var arr = [];
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                arr.push({
                    'key': prop,
                    'value': obj[prop]
                });
            }
        }

        arr.sort(function (a, b) {
            if (a.value < b.value) {
                return -1;
            }

            if (a.value > b.value) {
                return 1;
            }

            return 0;
        });

        return arr;
    }
};

/**
 * Created by jprevoe on 02/02/2015.
 */
(function ($, document, window, undefined) {
	var edit_order_title = $('[data-edit-order-id]');
	if (edit_order_title.length) {
		$('#order_list_table').on('click', '[data_soft_locked="true"]', function() {
			$(this).tooltip('show');
			$(this).removeAttr("data_soft_locked");
			return false;
		});

		$(window).bind('unload', function() {
			$.ajax({  // ajax call
				url: DisneyABC.baseUrl+'/orders/release_soft_lock/' + edit_order_title.attr("data-edit-order-id"),
				type: 'GET',
				dataType: 'html',
				async: false
			});
		});
	}
})(jQuery, document, window);
var FormsValidationGeneral = function( config ) {
    var defaultConfig = {
        ignore: ':hidden:not(.select2)',
        errorPlacement: function (error, element) {
            var elem = $(element);
            if (elem.hasClass('datecalendar') || elem.hasClass('datehours') || elem.parent('.datetimepicker').length){
                error.insertAfter(elem.closest('div'));
            } else {
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            var elem = $(element);
            if (elem.hasClass('select2')) {
                $('#s2id_' + elem.attr('id') + ' a').addClass(errorClass);
            } else {
                elem.addClass(errorClass);
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            var elem = $(element);
            if (elem.hasClass('select2')) {
                $('#s2id_' + elem.attr('id') + ' a').removeClass(errorClass);
            } else {
                elem.removeClass(errorClass);
            }
        },
        invalidHandler: function(form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                doScroll(validator.errorList[0].element);
                validator.errorList[0].element.focus();
            }
        }
    }

    var newConfig = {};
    if(typeof config != 'undefined') {
        newConfig = $.extend(defaultConfig,config);
    } else {
        newConfig = defaultConfig;
    }

    return {
        initValidate: function() {
            $(config.form).validate(newConfig);
        }
    }

}
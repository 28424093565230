var NotificationDetailFilters = (function () {
    var allUsersFiltered = {};
    var allUsersIdsToSend = [];
    var allUsersToSend = [];

    var setAllUsersFiltered = function (typeOfFilter,idFilter,users) {
        if(users.length) {
            allUsersFiltered[typeOfFilter + '-' + idFilter] = users;
        }
    }

    var getAllUsersFiltered = function () {
        return allUsersFiltered;
    }

    var removeUsersByFilter = function (typeOfFilter,idFilter) {
        if(allUsersFiltered[typeOfFilter + '-' + idFilter]) {
            return delete(allUsersFiltered[typeOfFilter + '-' + idFilter]);
        }
        return false;
    }

    var getAllUsersIdsToSend = function () {
        return allUsersIdsToSend;
    }

    var resetAllUsersIdsToSend = function () {
        allUsersIdsToSend = [];
    }

    var setAllUsersToSend = function (user) { 
        if(allUsersIdsToSend.indexOf(user.id.toString()) == -1) {
            if(user.contact_methods && user.contact_methods.length) {
                user.email = user.contact_methods[0]['pivot'].value;
            }
            allUsersIdsToSend.push(user.id);
            allUsersToSend.push(user);
        }
    }

    var getAllUsersToSend = function () {
        return allUsersToSend;
    }

    var resetAllUserToSend = function () {
        allUsersToSend = [];
    }

    var mergeUsersFiltered = function () {
        resetAllUsersIdsToSend();
        resetAllUserToSend();
        var usersToMerge = getAllUsersFiltered();
        for(var indexFilter in usersToMerge) {
            for(var index in usersToMerge[indexFilter]) {
                setAllUsersToSend(usersToMerge[indexFilter][index]);
            }
        }
    }

    var loadUsersAlreadySaved = function () {
        if(typeof usersIdAlreadySaved !== 'undefined') {
            allUsersIdsToSend = usersIdAlreadySaved;
        }
    }

    var initTableRecipients = function () {
        var configDatatableRecipients = {
            idDatatable: 'table-recipients',
            columns : [
                { 'data': 'name', 'title': 'Name', 'defaultContent': ''},
                { 
                    'data' : 'organizations',
                    'title' : 'Organization',
                    'width' : '40%',
                    'render' : function ( organizations, type, row, meta ) {
                        if (typeof organizations == 'object') {
                            if (organizations.length) {
                                var ul = ['<ul>'];
                                organizations.forEach(function (organization) {
                                    var li = [
                                        '<li>',
                                            organization.name,
                                        '</li>'
                                    ];
                                    ul.push(li.join(' '));
                                });
                                ul.push('</ul>');
                                return ul.join(' ');
                            }
                            return '';
                        } else {
                            return organizations;
                        }
                    },
                    'defaultContent' : ''
                },
                {'data': 'email', 'title': 'Email', 'defaultContent': ''},
            ],
        };
        NotificationsAdmin.datatablesGeneralRecipients = 
            DatatablesGeneral(configDatatableRecipients);
        NotificationsAdmin.recipientsTable = 
            NotificationsAdmin.datatablesGeneralRecipients.tableInitializated
    }

    var enableDisableFilterEpisodes = function (element) {
        if(element.data('url') === 'tv-series') {
            $('[data-url="episodes"]').prop('disabled',element.val() ? false : true);
        }
    }

    var removeEpisodesFilteredBySerie = function (optionSerie) {
        var episodesToRemove = [];
        var options = $.grep($('#filter-episodes').select2('data'), function (data) {
            if(data['program_id'] != optionSerie.id) {
                return true;
            } 
            episodesToRemove.push(data);
            return false;
        });
        for(var i = 0; i < episodesToRemove.length; i++) {
            var objectEpisode = { choice : episodesToRemove[i] };
            handlerFilterRecipientRemove(objectEpisode,'episodes');
        }
        $('#filter-episodes').select2('data', options);
    };

    var handlerFilterRecipientRemove = function (e,typeOfFilter) {
        /* if(typeOfFilter === 'tv-series') {
            removeEpisodesFilteredBySerie(e.choice);
        } */
        
        var dataFilterUnselected = e.choice;

        if(typeof dataFilterUnselected.dontRemove != 'undefined' && dataFilterUnselected.dontRemove) {
            e.preventDefault();
            return;
        }

        $('#notification-recipients').waitState();

        var classFilterToremove = '.filter-' + typeOfFilter + dataFilterUnselected.id;
        
        if(removeUsersByFilter(typeOfFilter,dataFilterUnselected.id)) {
            $(classFilterToremove).remove();
            redrawUsersTable();
            var totalRecords = NotificationsAdmin.recipientsTable.page.info().recordsTotal;
            $('#modal-count-recipients').text(totalRecords);
        }             
        $('#notification-recipients').waitState();

        return;
    };

    var getConfigToSelect2RecipientFilter = function (typeOfFilter,dropdownRecipient) {
        var configSelect2 = {
            element: dropdownRecipient,
            multiple : true,
            minimumInputLength: 1,
            ajax: {
                url: '/admin/notifications/filter-recipients/' + typeOfFilter
            },
            initSelection: function (element, callback) {
                var selectvalue  = $(element).val();
                var filterValues = JSON.parse(selectvalue);
                callback(filterValues);

                //only set users by filter when notification is in draft or scheduled
                if($('#notifications-button-cancel').length) {
                    filterValues.forEach(function (filterValue) {
                        var typeOfFilter = $(element).data('url');
                        var users = [];
                        if($(element).data('url') == 'users') {
                            $.get('/admin/notifications/filter-recipients/users?search='+filterValue.text)
                            .success(function (response) {
                                if(typeof response != 'undefined' && response.length) {
                                    users.push(response[0]);
                                }
                                setAllUsersFiltered(typeOfFilter,filterValue.id,users);
                            });
                        } else {
                            var objectTosend = {};
                            objectTosend[typeOfFilter] = [filterValue.id];
                            $.get('/admin/notifications/users-filter-recipients',objectTosend)
                            .success(function (response) {
                                if(typeof response != 'undefined' && response.users.length) {
                                    users = response.users;
                                }
                                setAllUsersFiltered(typeOfFilter,filterValue.id,users);
                            });
                        }
    
                        var inputFilterRecipients = $('<input/>',{
                            'type':'hidden',
                            'value': filterValue.text,
                            'name': $(element).data('name') + '[' + filterValue.id + ']',
                            'class': 'filter-' + $(element).data('url') + filterValue.id
                        });
    
                        $('#notification-create-form').append(inputFilterRecipients);
                    });
                }
            }
        }

        switch(typeOfFilter) {
            case 'users': {
                configSelect2.ajax.results = function (data, page) {
                    return {
                        results :
                            data.map(function (item) {
                                return {
                                    id : item.id,
                                    text : item.name,
                                    organizations: item.organizations,
                                    name: item.name,
                                    email: item.contact_methods.length ? item.contact_methods[0]['pivot'].value : ''
                                };
                            }
                    )};
                }
                break;
            }
            case 'episodes': {
                configSelect2.ajax.data = function (term, page) {
                    //var tvseriesIds = $('[data-url="tv-series"]').val().split(',');

                    return {
                        search: term
                        //search: term + '&pid[]=' + tvseriesIds.join('&pid[]=') // search term
                    };
                };
                configSelect2.ajax.results = function (data, page) {
                    return {
                        results :
                            data.map(function (item) {
                                return {
                                    id : item.id,
                                    program_id: item.program_id,
                                    text : item.program + ' - ' + item.name,
                                };
                            }
                    )};
                };
                configSelect2.formatSelectionCssClass = function (data, container) { 
                    return 'program' + data.program_id; 
                }
                break;
            }
            default: {
                configSelect2.ajax.results = function (data, page) {
                    return {
                        results :
                            data.map(function (item) {
                                return {
                                    id : item.id,
                                    text : item.name
                                };
                            }
                    )};
                }
            }
        }
        
        return configSelect2;
    };

    var initFilters = function () {
        function setUsersInTable(dropdownRecipient,typeOfFilter,idFilter,users) {
            var lastRecordsTable = NotificationsAdmin.recipientsTable.page.info().recordsTotal;  
            setAllUsersFiltered(typeOfFilter,idFilter,users);
            redrawUsersTable();
            showAmountUsersTableAdded(dropdownRecipient,lastRecordsTable);
            $('#notification-recipients').waitState();
        }

        $('.filter-recipients').each(function () {
            var dropdownRecipient = $(this);
            var typeOfFilter = dropdownRecipient.data('url');
            var isUsersFilter = typeOfFilter == 'users';

            var configSelect2 = getConfigToSelect2RecipientFilter(typeOfFilter,dropdownRecipient);
            
            var select2General = new Select2General(configSelect2);
            select2General.init();

            dropdownRecipient.on('select2-selecting', function (e) {
                $('#notification-recipients').waitState();
                var dataFilter = e.choice;
                
                appendFilterRecipientToSend(dropdownRecipient,dataFilter,typeOfFilter);

                if(isUsersFilter) {
                    setUsersInTable(dropdownRecipient,typeOfFilter,dataFilter.id,[dataFilter]);
                } else {
                    var objectTosend = {};
                    objectTosend[typeOfFilter] = [dataFilter.id];

                    $.get('/admin/notifications/users-filter-recipients',objectTosend)
                    .success(function (response) {
                        var users = [];
                        if(typeof response != 'undefined' && response.users.length) {
                            users = response.users;
                        }
                        setUsersInTable(dropdownRecipient,typeOfFilter,dataFilter.id,users);
                        //enableDisableFilterEpisodes(dropdownRecipient);
                    })
                    .fail(function () {
                        alert('Oops an error ocurred');
                        $('#notification-recipients').waitState();
                    });
                }
            });

            dropdownRecipient.on('select2-removing', function (e) {
                handlerFilterRecipientRemove(e,typeOfFilter);
                //enableDisableFilterEpisodes(dropdownRecipient);
            });
        });
    };

    var appendFilterRecipientToSend = function (dropdownRecipient,dataFilter,typeOfFilter) {
        var inputFilterRecipients = 
            $('<input/>',{
                'type':'hidden',
                'value': dataFilter.text,
                'name': dropdownRecipient.data('name') + '[' + dataFilter.id + ']',
                'class': 'filter-' + typeOfFilter + dataFilter.id
            });
        $('#notification-create-form').append(inputFilterRecipients);
    };

    var redrawUsersTable = function () {
        mergeUsersFiltered();
        var usersToSetTable = getAllUsersToSend();

        NotificationsAdmin.recipientsTable.clear();
        NotificationsAdmin.recipientsTable
        .rows.add(usersToSetTable)
        .draw();
        
        NotificationsAdmin.datatablesGeneralRecipients
        .pagination(NotificationsAdmin.recipientsTable);
        
        var totalRecords = NotificationsAdmin.recipientsTable.page.info().recordsTotal;
        $('#modal-count-recipients').text(totalRecords);
    };

    var showAmountUsersTableAdded = function (dropdownRecipient,lastRecordsTable) {
        var currentRecordsTable = NotificationsAdmin.recipientsTable.page.info().recordsTotal;
        var usersToSetTable = currentRecordsTable - lastRecordsTable;
        $('#amount-users-added-' + dropdownRecipient.data('name'))
        .text(usersToSetTable + ' users added to table').fadeIn();

        setTimeout(function () {
            $('#amount-users-added-' + dropdownRecipient.data('name')).fadeOut();
        },4000);
    };

    return {
        init: function () {
            initFilters();
            initTableRecipients();
            loadUsersAlreadySaved();
        },
        validateAmountRecipients: function (titleModal) {
            if(!NotificationsAdmin.recipientsTable.page.info().recordsTotal) {
                var messageModal = 'Please select at least one recipient';
                NotificationDetailModal.changeTitleMessageModal(
                    '#notifications-validation-modal',
                    titleModal,
                    messageModal
                );
                $('#notifications-validation-modal').show();
                return false;
            }
            return true;
        },
        getAllUsersIdsToSend: function () {
            return getAllUsersIdsToSend();               
        }
    }
})();
DisneyABC.dealWizardLauncher = {

    /**
     *  Business line select box
     *  @var {jqueryObject}
     */
    $businessLineSelect: null,

    /**
     *  Syndicated product select box
     *  @var {jqueryObject}
     */
    $syndicatedProductTypesSelect: null,

    /**
     * Initialize this functionality
     */
    init: function(){
        var self = this;
        self._initPageObjects();
        self._initHandlers();
    },

    /**
     *
     * @private
     */
    _initPageObjects: function(){
        var self = this;
        self.$businessLineSelect = $('#business_line_id');
        self.$syndicatedProductTypesSelect = $('#syndication_product_types');
        self.$featureTvProductTypesSelect = $('#feature_tv_product_types');
    },

    showProductTypes: function(product_type_select) {
        product_type_select.show().find('select').removeAttr('disabled');
    },

    hideProductTypes: function(product_type_select) {
        product_type_select.hide().find('select').attr('disabled', 'disabled');
    },

    /**
     *
     * @private
     */
    _initHandlers: function(){
        var self = this;

        self.$businessLineSelect.on('change', function(){
            if(self.$businessLineSelect.val() == syndication_id)
            {
                self.showProductTypes(self.$syndicatedProductTypesSelect);
                self.hideProductTypes(self.$featureTvProductTypesSelect);
	            return true;
            }
            if(self.$businessLineSelect.val() == doa_id)
            {
                self.hideProductTypes(self.$syndicatedProductTypesSelect);
                self.hideProductTypes(self.$featureTvProductTypesSelect);
	            return true;
            }

	        self.hideProductTypes(self.$syndicatedProductTypesSelect);
	        self.showProductTypes(self.$featureTvProductTypesSelect);
        });

        self.$businessLineSelect.change();
    }
};

(function () {
    if($('#launch_wizard_form').length > 0){
        DisneyABC.dealWizardLauncher.init();
    }
}());

(function ($, document, window, undefined) {

/**
 * Restrict Language and Territory based on "File Type" on asset forms (used to also restrict LOB)
 * @param  {int} file_subtype_id
 */
function file_type_restriction(file_subtype_id){
	var request = $.ajax({              // ajax call
        url: DisneyABC.baseUrl+'/file/restriction/'+file_subtype_id,
        type: 'GET',
        dataType: 'html'
    });

    // when request is complete
    request.done(function(data) {
      data = $.parseJSON(data);
      var force = false;
      var force_lob = false; // currently disabled
      var force_territory = false; // currently disabled
      if($("#add-doc-files").length || $("#adding-video").length || $("#adding-artwork").length){ // force when adding media, but visually suggest when editing
        force = true;
      }
      if(typeof data.BusinessLine != 'undefined'){
        if(force_lob){
          $('#business_line_ids input').attr('disabled', true); // disable if there are business line rules
        }
        $('#business_line_ids label').not('[for="business_line_ids_all"]').addClass('disable-this');
      }else{
        if(force_lob){
          $('#business_line_ids input').attr('disabled', false); // re-enable checkboxes if there are no rules
        }
        $('#business_line_ids label').removeClass('disable-this');
      }
      if(typeof data.Territory != 'undefined'){
        if(force_territory) {
	      $('.territory option').attr('disabled', true); // disable if there are territory rules
        }
	    $('.territory option').addClass('disable-this');
      }else{
        if(force_territory) {
	     $('.territory option').attr('disabled', false); // re-enable options if there are no rules
        }
	    $('.territory option').removeClass('disable-this');

      }
      if(typeof data.Language != 'undefined'){
        if(force){
          $('.language option').attr('disabled', true); // disable if there are territory rules
        }
        $('.language option').addClass('disabled');
      }else{
        if(force){
          $('.language option').attr('disabled', false); // re-enable options if there are no rules
        }
        $('.language option').removeClass('disabled');
      }
      jQuery.each(data, function(index, value) {
        if(index == 'BusinessLine'){ // enable checkboxes that are allowed for this file subtype
          jQuery.each(value, function(bl_index, bl_value) {
            var id = $("#business_line_ids input[value="+bl_value+"]").attr('id');
            if(force_lob){
              $("#business_line_ids input[value="+bl_value+"]").attr('disabled', false); // id has a [] in it and errors if I call it here
            }
            $('label[for="'+id+'"]').removeClass('disable-this');
          });
          $('#business_line_ids input:checkbox:disabled').prop('checked', false); // some fields may have been checked by 'all', uncheck if disabled
        }
        if(index == 'Territory'){ // enable options that are allowed for this file subtype
          jQuery.each(value, function(t_index, t_value) {
            if(force_territory) {
	          $('.territory').select2("val", t_value); // update the selected option
	          $('.territory option[value="' + t_value + '"]').attr('disabled', false);
            }
            $('.territory option[value="'+t_value+'"]').removeClass('disable-this');
          });
        }
        if(index == 'Language'){ // enable options that are allowed for this file subtype
          jQuery.each(value, function(l_index, l_value) {
            $('.language').select2("val", l_value); // update the selected option
            if(force){
              $('.language option[value="'+l_value+'"]').attr('disabled', false);
            }
            $('.language option[value="'+l_value+'"]').removeClass('disabled');
          });
        }
      });

    });

    // Fail
    request.fail(function(jqXHR, textStatus) {
      var response = $.parseJSON(jqXHR.responseText);
      var message = new Message(response.error.message);
      message.showMessage();

    });
  }
  // fire a change on file_subtype_id when adding or editing a Documents, Video or Image
  if($("#add-doc-files").length || $("#edit-doc-files").length || $("#asset_upload_video").length || $("#asset_upload_image").length){ // used in add/edit document and video
    $("#file_subtype_id").change(function(e){
      file_type_restriction($('#file_subtype_id').val());
    });
  }

})(jQuery, document, window);

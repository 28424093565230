$(function() {
    var $circles = $('#circles');

    if ($circles.length > 0) {
        var ajaxUrl = '/datatables/circle_clearances/' + $circles.data('product-id');
        $circles.ajaxTable({
            ajaxUrl : ajaxUrl
        });
    }

    var $vod = $('#vod_table');

    if ($vod.length > 0) {
        //ajaxTable.init($vod, '/datatables/vod?with=business_line');
        $vod.ajaxTable({
            ajaxUrl : '/datatables/vod?with=business_line'
        });
    }

    var $tv = $('#tv');

    if ($tv.length > 0) {
        $tv.ajaxTable({
            ajaxUrl : '/datatables/tv?with=products'
        });
    }

    var $order_list_table = $('#order_list_table');
    if ($order_list_table.length > 0) {
        $order_list_table.ajaxTable({
            rowTemplate : 'order_list',
            ajaxUrl     : '/orders',
            orderBy     : 'orders.created_at',
            dir         : 'desc'
        })
    }

    var $episodes_table = $('#season_episodes_table, #tvseries_episodes_table');

    if ($episodes_table.length > 0) {
        var options = {
            rowTemplate : 'episode_list'
        };
        if ($episodes_table.attr('data-season-id')) {
            options.season_id = $episodes_table.attr('data-season-id');
        }
        if ($episodes_table.attr('data-program-id')) {
            options.program_id = $episodes_table.attr('data-program-id');
        }

        // options[season_id] = $episodes_table.attr('data-season-id');
        $episodes_table.ajaxTable({
            rowTemplate : 'episode_list',
            ajaxUrl     : '/datatables/titles?' + $.param(options),
            orderBy     : 'titles.domestic_number,titles.foreign_number,titles.production_number',
            dir         : 'desc',
            sort        : 'domestic_number'
        });
    }

    $('.ajax-table').each(function(){
        $(this).ajaxTable(
            ajaxTableGetSettings($(this))
        );
    });
});

/**
 * Get the settings for the ajax table from the data attributes
 * @param $table
 * @returns {{rowTemplate: null, ajaxUrl: null, orderBy: null, dir: null, sort: null}}
 */
function ajaxTableGetSettings($table){
    var settings = {
        rowTemplate : null,
        ajaxUrl     : null,
        orderBy     : null,
        dir         : null,
        sort        : null
    };

    // fill out the settings if they are available, or leave it as null
    settings.ajaxUrl = $table.data('ajax-url') || null;
    if($table.data('ajax-form')) {
        settings.ajaxUrl += '?' + $($table.data('ajax-form')).serialize();
    }

    settings.rowTemplate = $table.data('row-template') || null;
    settings.orderBy = $table.data('order-by') || null;
    settings.dir = $table.data('dir') || null;
    settings.sort = $table.data('sort') || null;

    return settings;
}

/**
*  Message method is used to display notifications to the user throughout the site.
*  @param: description
*
*  How to use:
*  var message = new Message('description');
*  message.showMessage();
*  message.hideMessage('timeout'); // a timeout value can be used here.
*
*/
var Message = function(description,type,title) {

    //  Setting $wrapper, type and title values
    var $wrapper = $('#messagearea');
    // Define description if it isn't defined.
    if(typeof description === 'undefined') {
        this.description = 'An error has occured.';
    } else {
        this.description = description;
    }
    // Define type if it isn't defined.
    if(typeof type === 'undefined') {
        this.type = 'info';
    } else {
        this.type = type;
    }
    // Define title if it isn't defined.
    if(typeof title === 'undefined') {
        this.title = '';
    } else {
        this.title = title;
    }

    /**
    *  getMessage returns a fully stylized message with title and description.
    */
    this.getMessage = function() {
        var icon = 'exclamation-sign';
        
        if(this.type == 'error'){
            icon = 'remove-circle';
        }
        if(this.type == 'success'){
            icon = 'ok-circle';
        }

        return '<div id="messagebox" class="alert alert-' +
                this.type.toLowerCase() + '"><div class="container">' +
                '<div class="row" style="padding:10px 0px;">' +
                '<div class="pull-left" style="margin:8px 40px;">' +
                '<i class="glyphicon glyphicon-'+icon+' glyphicon-3x"></i></div>' +
                '<button type="button" class="close-alert pull-right" title="Delete Alert">' +
                '&times;</button><div  class="pull-left"><h4 style="margin-bottom:10px;">' +
                this.title + '</h4>' + this.description + '</div></div></div></div>';
    };

    /**
    *  showMessage inserts the returned getMessage into the #messagearea div.
    */
    this.showMessage = function() {
        $wrapper.after(this.getMessage());
        this.closeOnSelect();
    };

    /**
     * closeOnSelect should listen for a close click after showMessage
     */
    this.closeOnSelect = function() {
        $('.close-alert').on('click', function() {
            $(this).parents('#messagebox').remove();
        })
    };

    /**
    *  hideMessage takes a value and hides the message after timing out. default = 0;
    *  @param: wait
    */
    this.hideMessage = function(wait) {
        if(typeof wait === 'undefined'){
            wait = 0;
        };
        setTimeout( function() {
            $wrapper.hide();
        }, wait);
    };
};

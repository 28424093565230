/* globals asperaWeb:true */
/**
 * AsperaInstaller handles installation of aspera connect and establishes
 * connection between the DisneyABC site and the client.
 */
DisneyABC.AsperaInstaller = (function() {
    'use strict';

    /**
     * Configure the installer paths.
     */
    function installerPaths() {
        // Local versions of aspera vendor files.
        var baseUrl = $('body').data('baseurl');
        var web4Path = baseUrl + '/vendors/asperaweb-4.js';
        var connect4Path = baseUrl + '/vendors/connectinstaller-4.js';
        var connectVer = baseUrl + '/vendors/connectversions.js';

        // Load getScripts
        getScripts(web4Path, connect4Path, connectVer);
    }

    /**
     * Loads the scripts for asperaweb-4, connectinstaller-4 and connectversions
     * @param web4Path
     * @param connect4Path
     * @param connectVer
     */
    function getScripts(web4Path, connect4Path, connectVer) {
        // Number of installers loaded
        var installCount = 0;
        $.getScript(web4Path, function(data, textStatus, jqxhr) {
            scriptsLoaded(++installCount);
        });

        $.getScript(connect4Path, function(data, textStatus, jqxhr) {
            scriptsLoaded(++installCount);
        });

        $.getScript(connectVer, function(data, textStatus, jqxhr) {
            scriptsLoaded(++installCount);
        });
    }

    /**
     * Count to ensure that all 3 scripts are loaded.
     * @param installCount
     */
    function scriptsLoaded(installCount) {
        // If all three scripts are loaded proceed
        if (installCount === 3) {
            startConnect();
        }
    }

    /**
     * Once scripts are loaded start Aspera
     */
    function startConnect() {
        // Creates new AW4.Connect Object
        var asperaWeb = new AW4.Connect();

        // Creates new AW4.ConnectInstaller Object
        // asperaInstaller = new AW4.ConnectInstaller();
        // Event Listener for AW4
        asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);

        // Initialize Aspera Session
        asperaWeb.initSession();
    }

    /**
     * Provides feedback to the user pertaining to their current status in regards to
     * downloading, installing, updating and connecting to Aspera Connect.
     * @param eventType
     * @param data
     */
    function statusEventListener(eventType, data) {
        if (eventType === AW4.Connect.EVENT.STATUS) {
            var asperaDownloadLink = 'https://www.ibm.com/aspera/connect';
            var title = '<h3>Aspera Connect has failed to open automatically</h3>';
            var message = 
                '<b>' +
                    'If Aspera is Installed on your computer, please open it manually.' +
                '</b>' +
                '<p>' +
                    'If it does not work, please update your Aspera Connect client, you can download and install the latest version from ' +
                    '<b>' +
                        '<a href="' + asperaDownloadLink + '" target="_blank" rel="noopener noreferrer">here.</a>' + 
                    '</b>' +
                '</p>' +
                'When you are finished please refesh the page.';

            if (data === AW4.Connect.STATUS.FAILED) {
                messageText(title + message);
            } else if (data === AW4.Connect.STATUS.OUTDATED) {
                title = '<h3>Your version of Aspera Connect is out of date</h3>';
                message = 
                    '<p>' +
                        'Please download it ' +
                        '<b><a href="' + asperaDownloadLink + '" target="_blank" rel="noopener noreferrer">here.</a></b>' +
                    '</p>' +
                    'When you are finished please refesh the page.';
                messageText(title + message);
            }
        }
    }

    /**
     *   Creates a message to display to the user if aspera has failed.
     */
    function messageText(status) {
        var message = new Message('<div class="aspera-box">' +
            '<div class="aspera-logo-box">' +
            '<img src="http://downloads.asperasoft.com/images/pic_th/icon_connect.png">' +
            '</div><div class="aspera-logo-box">' + status + '</div></div>');
        message.showMessage();
    }

    /**
     *   If a button class related to Aspera exisits start the process.
     */
    var asperaActivators = [
        '.aspera_download_selected_button',
        '#select_aspera_file',
        '.file-input',
        '.anchorbutton',
        '#select_cc_file',
        '#select_preview_file',
        '.aspera_upload_file_path',
        '.aspera_upload_cc_file_path',
        '.aspera_upload_preview_file_path'
    ];

    if ($(asperaActivators.join(',')).length) {
        installerPaths();
    }

})();

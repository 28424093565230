DisneyABC.Search = function() {
    'use strict';

    /**
     * The search title form
     * @type {jQuery}
     */
    var $searchTitle = $('.search-title');
    /**
     * The season dropdown object
     * @type {jQuery}
     */
    var $titleSeasonGroup = $('.search-title .season_dropdown');
    /**
     * The advanced search tools
     * @type {jQuery}
     */
    var $advanced = $('#advanced');
    /**
     * Authenticated clear button
     * @type {jQuery}
     */
    var $clearAuthenticated = $('#clear_authenticated');
    /**
     * Title type selection
     * @type {jQuery}
     */
    var $titleTypeId = $('#title_type_id');
    /**
     * Program selection
     * @type {jQuery}
     */
    var $titleProgramGroup = $('.search-title .program_dropdown');
    /**
     *
     * @type {jQuery}
     */
    var $nonEpisodeFilters = $('.search-title .non-episode_filters');
    /**
     *
     * @type {jQuery}
     */
    var $episodeFilters = $('.search-title .episode_filters');
    /**
     *
     * @type {jQuery}
     */
    var $promoNext = $('#promo_next');
    /**
     *
     * @type {jQuery}
     */
    var $season = $('#season_id');
    /**
     *
     * @type {jQuery}
     */
    var $program = $('#program_id');

    /**
     *
     *
     * @param id
     * @returns ajax
     */
    function seasonDropdownAjax(id) {
        return $.ajax({
            url: DisneyABC.baseUrl + '/get_season_select',
            data: {
                'filter': id
            },
            type: 'GET',
            dataType: 'html'
        });
    }

    /**
     *
     * @param program
     * @returns string
     */
    function getProgramName(program) {
        return program.name;
    }

    /**
     * Set the title label based on the option that has been selected
     * @param selected
     */
    function setTitleLabelText(selected) {

        // remove any surrounding spaces
        selected = selected.trim();

        // default text
        var text = 'Feature / Episode';

        // possible replacements
        var replacements = {
            'Any': 'Feature / Episode',
            'Features': 'Feature',
            'Segment': 'Segment',
            'Short': 'Short',
            'Special': 'Special',
            'TV Series': 'Episode Name'
        };

        if (selected in replacements) {
            text = replacements[selected];
        }

        $('.search-title label[for=non-episode_name]').text(text);
        $('.search-title label[for=name]').text(text);
    }

    /**
     * Toggle the tv series filters
     * @param selected
     */
    function toggleTvSeriesFields(selected) {

        if (selected === 'TV Series') {
            $nonEpisodeFilters.hide().find('input').prop('disabled', true);
            $titleProgramGroup.show();
            $titleSeasonGroup.show();
            $titleProgramGroup.find('input').prop('disabled', false);
            $episodeFilters.show();

            if ($advanced.hasClass('collapse')) {
                $episodeFilters.find('input').prop('disabled', true);
            } else {
	            $episodeFilters.find('input').prop('disabled', false);
            }
        } else {
            $nonEpisodeFilters.show().find('input').prop('disabled', false);
            $titleProgramGroup.hide().find('input').prop('disabled', true);
            $episodeFilters.hide().find('input').prop('disabled', true);
        }
    }

    $titleTypeId.on('change', function() {
        setTitleLabelText($(this).find(':selected').text());
        toggleTvSeriesFields($(this).find(':selected').text());
    });

    if ($searchTitle.length) {

        $advanced.on('hidden.bs.collapse', function() {
            $advanced.find('input').prop('disabled', true);
        });

        $advanced.on('shown.bs.collapse', function() {
            $advanced.find('input:visible').prop('disabled', false);
        });

        // disable advanced options on load
        if (!$advanced.hasClass('in')) {
            $advanced.find('input').prop('disabled', true);
        }

        // clear authenticated radio button on search titles
        $clearAuthenticated.on('click', function() {
            $('input:radio').prop('checked', false);
        });
        var season_id = $('#season_id').attr('data-default');

        $titleProgramGroup.find('input').on('change', function() {
            var id = $(this).val();
            if (id <= 0) {
                $titleSeasonGroup.find('select').prop('disabled', true).select2('data', null);
                $titleSeasonGroup.show();
            }
        });

        $season.select2({
            allowClear: true
        });

        $titleProgramGroup.find('input').on('change', function() {
            var id = $(this).val();

            $titleSeasonGroup.find('select').select2('enable', false).select2('data', null);
            $promoNext.prop('disabled', true);
            if (id > 0 ) {
                var request = seasonDropdownAjax(id);
                request.done(function(data) {
                    data = $.parseJSON(data);
                    $titleSeasonGroup.find('select').select2('enable', true).html(data.html);
                    $titleSeasonGroup.find('select').select2('val', season_id);
                });
            }
        });

        $titleProgramGroup.find('#program_id').change();
    }

    if ($('.no_select2_for_program').length === 0) {

        $program.select2({
            placeholder: 'Search for a TV Series',
            minimumInputLength: 2,
            allowClear: !Boolean($program.attr('required')),
            ajax: {
                url: DisneyABC.baseUrl + '/get_program_select',
                dataType: 'json',
                data: function(term/*, page*/) {
                    return {
                        filter: term
                    };
                },

                results: function(data/*, page*/) {
                    return {
                        results: data.programs
                    };
                }

            },

            initSelection: function(element, callback) {
                var id = $(element).val();
                if (id !== '') {
                    $.ajax(DisneyABC.baseUrl + '/get_program_select', {
                        dataType: 'json',
                        data: {
                            id: (element.val())
                        },
                        success: function(data) {
                            callback(data);
                        }
                    });
                }

            },

            formatResult: getProgramName,
            formatSelection: getProgramName,
            escapeMarkup: function(m) {
                return m;
            }
        });

    }

    $titleTypeId.change();

    //if there is a selected season on load, show the season dropdown
    if ($titleProgramGroup.find(':selected').length) {
        $titleSeasonGroup.find('select').prop('disabled', false).show().change();
    }

    $titleProgramGroup.find('input').change();
};

$(document).ready(function() {
    'use strict';
    DisneyABC.Search();
});


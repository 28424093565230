/* global DisneyABC, alert, AW4, Message, console, window */

/**
 * Create Console - Handles console errors in legacy browsers.
 */
var Console = (function() {

	// If the console isn't defined, create it.
    if(typeof window.console === "undefined") {
        window.console = {
            log: function() { },
            debug: function() { }
        };
    }

})();

DisneyABC.addCircles = {
    /*
     ************************
     MULTIPLE SELECT2 CIRCLES
     ************************
     */
    // used by satellite transmission forms and add circle clearances admin
    //if($('body #add_circle_to_product').length > 0 || $('body #refeed_list').length > 0){
    // 	-if pre-selecting options, use the format "id:name|id:name" for the input value
    // 	-you can limit the circle types pulled back by adding this to the input field: data-circle_type_id=#

    $adddoc_file_associations: null,

    _initPageObjects: function(){
        var self = this;
        // Add Document
        self.$adddoc_file_associations = $('.adddocument #product_ids, .adddocument #document_clearance');

    },

    circle_url: function(circle_id){
        if(typeof circle_id != 'undefined' && circle_id){
            return $('body').attr('data-baseurl') + "/circle?circle_type_id="+circle_id;
        } else {
            return $('body').attr('data-baseurl') + "/circle";
        }
    },


    formatCircleResult: function(results) {
        var markup = "<table class='movie-result'><tr>";
        markup += "<td class='movie-info'><div class='movie-title'>"+results.name+"</div>";
        markup += "</td></tr></table>"
        return markup;
    },

    formatCircleSelection: function(results) {
        return results.name;
    },

    // intended for a Title with Products and Clearances
    init: function() {
        var self = this;
        self._initPageObjects();
        if (self.$adddoc_file_associations.length) {
            $('#file_association').change(function (e) {
                // doesn't know a resource id at the start (doesn't start on Title)
                if ($("#title_id").length) {
                    $("#add-multiple-circles").attr('data-resource_id', '');
                    // restore resource id for Title (does not have a select list)
                    if ($('#file_association').val() == 'Title') {
                        $("#add-multiple-circles").attr('data-resource_id', $("#title_id").val());
                    }
                }
            });

            // if there are multiple resource ids, comma separate them
            self.$adddoc_file_associations.change(function (e) {

                var selMulti = $.map($("option:selected", this), function (el, i) {
                    return $(el).val();
                });
                $("#add-multiple-circles").attr('data-resource_id', selMulti.join(","));

            });
        }
        //self.initJobFunctionSelect();
    },
    addMultipleCircles: function(scope) {
        var self = this;
        $(scope).find("#add-multiple-circles").select2({
            //placeholder: "Search for a circle",
            minimumInputLength: 1,
            multiple: true,
            separator: "|",
            ajax: {
                url: self.circle_url($('#add-multiple-circles').attr('data-circle_type_id')),
                dataType: 'json',
                type: "get",
                quietMillis: 500,
                data: function (term, page) {
                    var association = $('#file_association').val();
                    var resource_ids = $("#add-multiple-circles").attr('data-resource_id');
                    // check on Title circles if another fileassociation is selected but a resource id is not
                    if (!resource_ids && $("#title_id").length) {
                        association = 'Title';
                        resource_ids = $("#title_id").val();
                    }

                    return {
                        association: association,
                        resource_ids: resource_ids, // single or comma separated
                        filter: term,       // search term
                        page_limit: 10 // how many results, this needs to be enforced by the called url
                    };
                },
                results: function (data, page) {
                    var currentCircles = $('#add-multiple-circles').val()
                        .split('|').filter(function (element) { 
                            return element.indexOf(':') >= 0; 
                        }).map(function (element) { return element.split(':')[0]; });
                    var results = data.results.filter(function (result) { 
                        return currentCircles.indexOf(result.id) < 0; 
                    });
                    return {results: results};
                }
            },
            initSelection: function (element, callback) {
                var data = [];
                $(element.val().split("|")).each(function (i) {
                    var item = this.split(':');
                    data.push({
                        id: item[0],
                        name: item[1]
                    });
                });
                callback(data);

            },
            id: function (object) { // make the results consistent, so they show right on page error
                // note, you need to strip out the ids after for add/edit
                return object.id + ":" + object.name;
            },
            formatResult: self.formatCircleResult,
            formatSelection: self.formatCircleSelection,
            escapeMarkup: function (m) {
                return m;
            }    // we do not want to escape markup since we are displaying html in results
        });
        $(scope).find("#add-multiple-circles").on('change', function(event){
            var self = this;
            var uniqueItems = [];
            var items = [];
            if (event.removed) {
                var removedElement = (event.removed.id + ':' + event.removed.name).trim();
                items = event.val.filter(function(element) { 
                    return element !== removedElement; 
                });
            } else {
                items = event.val;
            }
            items = items.filter(function(item) { 
                return item.indexOf(':')>=0; 
            })
            .map(function(item) {
                var parsedItem = item.split(':');
                return {
                    id: parsedItem[0],
                    name: parsedItem[1].trim()
                };
            });
            items.forEach(function(item){
                if (!uniqueItems.find(function(uniqueItem) { return uniqueItem.id === item.id})) {
                    uniqueItems.push(item);
                }
            });
            self.value = uniqueItems.map(function(ui) { 
                return ui.id + ':' + ui.name; 
            }).join('|');
        });
    },
    /*
     **********************
     SINGLE SELECT2 CIRCLES
     **********************
     */

    addCircle: function(scope) {
        var self = this;
        $(scope).find("#add-circle").select2({
            //placeholder: "Search for a circle",
            minimumInputLength: 1,
            allowClear: true,
            ajax: {
                url: self.circle_url($('#add-circle').attr('data-circle_type_id')),
                dataType: 'json',
                type: "get",
                quietMillis: 500,
                data: function (term, page) {
                    return {
                        filter: term, 		// search term
                        page_limit: 10 // how many results, this needs to be enforced by the called url
                    };
                },
                results: function (data, page) {
                    return {results: data.results};
                }
            },
            initSelection: function (element, callback) {
                var id = $(element).val();
                if (id !== "") {
                    $.ajax($('body').attr('data-baseurl') + "/circle", { // shows circle name on error
                        dataType: "json",
                        data: {id: (element.val())},
                        success: function (data) {
                            callback(data);
                        }
                    });
                }

            },

            formatResult: self.formatCircleResult,
            formatSelection: self.formatCircleSelection,
            escapeMarkup: function (m) {
                return m;
            } 	// we do not want to escape markup since we are displaying html in results
        });
    },

    initJobFunctionSelect: function() {
        var configSelect2 = {
            element: selectRecipient,
            multiple : true,
            minimumInputLength: 1,
            ajax: {
                url: '/admin/notifications/filter-recipients/job-function',
                results : function(data, page) {
                    return {
                        results :
                            data.map(function(item) {
                                return {
                                    id : item.id,
                                    text : item.name
                                };
                            }
                    )};
                }
            },
            /* initSelection: function(element, callback) {
                var selectvalue = $(element).val();
                var filterValues = JSON.parse(selectvalue);
                callback(filterValues);
                filterValues.forEach(function(filterValue) {
                    var inputFilterRecipients = $('<input/>',{
                        'type':'hidden',
                        'value': filterValue.text,
                        'name': $(element).data('name') + '[' + filterValue.id + ']',
                        'class': $(element).data('url') + filterValue.id
                    });
                    $('#notification-create-form').append(inputFilterRecipients);
                });
                
            } */
        }

        var select2General = new Select2General(configSelect2);
        select2General.init();
    }

};

DisneyABC.deleteCircles = {
    init: function() {

        $('#delete_org_button').click(function(event) {
            if (!confirm('Are you sure you want to delete this organization?')) {
                event.preventDefault();
            }
        });
    }
};

(function () {
    if($('#add-circle').length || $('#add-multiple-circles').length){
        DisneyABC.addCircles.init();
    }
    if($('#delete_org_button').length){
        DisneyABC.deleteCircles.init();
    }
}());

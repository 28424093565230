/**
 * [duration_template description]
 * @param  {[type]}
 * @return {[type]}
 */
$(function duration_template() {

    var $template = $('.duration_template:first'),
        sectionId = 0,
        i = $('.duration_template').length - 1;

    var fieldNamespace = $('.duration_template:first').attr('data-field-namespace');

    // just in case?
    if(!fieldNamespace){
        fieldNamespace = 'file[file_dates]';
    }

    /**
     * Copy the first duration row entry, empty it out, then add it to the end of the duration list
     * @returns {number}
     */
    function addDuration() {
        var $newRow = $template.clone().attr('id', 'id' + (++i));

        $newRow = modifyStartDate($newRow);
        $newRow = modifyEndDate($newRow);
        $newRow = modifyIdField($newRow);
        $newRow = modifyFileIdField($newRow);
        $newRow = modifyDeleteButton($newRow);

        $newRow.appendTo('.duration_section');

        $newRow.find('.datetimepicker').datetimepicker();

        return i;
    }

    /**
     * Change the begin_at name and clear out the value
     * @param $row {jQuery object}
     * @returns {jQuery object} The modified row
     */
    function modifyStartDate($row) {
        var id = 'file-file_dates-' + i + '-begin_at';
        var name = fieldNamespace + '[' + i + '][begin_at]';
        //id = name;

        // change the label for target
        $row.find('label.begin-date')
            .attr('for',id);


        $row.find('input.file_date_begin_at')
            .removeClass('hasDatepicker')
            .attr('name', name)
            .attr('id', id)
            .val('')
            // chrome was leaving the value in the html, but it was not being displayed
            .attr('value', '');

        return $row;
    }

    /**
     * Change the end_at name and clear out the value
     * @param $row {jQuery object}
     * @returns {jQuery object} The modified row
     */
    function modifyEndDate($row) {

        var name = fieldNamespace + '[' + i + '][end_at]';
        var id = 'file-file_dates-' + i + '-end_at';

        //id = name;

        // change the label for target
        $row.find('label.end-date')
            .attr('for',id)
            .end();

        // change the input box
        $row.find('input.file_date_end_at')
            .removeClass('hasDatepicker')
            .attr('name', name)
            .attr('id', id)
            .val('')
            // chrome was leaving the value in the html, but it was not being displayed
            .attr('value', '')
            .end();

        return $row;
    }

    /**
     * Change the file_date_id name and clear out the value
     * @param $row {jQuery object}
     * @returns {jQuery object} The modified row
     */
    function modifyIdField($row){
        return $row.find('input.file_date_id')
            .attr('name', fieldNamespace + '[' + i + '][id]')
            .val('')
            // chrome was leaving the value in the html, but it was not being displayed
            .attr('value', '')
            .end();
    }

    /**
     * Increments the file_dates index so that the file_id is passed in as well
     * @param $row {jQuery object}
     * @returns {jQuery object} The modified row
     */
    function modifyFileIdField($row){
        return $row.find('input.file_id')
            .attr('name', fieldNamespace + '[' + i + '][file_id]')
            .end();
    }

    /**
     * modifyDeleteButton finds the add date button
     * by id then modifies the text and class
     * @param  {[int]}
     */
    function modifyDeleteButton($row) {
        return $row.find('a.copy-date')
            .text('Delete')
            .toggleClass('copy-date delete-date')
            .end();
    }

    /**
     * Sets up the copydate event
     */
    $('.copy-date').on("click", function () {
        sectionId = addDuration();
    });

    /**
     * Sets up the delete event
     */
    $('.duration_section').on("click", ".delete-date", function () {
        $(this).parents('.duration_template').remove();
    });

});

var TemplatesAdmin = {
    initDatatable : function() {
        var configDatatable = {
            idDatatable : 'templates-datatable',
            ajax : '/admin/templates',
            columns : [
                { 
                    'data' : 'id',
                    'title':'Select all <input type="checkbox" class="check-all" style="font-size:17px;margin-left:15px;">',
                    'orderable' : false,
                    'searchable' : false,
                    'className' : 'dt-center',
                    'render' : function(data, type, row) {
                        var checkbox = '';
                        if(row.status != 'archived') {
                            checkbox = [
                                '<input',
                                'type="checkbox"',
                                'value="' + data + '"',
                                'class="children-check-all"',
                                'style="font-size:17px;"',
                                'onclick="TemplatesAdmin.enableButtonDelete();">'
                            ].join(' ');
                        }
                        return checkbox;
                    },
                    'width' : '15%'
                },
                { 
                    'data' : 'name',
                    'title' : 'Title',
                    'width' : '30%',
                    'render' : function ( data, type, row, meta ) {
                        var link = [
                            '<a',
                            'href="/admin/templates/id/' + row.id + '">',
                            data,
                            '</a>'
                        ].join(' ');
                        return link;
                    },
                },
                { 
                    'data' : 'created_at',
                    'title' : 'Date Created',
                    'defaultContent' : '',
                    'width' : '15%' 
                },
                { 
                    'data' : 'created_by',
                    'title' : 'Created By', 
                    'defaultContent' : '',
                    'width' : '25%' 
                },
                { 
                    'data' : 'status',
                    'title' : 'Status',
                    'defaultContent' : '',
                    'width' : '15%' 
                }
            ],
            createdRow: function(row,data) {
                if(data.status == 'archived') {
                    $(row).addClass( 'row-disabled' );
                }
            }
        }
        var datatablesGeneral = DatatablesGeneral(configDatatable);
        this.templatesDatatable = datatablesGeneral.tableInitializated;
    },

    initWysiwygField: function() {
        wysiwygGeneral = new WysiwygGeneral();
        wysiwygGeneral.init({idElement:'template-body-summernote'});
    },

    checkAll : function() {
        $('.check-all').click(function() {
            $('.children-check-all', TemplatesAdmin.templatesDatatable.cells().nodes())
            .prop('checked',$('.check-all').is(':checked'));

            $('#templates-button-delete').attr('disabled',!$(this).is(':checked'));
        });
    },

    assignEventStatusSelect: function() {
        $('#template-status').change(function() {
            $('#templates-button-saveclose').hide();
            if($(this).val() == 'available') {
                $('#templates-button-saveclose').fadeIn();
            }
        });
        $('#template-status').change();
    },

    enableButtonDelete : function() {
        var isAnyChecked = $('.children-check-all').is(':checked');
        $('#templates-button-delete').attr('disabled',!isAnyChecked);
        var isAllChecked = $('.children-check-all:checked').length == $('.children-check-all').length;
        $('.check-all').prop('checked',isAllChecked);
    },

    assignEventDeleteButton : function() {
        $('#templates-button-delete').click(function() {
            var formTemplateManager = $('#templates-manager-form');
            formTemplateManager.find('[name="templateIds[]"]').remove();
            $('.children-check-all:checked', TemplatesAdmin.templatesDatatable.cells().nodes())
            .each(function() {
                var checkToSend = $('<input/>',{
                    type :'checkbox',
                    value :$(this).val(),
                    name : 'templateIds[]',
                    checked : 'checked'
                }).css('display','none');
                console.log(checkToSend);
                formTemplateManager.append(checkToSend);
            });

            $('#detele-confirmation-modal').show();
        });
    },

    assignEventSaveAsButton : function() {
        $('#templates-button-save').click(function() {
            if(!$('[name="name"]').val()) {
                $('#templates-validation-modal').show();
                return;
            }   
            var messageModal = 'Do you confirm to save the template ' + $('[name="name"]').val() + '?';
            $('#templates-save-modal-message').text(messageModal);
            $('#templates-save-modal').show();
        });
        $('#templates-save-modal-ok-button').click(function() {
            $('#templates-create-form').submit();
        });
    },

    assignEventSaveAndCloseButton : function() {
        $('#templates-button-saveclose').click(function() {
            if(!$('[name="name"]').val()) {
                $('#templates-validation-modal').show();
                return;
            }
            var id = $('#template-id').val() ? $('#template-id').val() : 0;
            $('#templates-create-form').attr('action','/admin/templates/save-close/id/' + id);
            $('#templates-create-form').submit();
        });
    },

    initManager: function() {
        this.initDatatable();
        this.checkAll();
        this.assignEventDeleteButton();
    },

    initDetail: function() {
        this.initWysiwygField();
        this.assignEventSaveAsButton();
        this.assignEventSaveAndCloseButton();
        readOnlyMode();
        this.assignEventStatusSelect();
    },
}

$(document).ready(function(){
    TemplatesAdmin.initManager();
    TemplatesAdmin.initDetail();
});
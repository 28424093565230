if (!Array.isArray) {
    Array.isArray = function(arg) {
        'use strict';
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

/**
 *
 * @constructor
 */
DisneyABC.Downloads = function() {
    'use strict';

    $('.btn-download-asset').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        var assetId = $(this).data('asset-id');
        var url = $(this).attr('href');
        if (!url) {
            url = $(this).data('href');
        }

        $(this).attr('href', '');
        $(this).data('href', url);

        $.get(url)
            .done(function(data) {
                var files = [];
                for (var which in data) {
                    if (data.hasOwnProperty(which)) {
                        if (data[which]) {
                            files.push('/download/' + assetId + '/' + which);
                        }
                    }
                }

                downloadMultiple(files);
            })
            .fail(function(jqXHR, data) {
                // matches what the aspera error message does
                console.error('Failure downloading file(s).');
                var response = $.parseJSON(jqXHR.responseText);
                var message = new Message(response.error.message);
                message.showMessage();
                message.closeOnSelect();
            });

        return false;
    });

    /**
     * Try and download an array of files
     * @param files array
     */
    function downloadMultiple(files) {
        function messageFileNoExists() {
            console.error('Failure downloading file(s).');
            var message = new Message("Sorry, the file doesn't exist");
            message.showMessage();
            message.closeOnSelect();
        }
        if(files.length) {
            $.each(files, function(key, value) {
                var iframe = $('<iframe></iframe>');
                iframe
                .hide()
                .attr('src', value)
                .appendTo($('body'))
                .load(function() {
                    if($(this).get(0).contentDocument) {
                        messageFileNoExists();
                    }    
                });
            });
        } else {
            messageFileNoExists();
        }
    }
};

DisneyABC.DownloadMultipleAssets = function () {
    function trackingDownloadAssets(assetName){
        var dataObject = {
            'event': userNameLoggedIn,
            'category': 'User-Asset',
            'label': assetName
        };
        
        if(typeof dataLayer != 'undefined'){
            dataLayer.push(dataObject);
        }
    }
      
    function addCheckAllToHeaderTable() {
        $('<input/>', {
          type: 'checkbox',
          class: 'check-all-multiple-assets',
          click: function () {
            $('.check-multiple-assets:not(:disabled)')
            .prop('checked', this.checked);  
          }
        })
        .appendTo('#assets-container .dataTable th.download_multiple_assets');
    }

    var multipleChecks = 0;
    $('.dataTable').DataTable().columns('.download_multiple_assets').nodes().to$().each(
        function () {
            if($(this).find('.check-multiple-assets').length) {
                multipleChecks++;
            }
        }
    );

    if(!multipleChecks) {
        $('.download_multiple_assets').remove();
        return;
    }

    $checkAllMultipleAssets = $('.check-all-multiple-assets');
    if($checkAllMultipleAssets.length) {
        $('.check-multiple-assets:not(:disabled)')
        .prop(
            'checked', 
            $checkAllMultipleAssets.get(0).checked
        );
    } else {
        addCheckAllToHeaderTable();
    }

    $btnDownloadMultiple = $('.btn-download-multiple-assets');
    $btnDownloadMultiple.show();
    //it was assign the event twice for that reason unbind
    $btnDownloadMultiple.unbind().click( function () {
        var checkedIds = $('.check-multiple-assets:checked');
        if(checkedIds.length) {
            var paramsUrl = '';
            checkedIds.each(function () {
                paramsUrl += 'download-multiple-assets[]=' + $(this).val();
                paramsUrl += '&';
                trackingDownloadAssets($(this).data('asset-name'));
            });
            var iframe = $('<iframe></iframe>');
            iframe
            .hide()
            .attr('src', $(this).data('link') + '?' + paramsUrl)
            .appendTo($('body'))
            .load(function() {
                if($(this).get(0).contentDocument) {
                    alert('Sorry, there was an error with the file');
                }    
            });
        }
        return false;
    });
};

$().ready(function() {
    'use strict';
    DisneyABC.Downloads();
});

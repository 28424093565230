/**
 * Stuff that happens on the addProduct pages
 */
DisneyABC.addProduct = (function($) {
	/**
     *
     * @type {number}
     */
	var add_product_change_tracker = 0;
	/**
     *
     * @type {number}
     */
	var new_run_count = 0;
	/**
     * @type {jQuery ajax object?}
     */
	var requestCopy;

	/**
     * Things that are specific to this page
     * @type {{form: null, productTypeDropdown: null, addProductTitles: null, form2: null, form4: null, titlesContainer2Content: null, dataTable: null, newRunDialog: null, newCommentsDialog: null}}
     */
	var page = {
		form: null,
		productTypeDropdown: null,
		addProductTitles: null,
		form2: null,
		form4: null,
		titlesContainer2Content: null,
		dataTable: null,
		newRunDialog: null,
		newCommentsDialog: null
	};

	/**
     * When the delete goes wrong
     * @param $row
     */
	var deleteFailure = function($row) {
		var message = new Message(quoteText($row.find('.title-name:first').text()) + ' failed to delete.');
		message.type = 'error';
		message.title = 'Error';
		message.showMessage();
		$row.removeClass('deleting');
		$row.find('inputs').attr('disabled', false);
	};

	var displayFailureMessage = function(data) {
		if (data.status === 'error') {
			var endOfMessage = 'an error.';
			if (data.code) {
				endOfMessage = 'a ' + data.code + ' error.';
			}
			var message = new Message('Failed to add new run because of ' + endOfMessage);
			message.type = 'error';
			message.title = 'Error';
			message.showMessage();
		}
	};

	/**
     * When the delete is successfull
     * @param $row
     */
	var deleteSuccessful = function($row) {
		var message = new Message(quoteText($row.find('.title-name:first').text()) + ' deleted successfully.');
		message.type = 'success';
		message.title = 'Success';
		message.showMessage();
	};

	/**
     * Add the row to the data table
     * @param row (the row data that was returned)
     */
	var addRow = function(row) {
		new DisneyABC.newRunDialog().hide();
		var index = page.dataTable.fnAddData(row);
		var $nTr = $(page.dataTable.fnSettings().aoData[index[0]].nTr);
		// put the classes back into the row so it doesn't look weird
		$(page.dataTable.fnSettings().aoData[0].nTr).find('td').each(function(columnIndex, value) {
			$nTr.find('td').eq(columnIndex).addClass($(value).attr('class'));
		});

		// create new message
		var message = new Message('Added new run of ' + quoteText($nTr.find('.title-name:first').text()));
		message.type = 'success'; // message type
		message.title = 'Success'; // message status
		message.showMessage();

		// weeee
		DisneyABC.animations.newRow($nTr);
		$nTr.addClass('new-row');

		$nTr.find('.datetimepicker').datetimepicker();
	};

	/**
     * Put quotation marks around text, also trim it so there isn't weird spacing
     * @param text
     * @returns {string}
     */
	var quoteText = function(text) {
		return "'" + $.trim(text) + "'";
	};

	/**
     *
     * @param $this
     */
	var showCorrectProductTypeList = function($this) {
		var val = $(this).val();
		$(this).closest('form').find('.product_type').attr('disabled', 'disabled');
		$(this).closest('form').find('.product_type').hide(); // hide any that might be currently showing
		if (val == syndication_id || val == doa_id) {
			$(this).closest('form').find('.product_type_id' + val).show();
			$(this).closest('form').find('.product_type_id' + val).removeAttr('disabled');
			$(this).closest('form').find('.product_type_id' + val).change();
		} else {
			$(this).closest('form').find('.product_type_id').show();
			$(this).closest('form').find('.product_type_id').removeAttr('disabled');
			$(this).closest('form').find('.product_type_id').change();
		}
		if (val == syndication_id) {
			$(this).closest('form').find('#add_product_default_rating').hide();
		} else {
			$(this).closest('form').find('#add_product_default_rating').show();
		}
	};

	/**
     *
     * @private
     */
	var _initPage = function() {
		var $dropdown = $('select#new_business_line_id');
		if ($dropdown.length) {
			showCorrectProductTypeList($dropdown);
		}
	};

	/**
     *
     * @private
     */
	var _initPageObjects = function() {
		page.form = $('#addproduct_form');
		page.productTypeDropdown = $('.product_type', '#addproduct_form');
		page.addProductTitles = $('.add_product_titles');
		page.form2 = $('#addproduct_form2');
		page.form4 = $('#addproduct_form4');
		page.titlesContainer2Content = $('#titles_container2_content');
		page.dataTable = $('#' + $('.dataTable:first').attr('id')).dataTable();
		page.newRunDialog = $('#new_run_dialog');
		page.newCommentsDialog = $('#new_comments_dialog');
	};

	var initStartExpirationDates = function() {
		var expirationDate = $('#expiration_date');
		var startDate = $('#start_date');

		if (expirationDate.length) {
			if (expirationDate.val() == '12/31/3000') {
				expirationDate.val('');
			}
			if (!expirationDate.val() && expirationDate.closest('.datetimepicker').length) {
				expirationDate.closest('.datetimepicker').data('DateTimePicker').destroy();
				expirationDate.closest('.datetimepicker').datetimepicker({
					useCurrent: false
				});
			}
		}

		if (startDate.length && startDate.closest('.datetimepicker').length && !startDate.val()) {
			startDate.closest('.datetimepicker').data('DateTimePicker').destroy();
			startDate.closest('.datetimepicker').datetimepicker({
				useCurrent: false
			});
		}
	};

	var validateStartExpirationRange = function() {
		var startDateElement = $('#start_date').closest('.datetimepicker');
		var expirationDateElement = $('#expiration_date').closest('.datetimepicker');

		if (startDateElement.length && expirationDateElement.length) {
			if ($('#start_date').val()) {
				expirationDateElement.data('DateTimePicker').setMinDate($('#start_date').val());
			}
			startDateElement.on('dp.change', function(e) {
				expirationDateElement.data('DateTimePicker').setMinDate(e.date);
			});
			if ($('#expiration_date').val()) {
				startDateElement.data('DateTimePicker').setMaxDate($('#expiration_date').val());
			}
			expirationDateElement.on('dp.change', function(e) {
				startDateElement.data('DateTimePicker').setMaxDate(e.date);
			});
		}
	};

	/**
     *
     * @private
     */
	var _initHandlers = function() {
		if (page.form2.hasClass('deal-create-doa-add-seasons')) {
			page.form2.find('input[type=submit]').attr('disabled', 'disabled');
		}

		initStartExpirationDates();
		validateStartExpirationRange();

		page.titlesContainer2Content.on('change', ':input', function() {
			add_product_change_tracker += 1;
		});

		page.form.on('change', 'select#new_business_line_id', function() {
			showCorrectProductTypeList($(this));
		});

		if (page.productTypeDropdown.length > 0) {
			page.productTypeDropdown.on('change', function() {
				var product_type = $(this).val();
				var $addProductAdminPage = $('#add_product_admin_page');
				var $addProductAdminPageMovie = $addProductAdminPage.find('.movie');
				var $addProductAdminPageNotVod = $addProductAdminPage.find('.not-vod');
				var $addProductAdminPageVod = $addProductAdminPage.find('.vod');
				var $addProductAdminPageYear = $addProductAdminPage.find('.year');
				var $addProductAdminPageBeginTime = $addProductAdminPage.find('#begin_at_time');
				var $addProductAdminPageEndTime = $addProductAdminPage.find('#end_at_time');
				var $addProductAdminPageFeatureBeginTime = $addProductAdminPage.find('#feature_begin_at_time');
				var $addProductAdminPageFeatureEndTime = $addProductAdminPage.find('#feature_end_at_time');
				var $addProductAdminPageDefaultRating = $addProductAdminPage.find('#add_product_default_rating');
				var $addProductAdminPageSeason = $addProductAdminPage.find('.season');
				var $addProductAdminPageNewProgram = $addProductAdminPage.find(' #new_program_id');
				var $addProductAdminPageProgram = $addProductAdminPage.find('.program');
				var $addProductAdminPageDefaultTime = $addProductAdminPage.find('#add_product_default_time');

				if (product_type == product_feature_id) {
					$addProductAdminPageVod.show();
					$addProductAdminPageVod.removeAttr('disabled');
				}

				if (product_type == product_package_of_features_id) {
					$addProductAdminPageVod.hide();
					$addProductAdminPageVod.attr('disabled', 'disabled');
				}

				if (product_type == product_feature_id || product_type == product_package_of_features_id) {
					// movies
					$addProductAdminPageMovie.show();
					$addProductAdminPageMovie.removeAttr('disabled');
					$addProductAdminPageNotVod.hide();

					$addProductAdminPageProgram.hide();

					$addProductAdminPageYear.hide();
					$addProductAdminPageYear.attr('disabled', 'disabled');
					$addProductAdminPageBeginTime.attr('disabled', 'disabled');
					$addProductAdminPageEndTime.attr('disabled', 'disabled');
					$addProductAdminPageFeatureBeginTime.removeAttr('disabled');
					$addProductAdminPageFeatureEndTime.removeAttr('disabled');

					$addProductAdminPageSeason.hide();
					$addProductAdminPageSeason.attr('disabled', 'disabled');
					$addProductAdminPageNewProgram.attr('disabled', 'disabled');
					$addProductAdminPageDefaultRating.hide();
					$addProductAdminPageDefaultRating.attr('disabled', 'disabled');
				} else {
					$addProductAdminPageNotVod.show();
					// episodes

					$addProductAdminPageSeason.hide();
					$addProductAdminPageSeason.attr('disabled', 'disabled');
					$addProductAdminPageDefaultTime.hide();

					$addProductAdminPageProgram.show();

					$addProductAdminPageYear.show();
					$addProductAdminPageYear.removeAttr('disabled');
					$addProductAdminPageNewProgram.removeAttr('disabled');
					$addProductAdminPageBeginTime.removeAttr('disabled');
					$addProductAdminPageEndTime.removeAttr('disabled');
					$addProductAdminPageFeatureBeginTime.attr('disabled', 'disabled');
					$addProductAdminPageFeatureEndTime.attr('disabled', 'disabled');

					$addProductAdminPageMovie.hide();
					$addProductAdminPageMovie.attr('disabled', 'disabled');
					$addProductAdminPageVod.hide();
					$addProductAdminPageVod.attr('disabled', 'disabled');

					$addProductAdminPageDefaultRating.show();
					$addProductAdminPageDefaultRating.removeAttr('disabled');
				}

				if (product_type == product_doa_id) {
					// Day of Air
					$addProductAdminPageSeason.show();
					$addProductAdminPageSeason.removeAttr('disabled');
					$addProductAdminPageDefaultTime.show();
				}
			});
		}

		/**
         * Track which titles have episode changes
         *
         * function used on the products > add product > step 2.
         * Alert user that their changes will be lost if they update the title list
         */

		page.addProductTitles.on('change', '.change_tracker', function() {
			var title_id = $(this).data('id');
			$('#addproduct_form3').append(
				'<input type="hidden" name="add_product_changed_titles[]" value="' + title_id + '" />'
			);
			add_product_change_tracker += 1;
		});

		page.titlesContainer2Content.on('click', '.new_run', function(e) {
			// dynamically added in
			e.preventDefault();

			var this_id = $(this).data('id'); // could be title id or clearance id
			var modal = new DisneyABC.newRunDialog();
			modal.setProductId($(this).data('product_id'));
			modal.setClearanceId($(this).data('id'));
			modal.setBeginAt($('#begin_at_' + this_id).val());
			modal.setBeginAtTime($('#begin_at_time_' + this_id).val());
			modal.setEndAt($('#end_at_' + this_id).val());
			modal.setEndAtTime($('#end_at_time_' + this_id).val());
			var title = $('#title_name_' + this_id).html();
			modal.setTitle(title);
			modal.setDomesticNumber($('#new_domestic_number_' + this_id).val());
			modal.setProductionNumber($('#new_production_number_' + this_id).val());
			modal.setForeignNumber($('#new_foreign_number_' + this_id).val());
			modal.setSyndicationNumber($('#new_syndication_number_' + this_id).val());
			modal.setTitleId($('#new_title_ids_' + this_id).val());
			modal.setLanguageId($('#new_language_id_' + this_id).val());
			modal.setRegionId($('#new_region_id_' + this_id).val());
			if (title === 'Pre-emption') {
				modal.setHeader('Add New Pre-emption');
			} else {
				modal.setHeader('Add New Run');
			}
			modal.show();
			return false;
		});

		page.form2.on('submit', function() {
			// if there is a change count, throw a warning
			if (add_product_change_tracker > 0) {
				return confirm('Warning, you will lose any unsaved changes. Press OK to proceed.');
			}
		});

		page.form2.on('change', 'select#season_id', function(e) {
			var $option = $(this).find('option:selected');
			var $input = $('input[type=submit]');
			$option.length > 0 ? $input.removeAttr('disabled') : $input.attr('disabled', 'disabled');
		});

		page.form4.on('click', '.new_airdates', function() {
			$('#new_airdates_dialog').modal('show');
			return false;
		});

		page.form4.on('click', '.add_circle_clearances', function() {
			$('#new_clearances_dialog').modal('show');
			return false;
		});

		// add pre-emption
		page.form4.on('click', '.new_preemption', function(e) {
			// dynamically added in
			e.preventDefault();

			var modal = new DisneyABC.newRunDialog();
			modal.setHeader('Add New Pre-emption');
			modal.setTitle('Pre-emption');
			modal.setTitleId(title_preemption_id);
			modal.setButtonText('Add');
			modal.setProductId($(this).data('product_id'));
			modal.show();
		});

		page.titlesContainer2Content.on('click', '.add_comments', function(e) {
			$(this).find('span').removeClass('icon-comment-alt').addClass('icon-comment');

			var modal = new DisneyABC.newCommentDialog();

			var this_id = $(this).data('id');
			var comments = $('#new_comments_' + this_id).val();
			modal.setId(this_id);
			modal.setComment(comments);
			modal.show();
			return false;
		});

		// executes after you submit a comment
		page.newCommentsDialog.on('click', '#add_new_comments', function() {
			var $newNoteContent = $('#new_comments_dialog');

			var this_id = $newNoteContent.find('#new_id').val();
			var comments = $newNoteContent.find('#new_comments').val();

			$('#new_comments_' + this_id).val(comments);
			$newNoteContent.find('#new_comments').val('');

			page.newCommentsDialog.modal('hide');
			return false;
		});

		// Remove title from a list when adding a product > step 5
		page.titlesContainer2Content.on('click', '.remove_title', function(e) {
			e.preventDefault();
			var $row = $(this).parents('tr');
			$row.addClass('deleting');
			$row.find('inputs').attr('disabled', true);

			if (confirm('Are you sure you want to delete ' + quoteText($row.find('.title-name:first').text()) + ' ?')) {
				var clearance_id = $(this).data('clearance_id');
				var product_id = $(this).data('product_id');

				//ajax delete if this product already exists
				//otherwise it hasn't been saved yet & removing it from the form will suffice
				if (product_id && clearance_id) {
					$.ajax({
						url: '/clearances/' + clearance_id,
						type: 'DELETE',
						dataType: 'json'
					}).done(function(data) {
						if (data == 'false' || data.status == 'error') {
							deleteFailure($row);
						} else {
							page.dataTable.fnDeleteRow($row);
							deleteSuccessful($row);
						}
					});
				} else {
					page.dataTable.fnDeleteRow($row);
					deleteSuccessful($row);
				}
			} else {
				$row.removeClass('deleting');
				$row.find('inputs').attr('disabled', false);
			}
		});

		// append new table row to window - a new preemption or new run
		page.newRunDialog.on('submit', '#new_run_form', function(e) {
			e.preventDefault();
			if (requestCopy && requestCopy.readyState != 4) {
				requestCopy.abort();
			}
			var product_id = $(this).data('product_id');
			var urlString = '/admin/product';
			var data = $(this).serialize();

			if (product_id) {
				data = data + '&product_id=' + product_id;
			}
			data = data + '&count=' + new_run_count;

			if ($(this).data('form_id')) {
				data = data + '&form_id=' + $(this).data('form_id');
			}

			requestCopy = $.ajax({
				url: urlString,
				data: data,
				type: 'PUT',
				dataType: 'json',
				success: function(data) {
					if (data.status === 'success') {
						addRow(data.row);
					} else {
						displayFailureMessage(data);
					}
				},
				fail: function(jqXHR, data) {
					displayFailureMessage(data);
				}
			});
			new_run_count += 1;
		});
	};

	return {
		/**
         * initialize the object
         */
		init: function() {
			_initPageObjects();
			_initHandlers();
			_initPage();
		}
	};
})(jQuery);

$(document).ready(function() {
	DisneyABC.addProduct.init();

	// datetime picker is a wrapping div, we have to propagate the
	// change down to make it happen on the inputs
	$('.datetimepicker').on('dp.change', function(ev) {
		$(this).find(':input').change();
	});
});

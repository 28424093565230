/**
 * add ".check-all" classname to element.
 * toggles all checkboxs in the same fieldset
 */

$('div').on('click', '.check-all', function () {
	var checked = this.checked;
	if(typeof checked === 'undefined'){
		checked = $(this).find('input:checkbox').is(':checked');
	}
	$(this).closest('fieldset').find('input:checkbox:not(:disabled)').not('[value="all"]').prop('checked', checked);
});


$( ".checkable-list input[name='All']" ).on('click', function () {
	$(this).closest('fieldset').find('input:checkbox:not(:disabled)').prop('checked', this.checked);
});

$(function(){
    $("#emptyOrg[type='checkbox']").change(function(){
        var item=$(this);
        if(item.is(":checked"))
        {
            window.location.href= item.data("target");
        } else {
            window.location.href= item.data("target-off");
        }
    });
});
// this is used on the shopping cart page
// checks all checkboxes in the table
$('.check-all-tb').click(function() {
	var checked = this.checked;
	$(this).closest('table').find('tr').each(function() {
		if (!$(this).hasClass('hidden')) {
			var checkbox = $(this).find('input:checkbox');
			if (checked) { checkbox[0].checked = true; }
			else		 { checkbox[0].checked = false; }
		}
	})
}).hover(function() {

});

/*
make certain variables "constant", aka unchangeable
bonus: similar to how we do this in Laravel
usage: Config.get('doa_id')
technique from: http://stackoverflow.com/questions/130396/are-there-constants-in-javascript

var Config = {};
Config.get = function(name){
	var constants = {
		 'product_feature_id':  1, //product types
		 'product_doa_id':      8,
		 'doa_id':              2, //business line
		 'title_preemption_id': 1, //title types
		 'airdate_type_prerelease_id': 1,
		 'airdate_type_simulcast_id': 2,
		 'airdate_type_postrelease_id': 3
	 };
	 return constants[name];
}
*/

/**
 * Check if something is empty
 * @link http://phpjs.org/functions/empty/
 * @param mixed_var
 * @returns {boolean}
 */
var seleniumTest = false;

function empty(mixed_var) {
	'use strict';
	var undef, key, i, len;
	var emptyValues = [undef, null, false, 0, '', '0'];

	for (i = 0, len = emptyValues.length; i < len; i++) {
		if (mixed_var === emptyValues[i]) {
			return true;
		}
	}

	if (typeof mixed_var === 'object') {
		for (key in mixed_var) {
			// TODO: should we check for own properties only?
			//if (mixed_var.hasOwnProperty(key)) {
			return false;
			//}
		}
		return true;
	}

	return false;
}

/********************** Fallbacks ****************************/
// avoid errors in browsers without a console (ie8)
if (!window.console) {
	window.console = {
		log: function() {}
	};
}

// polyfill for html5 form target
function testInputFormSupport() {
	var input = document.createElement('input'),
		form = document.createElement('form'),
		formId = 'test-input-form-reference-support';
	// Id of the remote form
	form.id = formId;
	// Add form and input to DOM
	document.body.appendChild(form);
	document.body.appendChild(input);
	// Add reference of form to input
	input.setAttribute('form', formId);
	// Check if reference exists
	var res = !(input.form == null);
	// Remove elements
	document.body.removeChild(form);
	document.body.removeChild(input);
	return res;
}
if (!testInputFormSupport()) {
	$('input[type="submit"][form]').on('click', function(e) {
		var targetForm = $('#' + e.getAttribue('form'));
		e.preventDefault();
		targetForm.submit();
	})
}


/* file structure based on: https://github.com/tylersavery/jQueryTO2013/tree/master/code/BasicApp.js */
var product_feature_id = 10; // product types
var product_package_of_features_id = 1;
var product_doa_id = 8;
var doa_id = 2; // business line
var syndication_id = 1; // business line
var title_preemption_id = 1; // title types
var trailer_link_id = 36;
var product_doa_id = 8;
var circle_type_org_id = 2;
var title_type_episode_id = 3;

var video_trailer_link_id = 36;

var video_link_ids = ["36", "74"];

DisneyABC.Init = function() {
	//alert("Init");
	ConfigInit();
	DisneyABC.Cache();

	DisneyABC.SetupPlugins('html'); // moved out of DisneyABC.Setup until the .change onload and video popovers both work in harmony

	DisneyABC.BindListeners(); // switch order of BindListeners and Setup until the .change onload and video popovers both work in harmony
	DisneyABC.Setup();
	// DisneyABC.InitVideo();

	// Routes
	DisneyABC.baseUrl = $('body').attr('data-baseurl');

	// Account Active Users
	DisneyABC.suspend = DisneyABC.baseUrl + '/account/suspend';
	DisneyABC.send_pass = DisneyABC.baseUrl + '/account/password-reset';
	DisneyABC.user_activity = DisneyABC.baseUrl + '/account/user-activity';

	// Account Inactive Users
	DisneyABC.reactivate = DisneyABC.baseUrl + '/account/reactivate';

	// Account Invitation
	DisneyABC.add_invitation = DisneyABC.baseUrl + "/account/invitation";
	DisneyABC.resend_invitation = DisneyABC.baseUrl + "/account/invitation";
	DisneyABC.revoke_invitation = DisneyABC.baseUrl + "/account/invitation";

	DisneyABC.get_title_list = DisneyABC.baseUrl + "/title/list";
	DisneyABC.get_program_list = DisneyABC.baseUrl + "/program/list";

	DisneyABC.add_titles = DisneyABC.baseUrl + "/admin/add-product";

	//DisneyABC.BindListeners();
};

if (!Function.prototype.bind) {
	Function.prototype.bind = function(oThis) {
		if (typeof this !== "function") {
			// closest thing possible to the ECMAScript 5
			// internal IsCallable function
			throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
		}

		var aArgs = Array.prototype.slice.call(arguments, 1),
			fToBind = this,
			fNOP = function() {},
			fBound = function() {
				return fToBind.apply(this instanceof fNOP && oThis ? this : oThis,
					aArgs.concat(Array.prototype.slice.call(arguments)));
			};

		fNOP.prototype = this.prototype;
		fBound.prototype = new fNOP();

		return fBound;
	};
}

DisneyABC.Setup = function() {

	// Titles
	if ($('#title_admin_page').length > 0 || $('#title_admin_add_clearance_page').length > 0) {
		//hide
		var title_type = $('.select').filter('[name="title[title_type_id]"]').find('option:selected').text();

		if (typeof title_type != undefined && title_type != '') {
			/* !='' prevents it from hiding Episode Details on title pages such as /admin/title/10 */
			$('.type-group').filter(':not(".group-' + title_type + '")').hide();
		}
		if ($('select.business-line').length) { // on 'shown' doesn't seem to work anymore
			$('select.business-line').change();
		}
	}

	if ($('#add_product_admin_pagpe').length > 0) {
		$('select.product').change();
	}

	if ($('#addproduct_form').length > 0) {
		$('select#new_business_line_id').change();
	}

	if ($('#login_form #username').length > 0) {
		$('#login_form #username').blur();
	}
	/*	if($('#add_product_admin_pagpe')){
			$('select.product').change();
		}*/

	if ($('#satellite_admin_page').length > 0) {
		$('select#business_line_id').change();
		$('#satellite_admin_page #refeed_for').change();
	}

	if ($('#add-doc-files').length || $('#edit-doc-files').length || $("#asset_upload_video").length) {
		$('select#file_subtype_id').change();
	}
	if ($("#add-doc-files").length > 0) {
		$('#file_association').change();
	}

	if ($('#satellite_form #clearance_id').length > 0) {
		$('#satellite_form #clearance_id').change();
	}
};

/**
 * Convert a filesize to a human readable filesize
 * @param filesize {integer}
 * @returns {string}
 * @constructor
 */
DisneyABC.ReadableFilesize = function(filesize) {
    var suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var powNum = Math.max(Math.min(Math.floor(Math.log(filesize) / Math.log(1024)), suffixes.length), 0);
    return (Math.round((filesize / Math.pow(1024, powNum)) * 10) / 10.0).toString() + " " + suffixes[powNum];
};

DisneyABC.SetupPlugins = function(scope) {
	$(scope).find('select').not('.simple_select').select2();

	$(scope).find('.tool-tip').tooltip({
		placement: 'top'
	});

	DisneyABC.addCircles.addMultipleCircles(scope);
	DisneyABC.addCircles.addCircle(scope);
	
	formatDateTimeController.adjustFormatDate(scope);
	formatDateTimeController.adjustFormatTime(scope);
	$(scope).find('select').not('.simple_select').attr('title','');
}

DisneyABC.Cache = function() {
	//alert("Cache");
	//init DOM object
	DisneyABC.dom = {};

	DisneyABC.dom.btn_suspend = $(".btn_suspend");

	DisneyABC.dom.accord_history = $(".openhistoryBtn");

	DisneyABC.dom.btn_reactivate_user = $(".btn_reactivate_user");
	DisneyABC.dom.btn_add_invite = $("#add_invitation");
	DisneyABC.dom.btn_resend_invite = $(".resend_invitation");
	DisneyABC.dom.btn_revoke_invite = $(".revoke_invitation");
	DisneyABC.dom.btn_save_clearance = $(".btn.save_clearance");
	DisneyABC.dom.btn_delete_clearance = $(".btn.delete_clearance");

	DisneyABC.dom.div_admin_clearance = $(".edit_clearances, .new_clearance, .add_clearance")
	DisneyABC.dom.business_line_dropdown = $('select.business-line');
	DisneyABC.dom.clearance_nav_tab_links = $('.nav-tabs a');
	DisneyABC.dom.product_type_dropdown = $('#addproduct_form .product_type');
	DisneyABC.dom.circle_type_dropdown = $('#circle_type');

	DisneyABC.dom.snippet_table = $("table#snippets_list");
	DisneyABC.dom.btn_delete_snippet = $(".btn.delete_snippet");

	// Title type dropdown
	DisneyABC.dom.title_type = $("#title_type");

	// Title type dropdown in title search
	DisneyABC.dom.title_type_id = $(".search-title #title_type_id");
	DisneyABC.dom.title_program_group = $('.search-title .program_dropdown');
	DisneyABC.dom.title_program_dropdown = $('.search-title .program_dropdown input');
	DisneyABC.dom.title_season_group = $('.search-title .season_dropdown');
	DisneyABC.dom.title_season_dropdown = $('.search-title .season_dropdown select');
	DisneyABC.dom.title_episode_filters = $('.search-title .episode_filters');
	DisneyABC.dom.title_nonepisode_filters = $('.search-title .non-episode_filters');

	// Alert Close Buttons
	DisneyABC.dom.alert_dismiss = $("#messagearea .close");
	// Alert Close Buttons
	DisneyABC.dom.notification_dismiss = $("#header_notifications .close");

	// Inline Tab
	DisneyABC.dom.inline_tab = $(".inline-tabs li a");
	DisneyABC.dom.satDate = $('#satdate');

	//title list datepickers
	DisneyABC.dom.getTitleBtn = $('#getTitleBtn');
	DisneyABC.dom.getBrowseBtn = $('#getBrowseBtn');

	DisneyABC.dom.startDate = $('#startdate');
	DisneyABC.dom.endDate = $('#enddate');

	//Your Order Page
	DisneyABC.dom.overdue = $('#overdue');

	//revisions resetbutton
	DisneyABC.dom.resetbutton = $('.revisionsfilter .reset');


};

/* These need to be loaded per request to reduce page load issues */
// *note: this also has a datepicker but it conflicts with the one below

/*	$("body").on("mouseover", ".add-on", function() {
		$(this).parent().datetimepicker({
			format: 'hh:mm',
			maskInput: true,
			pickSeconds: false,
			pickDate: false
		  });
		$('.bootstrap-datetimepicker-widget').hide();
	 });

	$("body").on("mouseover", ".datepicker", function() {
		$(this).datepicker({
			//showOn: 'both',
			//buttonImage: '/img/calendar.png',
			changeYear: true,
			showButtonPanel: true,
			minDate: 0, //today - added this for "add file, alert exipres_at"
			maxDate: "+1Y"
		});
	});

	//jQuery Calendar - allow past dates for bulk titles
	$("body").on("mouseover", ".datepicker-full", function() {
		$(this).datepicker({
			changeYear: true,
			showButtonPanel: true
			//showOn: 'both',
			//buttonImage: '/img/calendar.png'
		});
	});*/


String.prototype.replaceAll = function(str1, str2, ignore) {
	return this.replace(new RegExp(str1.replace(/([\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, function(c) {
		return "\\" + c;
	}), "g" + (ignore ? "i" : "")), str2);
};

if (typeof String.prototype.trim !== 'function') {
	String.prototype.trim = function() {
		return this.replace(/^\s+|\s+$/g, '');
	}
}

// Add ECMA262-5 Array methods if not supported natively
//
if (!('indexOf' in Array.prototype)) {
	Array.prototype.indexOf = function(find, i /*opt*/ ) {
		if (i === undefined) i = 0;
		if (i < 0) i += this.length;
		if (i < 0) i = 0;
		for (var n = this.length; i < n; i++)
			if (i in this && this[i] === find)
				return i;
		return -1;
	};
}



DisneyABC.BindListeners = function() {

	DisneyABC.dom.resetbutton.click(function(e) {
		$('#filter-user').select2("val", '0');
		$('#filter-product').val('');
		$('#filter-title').val('');
		$('#filter-action').select2("val", '0');
		$('#filter-reviseable_type').select2("val", '0');
		//$('#filter-start_date').val('');
		//$('#filter-end_date').val('');

	});

	/**
	 * Convert the provided date from mm/dd/yyyy format to yyyy-mm-dd format.
	 * @param slashyDate
	 * @returns {string}
	 */
	function convertSlashDateToDashDate(slashDate) {
		var parts = slashDate.split('/');
		var month = parts[0];
		var day = parts[1];
		var year = parts[2];
		return year + '-' + month + '-' + day;
	}

	DisneyABC.dom.satDate.change(function(e) {

		var selectedDate = $(this).val().replaceAll('/', '-');

		var parts = selectedDate.split('-'); // gives you: mm-dd-yyyy
		var month = parts[0];
		var day = parts[1];
		var year = parts[2];

		var target_date = year + '-' + month + '-' + day; // gives you yyyy-mm-dd
		window.location = DisneyABC.baseUrl + "/satellite/" + target_date + "/" + $(this).attr('sat_id');

	});


	DisneyABC.dom.inline_tab.click(function(e) {
		e.preventDefault();
		$(this).tab('show');
	});

	$('button').click(function(e) {
		var href = $(this).attr('href');
		if (typeof href !== typeof undefined && href !== false) {
			window.location.href = $(this).attr('href');
		}
	});

	$('.toggle-selector').on('click', '.btn', function(e) {
		$(this).closest('.toggle-selector').attr('data-toggle-state', $(this).attr('data-toggle'));
	});

	$('[data-js-event="update-importer-state"]').click(function(e) {
		if ($(this).attr('disabled')) {
			return false;
		}
		var metadata = $(this).closest('[data-toggle-state]');
		if (metadata.attr('data-toggle-state') == $(this).data('toggle')) {
			return true;
		}

		var enabled = $(this).data('toggle') == 'on' ? 1 : 0;
		var id = metadata.data('importer-id');
		var urlString = '/importer/toggle_enabled/' + id + '/' + enabled;

		$(this).siblings('.btn').attr('disabled', 'true');
		$(this).siblings('.loading-spinner').addClass('show');

		var request = $.ajax({ // ajax call
			url: urlString,
			type: 'POST', // type POST
			dataType: 'html'
		});

		var button = $(this);
		request.done(function() {
			$(button).siblings('.btn').removeAttr('disabled');
			$(button).siblings('.loading-spinner').removeClass('show');
		});
	});

	/*

	**********************
	ACTIVE USER PAGE
	**********************

	*/

	/**
	 * Suspend User.
	 *
	 * function used on the edit profile > active users page.
	 * Gets user id from the data-userid attribute of the button.
	 * Posts ajax call to API.
	 * If success remove user row from screen.
	 * If fail create error.
	 */
	DisneyABC.dom.btn_suspend.click(function() {
		var urlString = DisneyABC.suspend; // ajax url
		var userId = $(this).data('userid'); // get user id from button attrib
		var request = $.ajax({ // ajax call
			url: urlString,
			data: {
				'user_id': userId,
				'delete': '1'
			},
			type: 'POST', // type POST
			dataType: 'html'
		});

		// when request is complete
		request.done(function(data) {
			data = $.parseJSON(data);
			// create new message
			var message = new Message("User has been suspended.");
			message.type = data.status; // message type
			message.title = data.status; // message status

			// Success
			if (data.status.toLowerCase() === 'success') {
				message.showMessage(); // show message
				$('#active' + userId).next().remove(); // remove history row
				$('#active' + userId).remove(); // remove user row
			}
		});

		// Fail
		request.fail(function(jqXHR, data) {
			var response = $.parseJSON(jqXHR.responseText);
			var message = new Message(response.error.message);
			message.showMessage();
		});
	});


	/**
	 * Send Password.
	 *
	 * function used on the edit profile > active users page.
	 * Gets user id from the data-userid attribute of the button.
	 * Posts ajax call to API.
	 * If success send email to user
	 * (Do not forget to tuen on the beanstalkd service).
	 * If fail create error.
	 */
	$("a.btn_send_pass").each(function() {
        $(this).click(function() {
		    var urlString = DisneyABC.send_pass; // ajax url
		    var userId = $(this).data('userid'); // get user id from button attrib
		    var request = $.ajax({ // ajax call
			    url: urlString,
			    data: {
			        'user_id': userId
			    },
			    type: 'POST', // type POST
			    dataType: 'html'
		    });

		    // When request is complete
		    request.done(function(data) {
			    data = $.parseJSON(data);

			    // create new message
			    var message = new Message(data.message);
			    message.type = data.status; // message type
			    message.title = data.status; // message status
			    message.showMessage(); // show message
		    });

			// Fail
			request.fail(function(jqXHR, textStatus) {
				var response = $.parseJSON(jqXHR.responseText);
				var message = new Message(response.error.message);
				message.showMessage();
			});
		});
	});

	/*

	**********************
	INACTIVE USER PAGE
	**********************

	*/

	/**
	 * Reactivate User.
	 *
	 * function used on the edit profile > inactive users page.
	 * Gets user id from the data-userid attribute of the button.
	 * Posts ajax call to API.
	 * If success reactivates user account and removes the row from screen.
	 * If fail create error.
	 */

	DisneyABC.dom.btn_reactivate_user.click(function() {
		var urlString = DisneyABC.reactivate; // ajax url
		var userId = $(this).data('userid'); // get user id from accordian control

		var request = $.ajax({ // ajax call
			url: urlString,
			data: {
				'user_id': userId,
				'deleted': '0'
			},
			type: 'POST', // type POST
			dataType: 'html'
		});

		// when request is complete
		request.done(function(data) {
			data = $.parseJSON(data);
			var message = new Message(data.message);
			message.type = data.status; // message type
			message.title = data.status; // message status
			message.showMessage(); // show message to user

			// Success
			if (data.status.toLowerCase() === 'success') {
				//Remove invited user
				$('#inactive' + userId).next().remove(); // remove user history
				$('#inactive' + userId).remove(); // remove user row
				$('#active tr:last').after(data.view);
			}
		});

		// Fail
		request.fail(function(jqXHR, textStatus) {
			var response = $.parseJSON(jqXHR.responseText);
			message.title = "Request failed:";
			message.showMessage();

		});
	});


	/*

	**********************
	USER HISTORY FOR ACTIVE & INACTIVE USER PAGE
	**********************

	*/
	/**
	 * User Activity (History).
	 *
	 * function used on the edit profile > active users page.
	 * Gets user id from the data-userid attribute of the accordian control.
	 * add loading message to screen
	 * Get ajax call to API.
	 * If success update user interface
	 * If fail create error.
	 */
	DisneyABC.dom.accord_history.click(function() {
		var urlString = DisneyABC.user_activity; // ajax url
		var userId = $(this).data('userid'); // get user id from accordian control
		$('#modalwindow').modal('show');

		objId = '#history_content'; // get content container
		$(objId).html('Loading ...'); // add loading message

		var request = $.ajax({ // ajax call
			url: urlString,
			data: {
				'user_id': userId
			},
			type: 'GET', // type GET
			dataType: 'html'
		});

		// when request is complete
		request.done(function(data) {

			data = $.parseJSON(data);
			// Success
			if (data.status.toLowerCase() === 'success') {
				$(objId).html(data.html); // update user interface with history
			} else {
				$(objId).html('No history found'); // if no results add no results message
			}
		});
	});

	/*
	**********************
	ADD PRODUCT
	**********************
	*/
	DisneyABC.dom.business_line_dropdown.on('change', function() {

		$('#add_product_admin_page #new_product_type_id').select2('data', {
			id: '',
			text: ''
		}); /* THIS IS HOW TO RESET A Select2 */

	});


	DisneyABC.dom.circle_type_dropdown.on('change', function() {
		var val = $(this).val();
		fulltable = $('.fulltable').dataTable();
		fulltable.fnFilter(val, 0, true);
		if (val != circle_type_org_id) {
			$("#permission_org_circle").hide();
		} else {
			$("#permission_org_circle").show();
		}
	}).change();

	/**
	 * Used to advance the date
	 * @param  {string} source_begin    source input containing begin date in the format MM/DD/YYYY
	 * @param  {string} source_end      source input containing end date in the format MM/DD/YYYY
	 * @param  {int} offset_days        usually 1 or 7 days
	 * @param  {object} target_begin    target input to update, can be anywhere in the dom
	 * @param  {object} target_end      target input to update, can be anywhere in the dom
	 */
	function copy_date_plus_offset(source_begin, source_end, offset_days, target_begin, target_end) {

		if (typeof(source_begin.val()) != 'undefined' && source_begin.val()) {
			source_begin = source_begin.val().split('/');
			var begin_date = new Date(source_begin[2], source_begin[0] - 1, source_begin[1]);
		} else {
			var begin_date = '';
		}

		begin_date.setDate(begin_date.getDate() + offset_days);

		if (typeof(source_end.val()) != 'undefined' && source_end.val()) {
			source_end = source_end.val().split('/');
			var end_date = new Date(source_end[2], source_end[0] - 1, source_end[1]);
		} else {
			var end_date = '';
		}

		if (begin_date && end_date) {
			end_date.setDate(end_date.getDate() + offset_days);

			var calculated_begin = ('0' + (begin_date.getMonth() + 1)).slice(-2) + '/' + ('0' + (begin_date.getDate())).slice(-2) + '/' + begin_date.getFullYear();
			var calculated_end = ('0' + (end_date.getMonth() + 1)).slice(-2) + '/' + ('0' + (end_date.getDate())).slice(-2) + '/' + end_date.getFullYear();

			target_begin.val(calculated_begin);
			target_end.val(calculated_end);
		}
	}

	$("body").on("click", ".add_product_week_previous", function() { // add a week from previous entry

		var source_begin = $(this).closest('tr').prev().find('.begin_at');
		var source_end = $(this).closest('tr').prev().find('.end_at');

		var target_begin = $(this).closest('tr').find('.begin_at');
		var target_end = $(this).closest('tr').find('.end_at');

		copy_date_plus_offset(source_begin, source_end, 7, target_begin, target_end);
		return false;
	});
	$("body").on("click", ".add_product_day_previous", function() { // add a day from previous entry

		var source_begin = $(this).closest('tr').prev().find('.begin_at');
		var source_end = $(this).closest('tr').prev().find('.end_at');

		var target_begin = $(this).closest('tr').find('.begin_at');
		var target_end = $(this).closest('tr').find('.end_at');

		copy_date_plus_offset(source_begin, source_end, 1, target_begin, target_end);
		return false;
	});

	$("body").on("click", ".add_product_week", function() { // add a week on current entry

		var source_begin = $(this).closest('tr').find('.begin_at');
		var source_end = $(this).closest('tr').find('.end_at');

		var target_begin = source_begin;
		var target_end = source_end;

		copy_date_plus_offset(source_begin, source_end, 7, target_begin, target_end);
		return false;
	});
	$("body").on("click", ".add_product_day", function() { // add a day on current entry

		var source_begin = $(this).closest('tr').find('.begin_at');
		var source_end = $(this).closest('tr').find('.end_at');

		var target_begin = source_begin;
		var target_end = source_end;

		copy_date_plus_offset(source_begin, source_end, 1, target_begin, target_end);
		return false;
	});

	$("body").on("click", ".add_product_copy_runtime", function() { // copy current runtime to all rows

		var source_runtime = $(this).closest('tr').find('.runtime');
		if (typeof(source_runtime.val()) != 'undefined') {

			$('.runtime').each(function(index, obj) {
				$(obj).val(source_runtime.val());
			});
		}
		return false;
	});
	$("body").on("change", "#toggle_hide", function() { // toggle hide all / unhide all
		if ($(this).is(':checked')) {
			$('.toggle_hide').prop('checked', true);
		} else {
			$('.toggle_hide').prop('checked', false);
		}
		return true;
	});


	// check days on bulk titles form and satellite schedule
	$("body").on('change', '#weekend', function() {
		if ($(this).is(':checked')) {
			$('#sat').prop('checked', true);
			$('#sun').prop('checked', true);
		} else {
			$('#sat').prop('checked', false);
			$('#sun').prop('checked', false);
		}
	});
	$("body").on('change', '#weekday', function() {
		if ($(this).is(':checked')) {
			$('#mon').prop('checked', true);
			$('#tues').prop('checked', true);
			$('#wed').prop('checked', true);
			$('#thurs').prop('checked', true);
			$('#fri').prop('checked', true);
		} else {
			$('#mon').prop('checked', false);
			$('#tues').prop('checked', false);
			$('#wed').prop('checked', false);
			$('#thurs').prop('checked', false);
			$('#fri').prop('checked', false);
		}
	});
	/**/

	// tool tips
	$('.tool-tip').tooltip({
		placement: 'top'
	});

	/*
	**********************
	SATELLITE SCHEDULE
	**********************
	*/

	//@TODO - *REFACTOR* TO NOT WATCH BODY!!!
	// disable or enable the 'add alert' form on title satellite transmission forms
	$('body').on('change', '#satellite_form #clearance_id', function() {
		if ($('#satellite_form #clearance_id').val() == '0') {
			$('.satellite_clearance_alert input').attr('disabled', 'disabled');
			$('.satellite_clearance_alert textarea').attr('disabled', 'disabled');
		} else {
			$('.satellite_clearance_alert input').removeAttr('disabled');
			$('.satellite_clearance_alert textarea').removeAttr('disabled');
		}
		return false;
	});

	var request; // global object

	function refresh_satellite_form() {

		// issue where these take a long time to complete and many can be running in the background at one time
		// remove previous requests
		if (request && request.readyState != 4) {
			request.abort();
		}

		var urlString = '/satellite/product';
		var data = $('#satellite_form').serialize();

		request = $.ajax({
			url: urlString,
			data: data,
			type: 'PUT',
			dataType: 'html',
			beforeSend: function() {
				var loadingMessage = '<div style="margin-top:1em;"><h4>Loading form...</h4></div>'
				$('#satellite_step3').html(loadingMessage);
			}
		});

		// when request is complete
		request.done(function(data) {
			data = $.parseJSON(data);
			// create new message

			var message = new Message(data.message);
			message.type = data.status; // message type
			message.title = data.status; // message status
			//message.showMessage();

			// Success
			if (data.status === 'success') {
				$('#satellite_step3').html(data.view).promise().done(function() {
					DisneyABC.EventManager.publish('loadTimePicker');
				});
				DisneyABC.SetupPlugins('#satellite_admin_page');
				$('#satellite_form #clearance_id').change(); // disable 'Add Alert' message area
				/*                      if($("#satellite_admin_page.product").length>0){
				 refresh_satellite_review();
				 }*/
				 $('#cancel-satellite-add-title').hide();
			}
		});

		// Fail
		request.fail(function(jqXHR, data) {
			var response = $.parseJSON(jqXHR.responseText);
			var message = new Message(response.error.message);
			message.showMessage();
		});


	}

	var requestReview; // global object

	function refresh_satellite_review() {
		if (requestReview && requestReview.readyState != 4) {
			requestReview.abort();
		}

		var urlString = '/satellite/product-review';
		//var data = $('#satellite_form').serialize()+"&"+$('#satellite_form2').serialize();
		var data = $('#satellite_form').serialize();

		// required to generate list
		var begin_at = $('#broadcast_first_at').val();
		var end_at = $('#broadcast_last_at').val();

		if (begin_at && end_at) {
			requestReview = $.ajax({
				url: urlString,
				data: data,
				type: 'PUT',
				dataType: 'html',
				beforeSend: function() {
					$('#satellite_review').html('Generating schedule, please wait...');
				},
				success: function() {
					// when request is complete
					requestReview.done(function(data) {
						data = $.parseJSON(data);

						//satellite_review-=1;

						// create new message
						var message = new Message(data.message);
						message.type = data.status; // message type
						message.title = data.status; // message status
						//message.showMessage();

						// Success
						if (data.status === 'success') {
							// only show the last request, don't load old requests as they finish
							// async false seems to lock up the page
							$('#satellite_review').html(data.view);
							DisneyABC.SetupPlugins('#satellite_admin_page');
						}
					});

					// Fail
					requestReview.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
					});
				}
			});
		}
		return false;

	}


	$("#satellite_admin_page #business_line_id").change(function() {
		var selection = $(this).val();
		$('#satellite_step2').show();
		$('#satellite_step2 #product_doa select').attr('disabled', 'disabled');
		$('#satellite_step2 #product_syndication select').attr('disabled', 'disabled');

		if (selection == doa_id) {
			$('#satellite_step2 #product_syndication').hide();
			$('#satellite_step2 #product_doa').show();
			$('#satellite_step2 #product_doa select').removeAttr('disabled');
		} else {
			$('#satellite_step2 #product_doa').hide();
			$('#satellite_step2 #product_syndication').show();
			$('#satellite_step2 #product_syndication select').removeAttr('disabled');
		}

		$('#satellite_step3').html(''); // clear
		$('#continue').show();
		//refresh_satellite_form();
		/*      if($("#satellite_admin_page.product").length>0){
					$('#satellite_review').html(''); // clear
				}*/
	});

	$("#satellite_admin_page .refresh_form").change(function() {
		$('#satellite_step3').html(''); // clear
		$('#continue').show();
	});
	$("body").on("click", "#satellite_admin_page .main_trigger", function() {
		$('#satellite_step3').html(''); // clear
		$('#continue').hide();
		if ($("#satellite_admin_page.product").length > 0) {
			$('#satellite_review').html(''); // clear
		}
		refresh_satellite_form();
		return false;
	});
	$("body").on("click", "#satellite_admin_page.product .trigger", function() {

		// required to submit
		var feed_offset = $('#begin_at_days_before_clearance').val();
		var transmit_time = $('#transmit_time').val();
		var duration = $('#content_minutes').val();
		var start = $('#broadcast_first_at').val();
		var end = $('#broadcast_last_at').val();
		var expire = $('#delete_unconfirmed_days_before').val();

		if (feed_offset && transmit_time && duration && start && end && expire) {
			$('#satellite_review').html(''); // clear
			refresh_satellite_review();
		} else {
			$('#satellite_review').html('Please fill in all required date/time fields.');
		}

		return false;
	});


	/**
	 * check the time slot and show if it is available or not
	 */
	var requestTransmit; // global object

	$("body").on('blur', "#satellite_admin_page.title .add-on.date_trigger, #satellite_admin_page.edit .add-on.date_trigger", function() {
		$("body #satellite_admin_page.title .date_trigger, body #satellite_admin_page.edit .date_trigger").change();

	});
	$("body").on("change", "#satellite_admin_page.title .date_trigger, #satellite_admin_page.edit .date_trigger", function() {
		if (requestTransmit && requestTransmit.readyState != 4) {
			requestTransmit.abort();
		}

		var tdate = $('#satellite_admin_page #broadcast_first_at').val();
		var ttime = $('#satellite_admin_page #transmit_time').val();
		var duration = $('#satellite_admin_page #content_minutes').val();

		if (tdate && ttime && duration) {
			// check if date is taken
			var urlString = '/satellite/timeslot-availability';
			var data = $('#satellite_form').serialize();

			requestTransmit = $.ajax({
				url: urlString,
				data: data,
				type: 'GET',
				dataType: 'html',
				beforeSend: function() {
					$('#satellite_transmit_check').html('');
				},
				success: function() {
					// when request is complete
					requestTransmit.done(function(data) {
						data = $.parseJSON(data);
						// create new message

						$('#satellite_transmit_check').html(data.view);

						var message = new Message(data.message);

						if (data.status === 'available') {
							message.type = 'success';
							message.title = 'success';
							//$('#transmission_schedule_save').removeAttr('disabled');
						} else {
							message.type = 'failure';
							message.title = 'failure';
							//$('#transmission_schedule_save').attr('disabled','disabled');
						}
					});

					// Fail
					requestTransmit.fail(function(jqXHR, data) {
						var response = $.parseJSON(jqXHR.responseText);
						var message = new Message(response.error.message);
						message.showMessage();
					});
				}
			});

		}
	});

	// VALIDATIONS IN SATELLITE FORM
	var validationFormSatellite = new FormsValidationGeneral({
		form: '#satellite_form'
	});

	validationFormSatellite.initValidate();

	// confirm a satellite transmission - popup a list of clearances related to the product
	var requestConfirm; // global object
	$(".satellite-confirm").click(function() {
		$('#satellite_transmission_id').val($(this).data('id'));
		$('#satellite_transmission_product_id').val($(this).data('product_id'));
		$('#refeed_type_id').select2('data', {
			id: $(this).data('refeed_id'),
			text: $('#refeed_type_id option[value=' + $(this).data('refeed_id') + ']').text()
		});

		var urlString = '/satellite/edit';
		var data = 'date=' + $('#satellite_transmission_date').val() + '&product_id=' + $(this).attr('data-product_id') + '&id=' + $(this).data('id');

		var requestConfirm = $.ajax({
			url: urlString,
			data: data,
			type: 'PUT',
			dataType: 'html',
			success: function() {
				// when request is complete
				requestConfirm.done(function(data) {
					data = $.parseJSON(data);
					// create new message

					var message = new Message(data.message);
					message.type = data.status; // message type
					message.title = data.status; // message status
					//message.showMessage();

					// Success
					if (data.status === 'success') {
						$('#satellite_clearance_list').html(data.view);
						$('#clearance_id option[value=' + data.clearance_id + ']').prop('selected', true);
						$('#satellite_confirm_dialog').modal('show');
					}
				});

				// Fail
				requestConfirm.fail(function(jqXHR, data) {
					var response = $.parseJSON(jqXHR.responseText);
					var message = new Message(response.error.message);
					message.showMessage();
				});
			}
		});

		return false;
	});
	// submit the confirmation popup
	$("#add_new_confirmation").click(function() {
		$(this).closest('form').submit();
		$('#satellite_confirm_dialog').modal('hide');
		return false;
	});
	// delete satellite transmissions for a product - popup a prompt to confirm deletion before removal
	$(".satellite-delete-product").click(function() {
		$('#satellite_form_delete_product #satellite_delete_product_id').val($(this).data('id'));
		$('#satellite_form_delete_product #satellite_delete_satellite_id').val($(this).data('satellite'));
		$('#satellite_form_delete_product #satellite_delete_title_type_id').val($(this).data('type'));
		$('#satellite_delete_dialog').modal('show');

		return false;
	});
	// submit product delete form and close modal
	$("#new_deletion").click(function() {
		$(this).closest('form').submit();
		$('#satellite_delete_dialog').modal('hide');
		return false;
	});
	// delete a satellite transmission - popup a prompt to confirm deletion before removal
	$(".satellite-delete").click(function() {
		$('#satellite_form_delete #satellite_delete_satellite_id').val($(this).data('id'));
		$('#satellite_form_delete').attr("action", "/satellite/delete/" + $(this).data('id'));
		$('#satellite_delete_single_dialog').modal('show');

		return false;
	});
	// submit product delete form and close modal
	$("#new_deletion_single").click(function() {
		$(this).closest('form').submit();
		$('#satellite_delete_single_dialog').modal('hide');
		return false;
	});

	// show/hide the refeed for select box on title form
	$("#satellite_admin_page").on('change', '#refeed_for', function() {
		if ($(this).is(':checked')) {
			DisneyABC.SetupPlugins('#satellite_admin_page');
			$('#add-multiple-circles').attr('disabled', false);
		} else {
			$('#add-multiple-circles').attr('disabled', true);
		}
		return true;
	});

	// CSV transmission range prompt
	$("body").on("click", "#csv_transmission_range", function() {
		$('#transmission_range_dialog').modal('show');
		return false;
	});

	$("body").on("click", "#generate_csv_range", function() {
		$(this).closest('form').submit();
		//$('#transmission_range_dialog').modal('hide');
		return false;
	});

	/* if 'reserve time before' is not check, disable 'time to reserve'
	$("body").on('change', '#use_pre_content', function() {
		if($(this).is(':checked')){
			$("#satellite_admin_page #pre_content_minutes").removeAttr('disabled');
		}else{
			$("#s2id_pre_content_minutes").find('span').html('0 minutes');
			$("#pre_content_minutes option:first").prop('selected','selected');
			$("#satellite_admin_page #pre_content_minutes").attr('disabled', 'disabled');
		}
	});
*/
	/* if 'reserve time after' is not check, disable 'time to reserve'
	$("body").on('change', '#use_post_content', function() {
		if($(this).is(':checked')){
			$("#satellite_admin_page #post_content_minutes").removeAttr('disabled');
		}else{
			$("#s2id_post_content_minutes").find('span').html('0 minutes');
			$("#post_content_minutes option:first").prop('selected','selected');
			$("#satellite_admin_page #post_content_minutes").attr('disabled', 'disabled');
		}
	});
 */
	/*  $("#satellite_admin_page #feed_offset").change(function() {
			var selection=$(this).val();
			if(selection){
				$('#satellite_step4').show();
			}else{
				$('#satellite_step4').hide();
			}
		}); */

	/*

	**********************
	NOTIFICATION MESSAGES CLOSE
	**********************

	*/
	/**
	 * Notification Close Function.
	 *
	 * function used to close the notification messages.
	 */
	DisneyABC.dom.notification_dismiss.click(function() {

		var request = $.ajax({
			url: DisneyABC.baseUrl + "/alerts/dismiss/" + $(this).attr('data-id'),
			data: {},
			type: 'POST', // type DELETE
			dataType: 'html'
		});


		//when request is complete
		request.done(function(data) {
			data = $.parseJSON(data);
			if (data.status.toLowerCase() === 'success') {

			} else {
				var message = new Message(data.message);
				message.type = data.status;
				message.title = data.status;
				message.showMessage();

			}


		});
		request.fail(function(jqXHR, data) {
			var message = new Message(data);
			message.title = 'Request failed';
			message.showMessage();
		});

		if (this) {
			$(this).closest('.notification').remove();
		}

	});


	/*

	**********************
	SNIPPETS PAGE
	**********************

	*/

	/**
	 * Delete Snippet.
	 *
	 * function used on the snippets indes page.
	 * Gets user id from the data-userid attribute of the button.
	 * Posts ajax call to API.
	 * If success remove user row from screen.
	 * If fail create error.
	 */
	DisneyABC.dom.snippet_table.on('click', 'td .delete_snippet', function() {
		if (confirm('Really delete this snippet?')) {
			var urlString = DisneyABC.baseUrl + '/admin/snippets/'; // ajax url
			var snippet_id = $(this).parents('tr').data('snippetid'); // get user id from button attrib
			var request = $.ajax({ // ajax call
				url: urlString,
				data: {
					'snippet_id': snippet_id
				},
				type: 'DELETE', // type POST
				dataType: 'html'
			});
			// when request is complete
			request.done(function(data) {
				data = $.parseJSON(data);
				var message = new Message(data.message);
				message.type = data.status;
				message.title = data.status;
				message.showMessage();

				if (data.status.toLowerCase() === 'success') {

					var message = new Message(data.message);

					$('#snippets_list tr.snippet-' + data.id).remove();
					if (!$('#snippets_list tr').length) {
						$('#snippets_list').replaceWith('<p>No snippets yet :(</p>')
					}
				}
			});

			request.fail(function(jqXHR, textStatus) {
				var message = new Message(textStatus);
				message.title = "Request failed";
				message.showMessage();
			});
		}
		return false;
	});

};


// methods
DisneyABC.SetSizeAndPositions = function() {};

DisneyABC.GoToPage = function(page) {};


$(document).ready(function() {

	DisneyABC.Init();
	if (typeof auto_show_modal !== "undefined") {
		if ($(auto_show_modal)) {
			$(auto_show_modal).modal("show")
		} else {

		}
	}

	if ($('.fulltable').dataTable()) {
		fulltable = $('.fulltable').dataTable();
		if ($('#circle_type option:selected').length) {
			var val = $('#circle_type option:selected').val()
			fulltable = $('.fulltable').dataTable();
			fulltable.fnFilter(val, 0, true);
		}
		/* fulltable.fnFilter( '3', 0, true); don't know why this was here, but it was breaking /browse/tv */
	}

	$('#subnavbar').affix({});

	// Used on: episode title template,
	// For tabbed sections
	$('.active .toggle').click();

	$('#notifications').on('click', '.expireNotification', function(ev) {

		if (confirm("Are you sure you want to expire this alert?")) {
			var spinner = $(this).next('.loading');
			spinner.show();

			var id = $(this).closest('tr').attr('data-notificationID');

			var expiredate = $(this).closest('tr').find('.enddate');
			expiredate.html($.datepicker.formatDate('mm/dd/yy', new Date()));

			$(this).hide();

			var urlString = DisneyABC.baseUrl + '/alerts/expire/' + id;

			var request = $.ajax({
				url: urlString,
				type: 'POST',
				dataType: 'html'
			});

			// when request is complete
			request.done(function(data) {
				data = $.parseJSON(data);
				spinner.hide(); // add loading message
				// create new message
				var message = new Message(" Alert has been expired.");
				message.type = 'info'; // message type
				message.title = 'Alert Expired'; // message status
				message.showMessage(); // show message

				if (data != false && data.success == true) {
					$('tr[data-notificationID=' + data.id + ']').addClass('expired');
				}
			});
			request.fail(function(data) {

			});

			ev.preventDefault();
			return false;

		} else {
			return false;
		}

	});

	$('#notifications').on('click', '.deleteNotification', function(ev) {

		if (confirm("Are you sure you want to delete this alert?")) {
			var spinner = $(this).next('.loading');
			spinner.show();
			var id = $(this).closest('tr').attr('data-notificationID');
			$(this).hide();
			$(this).closest('tr').find('.expireNotification').hide();

			var urlString = DisneyABC.baseUrl + '/alerts/delete/' + id;

			var request = $.ajax({
				url: urlString,
				type: 'POST',
				dataType: 'html'
			});

			// when request is complete
			request.done(function(data) {
				data = $.parseJSON(data);
				spinner.hide();

				// create new message
				var message = new Message(" Alert has been deleted.");
				message.type = 'info'; // message type
				message.title = 'Alert Deleted'; // message status
				message.showMessage(); // show message

				if (data != false && data.success == true) {
					$('tr[data-notificationID=' + data.id + ']').hide();
				}
			});
			request.fail(function(data) {

			});
			ev.preventDefault();
			return false;
		} else {
			return false;
		}

	});

	$('#notifications').on('click', '.getUserList', function(ev) {

		var spinner = $(this).next('.loading');
		spinner.show();

		var id = $(this).closest('tr').attr('data-notificationID');
		$(this).hide();

		var urlString = DisneyABC.baseUrl + '/alerts/users-for-notification/' + id;

		var request = $.ajax({
			url: urlString,
			type: 'GET',
			dataType: 'html'
		});

		var td = $(this).closest('td');

		// when request is complete
		request.done(function(data) {
			try {
				data = $.parseJSON(data);
				if (data != false && data.success == true) {
					//$('tr[data-notificationID=' + data.id + ']').hide();
					td.append(data.users);
				} else {
					throw "invalid data";
				}
			} catch (err) {
				td.append('<span style="color:red;">An&nbsp;Error&nbsp;Occured</span>');
			}

		});
		request.fail(function(data) {
			td.append('<span style="color:red;">An&nbsp;Error&nbsp;Occured</span>');
		});

		ev.preventDefault();
		spinner.hide();

		return false;
	});

	function episodes_from_season_dropdown_ajax(season_id) {
		var request = $.ajax({
			url: DisneyABC.baseUrl + '/get_episode_from_season_select',
			data: {
				'filter': season_id
			},
			type: 'GET',
			dataType: 'html'
		});
		return request;
	}

	$('#no_product_title_form #new_title_type').on('change', function() {
		if ($("#new_title_type").val() == title_type_episode_id) {
			$("#episode_fields").show();
			$("#episode_fields input").removeAttr('disabled');
			$("#episode_fields select").removeAttr('disabled');
			$("#feature_fields").hide();
			$("#feature_fields input").attr('disabled', true);
			$("#feature_fields select").attr('disabled', true);
		} else {
			$("#episode_fields").hide();
			$("#episode_fields input").attr('disabled', true);
			$("#episode_fields select").attr('disabled', true);
			$("#feature_fields").show();
			$("#feature_fields input").removeAttr('disabled');
			$("#feature_fields select").removeAttr('disabled');
		}
	});



	var requestSeasons;
	$('#no_product_title_form #program_id').on('change', function() {

		if (requestSeasons && requestSeasons.readyState != 4) {
			requestSeasons.abort();
		}
		var program_id = $(this).val();
		var urlString = '/get_season_select?filter=' + program_id;
		$("body").css("cursor", "wait");
		requestSeasons = $.ajax({
			url: urlString,
			type: 'GET',
			dataType: 'html',

			success: function() {
				// when request is complete
				requestSeasons.done(function(data) {
					data = $.parseJSON(data);

					if (data.status === 'success') {
						if (data.count > 0) {
							$('#season_id').parent().show();
						} else {
							$('#season_id').parent().hide();
						}
						$('#season_id').select2('data', {
							id: '',
							text: ''
						});
						$('#season_id').find('option').remove().end().append(data.html);
					} else {
						// error handling here
					}
				});

				// Fail
				requestSeasons.fail(function(jqXHR, data) {
					var response = $.parseJSON(jqXHR.responseText);
					var message = new Message(response.error.message);
					message.showMessage();
				});
				$("body").css("cursor", "default");

			}
		});

		return false;
	});

	if ($('#order_header_page').length > 0) {
		function rePopulateClientContactList() {
			$('.client_contact_list').hide();
			var vendor_id = $('#order_header_page #organization_name_id').val();
			$('#client_contact_list' + vendor_id).show();
			rePopulateClientForm();
		}

		function rePopulateClientForm() {
			var organization_id = $('#order_header_page #organization_name_id').val();
			var contact_id = $('#order_header_page #client_contact_id' + organization_id).val();

			var curr_person_data = contact_people_data[organization_id][contact_id];
			if (curr_person_data['email'] == undefined || curr_person_data['email'] == '') {
				$('#order_header_page #client_contact_email')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #client_contact_email')
					.attr('href', 'mailto:' + curr_person_data['email'])
					.removeClass('noLink')
					.text(curr_person_data['email']);
			}
			if (curr_person_data['phone_office'] == undefined || curr_person_data['phone_office'] == '') {
				$('#order_header_page #client_phone_office')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #client_phone_office')
					.attr('href', 'tel:' + curr_person_data['phone_office'])
					.removeClass('noLink')
					.text(curr_person_data['phone_office']);
			}
			if (curr_person_data['phone_mobile'] == undefined || curr_person_data['phone_mobile'] == '') {
				$('#order_header_page #client_phone_mobile')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #client_phone_mobile')
					.attr('href', 'tel:' + curr_person_data['phone_mobile'])
					.removeClass('noLink')
					.text(curr_person_data['phone_mobile']);
			}
			if (curr_person_data['address'] == undefined || curr_person_data['address'] == '') {
				$('#order_header_page #client_address')
					.addClass('disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #client_address')
					.html(curr_person_data['address']);
			}
		}

		function rePopulateVendorContactList() {
			$('.vendor_contact_list').hide();
			var vendor_id = $('#order_header_page #vendor_name_id').val();
			$('#vendor_contact_list' + vendor_id).show();
			rePopulateVendorForm();
		}

		function rePopulateVendorForm() {
			var vendor_id = $('#order_header_page #vendor_name_id').val();
			var contact_id = $('#order_header_page #vendor_contact_id' + vendor_id).val();
			var curr_person_data = vendor_people_data[vendor_id][contact_id];

			if (curr_person_data['email'] == undefined || curr_person_data['email'] == '') {
				$('#order_header_page #vendor_contact_email')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #vendor_contact_email')
					.attr('href', 'mailto:' + curr_person_data['email'])
					.removeClass('noLink')
					.text(curr_person_data['email']);
			}
			if (curr_person_data['phone_office'] == undefined || curr_person_data['phone_office'] == '') {
				$('#order_header_page #vendor_phone_office')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #vendor_phone_office')
					.attr('href', 'tel:' + curr_person_data['phone_office'])
					.removeClass('noLink')
					.text(curr_person_data['phone_office']);
			}
			if (curr_person_data['phone_mobile'] == undefined || curr_person_data['phone_mobile'] == '') {
				$('#order_header_page #vendor_phone_mobile')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #vendor_phone_mobile')
					.attr('href', 'tel:' + curr_person_data['phone_mobile'])
					.removeClass('noLink')
					.text(curr_person_data['phone_mobile']);
			}
		}

		function rePopulateDadtForm() {
			var curr_person_data = dadt_people_data[$('#order_header_page #dadt_contact_id').val()];
			if (curr_person_data['email'] == undefined || curr_person_data['email'] == '') {
				$('#order_header_page #dadt_contact_email')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #dadt_contact_email')
					.attr('href', 'mailto:' + curr_person_data['email'])
					.removeClass('noLink')
					.text(curr_person_data['email']);
			}
			if (curr_person_data['phone_office'] == undefined || curr_person_data['phone_office'] == '') {
				$('#order_header_page #dadt_phone_office')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #dadt_phone_office')
					.attr('href', 'tel:' + curr_person_data['phone_office'])
					.removeClass('noLink')
					.text(curr_person_data['phone_office']);
			}
			if (curr_person_data['phone_mobile'] == undefined || curr_person_data['phone_mobile'] == '') {
				$('#order_header_page #dadt_phone_mobile')
					.removeAttr('href').addClass('noLink disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #dadt_phone_mobile')
					.attr('href', 'tel:' + curr_person_data['phone_mobile'])
					.removeClass('noLink')
					.text(curr_person_data['phone_mobile']);
			}
			if (curr_person_data['address'] == undefined || curr_person_data['address'] == '') {
				$('#order_header_page #dadt_address :disabled')
					.html('<em>Data unavailable</em>');
			} else {
				$('#order_header_page #dadt_address')
					.html(curr_person_data['address']);
			}
		}
		rePopulateClientContactList();
		$('#order_header_page #organization_name_id').change(rePopulateClientContactList);
		rePopulateClientForm();
		$('#order_header_page .client_contact_list').change(rePopulateClientForm);
		rePopulateVendorContactList();
		$('#order_header_page #vendor_name_id').change(rePopulateVendorContactList);
		rePopulateVendorForm();
		$('#order_header_page .vendor_contact_list').change(rePopulateVendorForm);
		rePopulateDadtForm();
		$('#order_header_page #dadt_contact_id').change(rePopulateDadtForm);
	}

	if ($('#order_cart_form').length) {

		$('.show-additional-assets').click(function() {
			if ($(this).data().action == 'show-addtnl-assets') {
				$(this).nextAll('table:first').find('input:checkbox').not(':checked').closest('td').closest('tr').removeClass('hidden');
				$(this).data().action = 'hide-addtnl-assets';
				$(this).text('Hide Additional Assets');
			} else if ($(this).data().action == 'hide-addtnl-assets') {
				$(this).nextAll('table:first').find('input:checkbox').not(':checked').closest('td').closest('tr').addClass('hidden');
				$(this).data().action = 'show-addtnl-assets';
				$(this).text('Show Additional Assets');
			}
		});

		function UpdateSubmitButton() {
			if ($('input[data-selectcheckbox="true"]:checked').length == 0) {
				$('.order-submit-button').attr("disabled", '');
				$('.nothing-selected-error').show();
			} else {
				$('.order-submit-button').removeAttr("disabled");
				$('.nothing-selected-error').hide();
			}
		}
		$('input[data-selectcheckbox="true"]:checked').click(function() {
			UpdateSubmitButton();
		});
		UpdateSubmitButton();
	}

	if ($(".orderablefiles").length) {
		$(".orderablefiles .clear-filter").click(function() {
			$(this).prev("select").val("").change().select2("val", "")
		});
	}

	$('.expander, .expanderText').click(function() {
		$(this).parent().children('.expander').toggleClass('closed');
		var curr = $(this).closest('.parent').children('.children').slideToggle();
	});

	$(".deliver_date_datepicker").on("mouseover", function() {
		$(this).datepicker({
			//showOn: 'both',
			//buttonImage: '/img/calendar.png',
			minDate: "+3D", //today - added this for "add file, alert exipres_at"
			maxDate: "+1Y"
		});
	});



	//****ORDER PAGE**** /orders/show.blade.php//
	if ($('.container.workorder-view').length) {

		$('.comments').on('click', 'a.delete-comment', function() {

			commentId = $(this).closest('li').data('id');

			$('#asset-details :not(.loading)').remove();
			$('#asset-details .loading').show();
			var request = $.ajax({ // ajax call
				url: DisneyABC.baseUrl + '/orders/workflow-step-comment',
				data: {
					comment_id: commentId
				},
				type: 'DELETE', // type POST
				dataType: 'html'
			});

			// when request is complete
			request.done(function(data) {
				data = $.parseJSON(data);

				if (data.status.toLowerCase() === 'success') {
					$('#comment-' + data.id).remove();
					if (!$('.comment-list li').length) {
						$('div.comments').hide();
					}
				} else {
					var message = new Message("Unable to delete comment.");
					message.showMessage();
				}
			});

			// Fail
			request.fail(function(jqXHR, textStatus) {
				var response = $.parseJSON(jqXHR.responseText);

				message.title = "Request failed:";
				message.showMessage();

			});

			return false;
		});

		//filter vendor contacts based on selected vendor.
		$('#vendor_id').change(function(e) {
			var vendor_id = $(this).val();
			// fuck you select2
			$('#vendor_contact_id [value^="' + vendor_id + '|"]').show().removeAttr('disabled');
			$('#vendor_contact_id option').not('[value^="' + vendor_id + '|"]').hide().attr('disabled', 'true');
			$('#vendor_contact_id option:selected:disabled').parent().find('option').not(':disabled').first().attr('selected', 'true');
			$('#vendor_contact_id').select2();
		});

		$('#vendor_id').change();

		//client-side check for business_line assignment
		$('.advance-status').click(function(e) {
			var newStatus = $(this).val();

			$('#order_edit_form').submit(function(event) {
				//is this leaving review?
				if (newStatus == 3) {
					$('.assign-clearances').each(function() {
						if ($(this).find('input').length) {
							if (!$(this).find('input:checked').length) {
								var message = new Message('You must assign clearances for all files being ordered.');
								message.showMessage();
								event.preventDefault();
								return false;
							}
						}
					});
				}
				return true;
			});
		});

		//confirm order cancellation
		$('.order-cancel').click(function(e) {
			if (!confirm('Are you sure you want to cancel this order?')) {
				event.preventDefault();
			}
		})


	}

});

// admin/organizations/#/delivery_terms
$('body').on('click', '.btn_edit_delivery_term', function() {
	var selectedBtn = $(this);
	var showing = selectedBtn.closest('tr').next('tr');
	showing.toggle('show');
	if (!selectedBtn.hasClass('loaded') && !selectedBtn.hasClass('loading')) {
		selectedBtn.addClass('loading');
		var urlString = '/admin/edit-delivery-term-row/' + selectedBtn.data('id');
		var tr = $(selectedBtn).closest('tr');
		var editRow = $('.edit-delivery_term.delivery_term-' + selectedBtn.data('id') + ' td');

		editRow.html('<i class="glyphicon glyphicon-refresh glyphicon-spin" style="display: block; text-align: center;"></i>');
		requestTransmit = $.ajax({
			url: urlString,
			type: 'GET',
			dataType: 'html',
			success: function() {
				selectedBtn.removeClass('loading');
				selectedBtn.addClass('loaded');
				// when request is complete
				requestTransmit.done(function(data) {
					editRow.html(data);
					DisneyABC.SetupPlugins('.edit-delivery_term'); // activate select2

				});
			},
			fail: function(jqXHR, textStatus, error) {
				selectedBtn.removeClass('loading');
				editRow.html('<p style="text-align:center; color: red;">An error occured</p>');
				var message = new Message(error);
				message.showMessage();
			}
		});
	}
});
//Save edited delivery term
$('body').on('click', '.btn.save_delivery_term', function() {
	var urlString = DisneyABC.baseUrl + '/admin/delivery-term';
	var data = $(this).closest('form').serialize();

	var request = $.ajax({
		url: urlString,
		data: data,
		type: 'PUT',
		dataType: 'html'
	});

	// when request is complete
	request.done(function(data) {
		data = $.parseJSON(data);
		// create new message

		var message = new Message(data.message);
		message.type = data.status; // message type
		message.title = data.status; // message status
		message.showMessage();

		// Success
		if (data.status === 'success') {
			$('.edit-delivery_term .delivery_term-' + data.id).replaceWith('<tr id="placeholder-' + data.id + '"></tr>'); // show message
			$('#placeholder-' + data.id).replaceWith(data.view);
			DisneyABC.SetupPlugins('.edit-delivery_term .delivery_term-' + data.id);
		}
	});

	// Fail
	request.fail(function(jqXHR, data) {
		var response = $.parseJSON(jqXHR.responseText);
		var message = new Message(response.error.message);
		message.showMessage();
	});
});


/* MediaListing button/tab toggling - used to list All, Clearances, Document tabs */
/*	if($('#mediasection').length > 0)
	{
		//hide the tables
		$('.medialist-content').hide();

		$('.medialist-button').click(function(){
			var clicked = this;

			// Change active medialist button
			$('.medialist-button').parent().removeClass('active');
			$(clicked).parent().addClass('active');

			// Change visible medialist table
			$('.medialist-content').hide();
			$('#medialist_'+$(clicked).attr('id')).closest('.medialist-content').show();

			return false;
		});

		$('.active .medialist-button').click();

	}*/
if ($('.medialist-video').length > 0) { // Video Assets - adds hidden fields with the ids of the available video files (filtered via datatables), not fully implemented yet
	$('.csv_xml_export').click(function() {
		oTable = DisneyABC.orderFilesTable;
		var nNodes = oTable.fnGetNodes();
		var formSpan = $(this).closest(".csv_xml_form");
		var dataForm = $(document.createElement('form'));
		dataForm.css('display: none;');
		dataForm.attr('method', 'POST');
		dataForm.attr('action', formSpan.data('action'));

		$(".csv_xml_form input[type='hidden']").remove();

		$.each(oTable.$('tr', {
			"filter": "applied"
		}), function(index, value) {
			dataForm.append('<input type="hidden" name="csv_xml_ids[]" value="' + $(this).data("id") + '" />'); // add ids from the datatable
		});
		// dataForm.append('<input type="submit" >');
		$('body').append(dataForm);
		dataForm.submit(); // submit with hidden input
		return false;
	});
}
$(function() {
	var hash = window.location.hash;
	hash && $('ul.nav a[href="' + hash + '"]').tab('show');
	$('html,body').scrollTop(0)

	$('.nav-tabs a').click(function(e) {
		// $(this).tab('show');
		var scrollmem = $('body').scrollTop();
		window.location.hash = this.hash;
		$('html,body').scrollTop(scrollmem);
	});
});

/* Airdate Information button/tab toggling */
if ($('.airdate-button').length > 0) {
	$('.airdate-button').click(function() {
		var clicked = this;
		var clearance_id = $(clicked).attr('id').substring(8)

		//Change active button

		$('.airdate-button').parent().removeClass('active');
		$(clicked).parent().addClass('active');

		//Change visible episode-details table
		$('.episode-details').hide();
		$('#' + $(clicked).attr('id') + '_transmissions').show();
		return false;
	});
	// }

	// show / hide DOA episode details - doesn't work in IE7
	function toggleClickHandler(jqThis, ev, recurse) {
		ev.preventDefault();

		jqThis.parent().addClass('active').siblings().removeClass('active');

		// #episode123-details
		var div_id = jqThis.attr('id') + "-details";

		$('#' + div_id).show().siblings('.episode-details').hide();

		// Toggle the media filters to show matching product
		var div_id = jqThis.attr('data-media-filter');

		if (recurse) {
			if ($('a[data-date="' + div_id.substring(7) + '"]').length) {
				filterDateClickHandler($('a[data-date="' + div_id.substring(7) + '"]'), ev, false);
			}

			var text_filter = jqThis.attr('text-filter');

			$("a[href='#filterByText']").filter(function() {
				return $(this).text().trim() == text_filter;
			}).each(function() {
				filterDateClickHandler($(this), ev, false);
			});
		}
		return false;
	}

}
//$(document).ready(function(){DisneyABC.Init();}});


(function($) {
	$.fn.extend({
		//pass the options variable to the function
		confirmModal: function(options) {
			var html = '<div class="modal" id="confirmContainer"><div class="modal-header"><a class="close" data-dismiss="modal">×</a>' +
				'<h3>#Heading#</h3></div><div class="modal-body">' +
				'#Body#</div><div class="modal-footer">' +
				'<a href="#" class="btn btn-primary" id="confirmYesBtn">Delete Token</a>' +
				'<a href="#" class="btn" data-dismiss="modal">Cancel</a></div></div>';

			var defaults = {
				heading: 'Please confirm',
				body: 'Body contents',
				callback: null,
				confirmUrl: null
			};

			var options = $.extend(defaults, options);
			html = html.replace('#Heading#', options.heading).replace('#Body#', options.body);
			$(this).html(html);
			$(this).modal('show');
			var context = $(this);
			$('#confirmYesBtn', this).click(function() {
				if (options.callback != null)
					options.callback();
				$(context).modal('hide');
			});
		}
	});

	//BEGIN ADD DOCUMENT
	if ($("#add-doc-files").length) {

		/* for add documents - show/hide dropdown sections based on file association selected */
		$("#add-doc-files #file_association").change(function(e) {
			if ($(this).val() == "Clearance") {
				$("#add-doc-files #add-doc-clearance").find(':input').prop('disabled', false);
				$("#add-doc-files #add-doc-clearance").show();
			} else {
				$("#add-doc-files #add-doc-clearance").hide();
				$("#add-doc-files #add-doc-clearance").find(':input').prop('disabled', true);
			}
			if ($(this).val() == "Product") {
				$("#add-doc-files #add-doc-product").find(':input').prop('disabled', false);
				$("#add-doc-files #add-doc-product").show();
			} else {
				$("#add-doc-files #add-doc-product").hide();
				$("#add-doc-files #add-doc-product").find(':input').prop('disabled', true);
			}
			if ($(this).val() == "Product" || $(this).val() == "Clearance") {
				$("#add-doc-files #business-line-list").hide();
				$("#add-doc-files #business-line-list").find(':input').prop('disabled', true);
			} else {
				$("#add-doc-files #business-line-list").find(':input').prop('disabled', false);
				$("#add-doc-files #business-line-list").show();
			}
		});

		$("#add-doc-files #file_association").change();

		//Edit Document delete button
		$('.editdoc .btnDelete').click(function() {
			return confirm("Warning! You are about to delete this file. This action cannot be undone.");
		});

	} //END ADD DOCUMENT

	// switch the language and territory list on add and edit document - based on the clearance id (if used)
	if ($("#add-doc-files").length > 0 || $("#edit-doc-files").length > 0) {
		var id = 0;
		var language_id = 0;
		var territory_id = 0;
		$("#document_clearance").on('select2-selecting', function(e) {
			id = e.val;
			language_id = $("#clearance_" + id + " .language").text(); // can't be empty in the clearance
			territory_id = $("#clearance_" + id + " .territory").text(); // can't be empty
			$(".language").val(language_id).trigger("change");
			$(".territory").val(territory_id).trigger("change");
		});

	}

	//BEGIN ADD VIDEO
	if ($("#asset_upload_video").length) {
		// DisneyABC.CURRENT_SELECTED_OUTPUT_ID = -1;

		$("#asset_upload_video #file_association").change(function(e) {
			if ($(this).val() == "Clearance") {
				$("#add-doc-clearance").find(':input').prop('disabled', false);
				$("#add-doc-clearance").show();
			} else {
				$("#add-doc-clearance").hide();
				$("#add-doc-clearance").find(':input').prop('disabled', true);
			}
			if ($(this).val() == "Product") {
				$("#add-doc-product").find(':input').prop('disabled', false);
				$("#add-doc-product").show();
			} else {
				$("#add-doc-product").hide();
				$("#add-doc-product").find(':input').prop('disabled', true);
			}
			if ($(this).val() == "Title" || $(this).val() == "Season" || $(this).val() == "Program") {
				$("#add-doc-business-line").find(':input').prop('disabled', false);
				$("#add-doc-business-line").show();
			} else {
				$("#business-line").hide();
				$("#business-line").find(':input').prop('disabled', true);
			}
		});

		//instigate show/hide correct association selects
		$("#asset_upload_video #file_association").change();

		//remove hidden audio rows on form submit
		$('#asset_upload_video').submit(function() {
			$(this).find('.channel_holder .hide :input').attr("disabled", "disabled");
			return true;
		});

		$('#delete_asset_button').click(function() {
			if (!confirm('Are you sure you want to delete the video file and all associated metadata?')) {
				event.preventDefault();
			}
		});

		$('#asset_upload_video').on('change', '.info :input', function(e) {
			$(this).closest('.info').removeClass('info');
		});

        if ($('#file_subtype_id').length > 0) {
            $('#file_subtype_id').change(function() {
                if ( $.inArray($(this).val(), video_link_ids) > -1) {
                    $('#trailer_link_div').show();
                } else {
                    $('#trailer_link_div').hide();
                }

			});
			$('#file_subtype_id').change();
		}

	}

	//file delete handling
	$('.btn-delete-actual-file').click(function(){
		if(confirm('Are you sure you want to delete the file?')){
			$(this).nextAll('.actual-file-field').first().val('');
			$(this).closest('.aspera-file-management').find('.existing-file').hide();
			$(this).closest('.aspera-file-management').find('.upload-file').show();
		}
	});

	//END ADD VIDEO

	$('#modal-from-dom.edit-document').on('hidden', function() {
		window.alert('hidden event fired!');
	});

	$('select#clearance_ids').on('change', function() {
		$('#add-doc-clearance > .val_error').html('');
	});

	$('select#product_ids').on('change', function() {
		$('#add-doc-product > .val_error').html('');
	});

	// Accordion Tables
	$(".accordion thead").click(function() {
		accordion_thead = this;
		$(accordion_thead).next("tbody").slideToggle(10).parent().toggleClass('open-accordion').toggleClass('closed-accordion');
		$(accordion_thead).children('tr').children('th').children('i').toggleClass('icon-chevron-down').toggleClass('icon-chevron-right');
	});

	$(".closed-accordion tbody").slideToggle(1);

})(jQuery);

DisneyABC.setCookie = function setCookie(c_name, value, exdays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
	document.cookie = c_name + "=" + c_value;
}

DisneyABC.getCookie = function getCookie(c_name) {
	var c_value = document.cookie;
	var c_start = c_value.indexOf(" " + c_name + "=");
	if (c_start == -1) {
		c_start = c_value.indexOf(c_name + "=");
	}
	if (c_start == -1) {
		c_value = null;
	} else {
		c_start = c_value.indexOf("=", c_start) + 1;
		var c_end = c_value.indexOf(";", c_start);
		if (c_end == -1) {
			c_end = c_value.length;
		}
		c_value = unescape(c_value.substring(c_start, c_end));
	}
	return c_value;
}

$(window).resize(function() {
	var windowHeight = $(window).height();
	var modalHeight = Math.floor(windowHeight * 0.8);
	$('#modal-from-dom').css('max-height', modalHeight + 'px');
	var bodyHeight = Math.floor(modalHeight - $('.modal-header').height() - 30 /* padding */ - 40 /* margins */ );
	$('.modal-body').css('max-height', (bodyHeight) + 'px');

	$('#add-notification-form .modal-body').css('max-height', '99999px');
})

$(window).resize();
var visible = true;
$('.toggletabledata').click(function() {
	$('.tbody').toggle('slow', function() {
		visible = !visible;
		if (visible) {
			$('#toggleico').removeClass('icon-plus-sign');
			$('#toggleico').addClass('icon-minus-sign');
		} else {
			$('#toggleico').removeClass('icon-minus-sign');
			$('#toggleico').addClass('icon-plus-sign');
		}
	});
});


$('#program_id_for_no_product_title').change(function() {
	$('.select2-container.season_dropdown').addClass('hidden');
	$('#season_id_for_program_' + $(this).val()).removeClass('hidden');
});

$('#add_program_field, .no-enter-submit').keydown(function(ev) {
	if (ev.keyCode == 13) {
		ev.preventDefault();
		return false;
	}
});

$.fn.modal.Constructor.prototype.enforceFocus = function() {
	modal_this = this
	$(document).on('focusin.modal', function(e) {
		if (modal_this.$element[0] !== e.target && !modal_this.$element.has(e.target).length
			// add whatever conditions you need here:
			&& !$(e.target).hasClass('select2-input') /*&& !$(e.target.parentNode).hasClass('select2-input')*/ ) {
			modal_this.$element.focus()
		}
	})
};


$('#removeSelected').click(function() {
	$('td input:checked').closest('tr').hide();
})

$('#overdue').change(function() {
	if ($('#overdue').is(":checked")) {
		$('#end_date').attr('disabled', true);
		$('#current_workflow_step_id').attr('disabled', true);
	} else {
		$('#end_date').removeAttr('disabled');
		$('#current_workflow_step_id').removeAttr('disabled');
		//alert('not checked');
	}
}).change();

// audio_channel_id => audio_channel_id_2
function clone_input_to_new_id(select2_id, elem_id, label_id, new_elem, new_num) {

	var new_id = elem_id + '_' + new_num;
	new_elem.find('#' + select2_id).remove();
	new_elem.find('#' + elem_id).attr('id', new_id).val('');
	new_elem.find('#' + elem_id).attr('name', new_id).val('');
	new_elem.find('#' + label_id).attr('for', new_id);

	new_elem.find("#" + new_id).select2(); //reset the select
}

$('#btnAddAnotherChannel').click(function() {
	var num = $('.clonedInput').length, // how many "duplicatable" input fields we currently have
		new_num = new Number(num + 1), // the numeric ID of the new input field being added
		new_elem = $('#entry1').clone().attr('id', 'entry' + new_num).fadeIn('slow'); // clone the 1st row into a new row, and manipulate it's ID using new_num value

	// manipulate the name/id values of the input inside the new element
	// H2 - section
	new_elem.find('.heading-reference').attr('id', 'ID' + new_num + '_reference').attr('name', 'ID' + new_num + '_reference').html('Audio Specs #' + new_num);

	clone_input_to_new_id('s2id_audio_channel_id', 'audio_channel_id', 'audio_channel_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_audio_type_id', 'audio_type_id', 'audio_type_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_audio_mix_id', 'audio_mix_id', 'audio_mix_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_language_id', 'language_id', 'language_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_audio_medium_format_id', 'audio_medium_format_id', 'audio_medium_format_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_audio_file_type_bitrate_id', 'audio_file_type_bitrate_id', 'audio_file_type_bitrate_id_label', new_elem, new_num);
	clone_input_to_new_id('s2id_audio_sampling_rate_id', 'audio_sampling_rate_id', 'audio_sampling_rate_id_label', new_elem, new_num);

	// insert the new element after the last "duplicatable" input field
	$('#entry' + num).after(new_elem);
	$('#ID' + new_num + '_title').focus();

	// enable the "remove" button
	$('#btnDel').attr('disabled', false);

	// right now you can only add 12 sections. change '12' below to the max number of times the form can be duplicated
	if (new_num == 12) {
		$('#btnAddAnotherChannel').attr('disabled', true).prop('value', "You've reached the maximum # of Audio Channels");
	}
});

$('#btnDel').click(function() {
	// confirmation
	if (confirm("Are you sure you wish to remove this section? This cannot be undone.")) {
		var num = $('.clonedInput').length;
		// how many "duplicatable" input fields we currently have
		$('#entry' + num).slideUp('slow', function() {
			$(this).remove();
			// if only one element remains, disable the "remove" button
			if (num - 1 === 1)
				$('#btnDel').attr('disabled', true);
			// enable the "add" button
			$('#btnAddAnotherChannel').attr('disabled', false).prop('value', "add section");
		});
	}
	return false;
	// remove the last element

	// enable the "add" button
	$('#btnAddAnotherChannel').attr('disabled', false);
});

$('#btnDel').attr('disabled', true);


$(document).ready(function() {

	// Find all, forms on the page with an attribute of
	// data-validation. it then reads the passes the
	// value of the data-validation attribute to jquery validate
	var options = {
		errorPlacement: function(error, element) {
			$(element).closest('div').append(error);
		}
	}
	$("form[data-validation]").validate(
		$.extend({}, $("form[data-validation]").data("validation"), options)
	);


	var uploadPathsReceived = function uploadPathsReceived(pathsArray) {
		var files = pathsArray['dataTransfer']['files'];

		if (uploadPathsReceived.fileSelecting == undefined || uploadPathsReceived.fileSelecting == 'main_file') {
			var file_path_selector = '#file_path';
			var button_selector = '#select_aspera_file';
			var textbox_selector = '.aspera_textbox';
			$('input[name="file_changed"]').attr('value', true);
		} else if (uploadPathsReceived.fileSelecting == 'cc_file') {
			var file_path_selector = '#cc_file_path';
			var button_selector = '#select_cc_file';
			var textbox_selector = '.cc_aspera_textbox';
		} else if (uploadPathsReceived.fileSelecting == 'preview_file') {
			var file_path_selector = '#preview_file_path';
			var button_selector = '#select_preview_file';
			var textbox_selector = '.preview_aspera_textbox';

			if (files.length === 1) {
				var fileName = files[0]['name'];
				// the previewInfo element is actually the wrong file type error, which is purely visual, you can upload non-flv previews
				if (fileName.substring(fileName.length - 4).toLowerCase() == ".flv") {
					$('.previewInfo').hide();

				} else {
					$('.previewInfo').show();
				}
			} else {
				$('.previewInfo').show();
			}

			if ($('#preview_file_error').length) {
				$('#preview_file_error').remove();
			}
		}

		DisneyABC.AsperaUploadPaths = [];
		$(file_path_selector).attr('value', '');
		if (files.length == 1) {
			$(button_selector).text("Clear File");
			DisneyABC.AsperaUploadPaths = files[0]['name'];
			// this is using the error text box to put the file name in when everything works correctly
			var error_message = files[0]['name'];
			$(button_selector).parent().children(textbox_selector).text(error_message).removeClass('error');
			$(file_path_selector).attr('value', files[0]['name']);
		} else {
			var error_message = 'Please select a single file';
			$(button_selector).parent().children(textbox_selector).text(error_message).addClass('error');
		}
	}

	uploadPathsReceived.fileSelecting = 'main_file';

	if ($('#select_aspera_file').length != 0 || $('#select_cc_file').length != 0 || $('#select_preview_file').length != 0) {
		DisneyABC.AsperaInit();
	}

	$('#select_aspera_file').click(function() {
		if ($('#file_path').val() != "") {
			$(this).text('Select File');
			$('#file_path').val("");
			$(this).parent().children('.aspera_textbox').text('');
			$('input[name="file_changed"]').attr('value', true);
		} else {
			uploadPathsReceived.fileSelecting = 'main_file';
			DisneyABC.getPathsToUpload(uploadPathsReceived);
		}
	});

	$('#select_cc_file').click(function() {
		if ($('#cc_file_path').val() != "") {
			$(this).text('Select File');
			$('#cc_file_path').val("");
			$(this).parent().children('.cc_aspera_textbox').text('');
		} else {
			uploadPathsReceived.fileSelecting = 'cc_file';
			DisneyABC.getPathsToUpload(uploadPathsReceived);
		}
	});

	$('#select_preview_file').click(function() {
		if ($('#preview_file_path').val() != "") {
			$(this).text('Select File');
			$('#preview_file_path').val("");
			$(this).parent().children('.preview_aspera_textbox').text('');
			$('.previewInfo').hide();
		} else {
			uploadPathsReceived.fileSelecting = 'preview_file';
			DisneyABC.getPathsToUpload(uploadPathsReceived);
		}
	});

	if ($('#aspera_upload_page').length != 0) {
		DisneyABC.AsperaInit();

		function redirectPage() {
			DisneyABC.files_to_upload -= 1;
			if (DisneyABC.files_to_upload == 0) {
				window.location.replace($('.aspera_upload_redirect').text());
			}
		}

		function parse_file_name(file_path) {
			var file_name = file_path.split("\\");
			var file_name_mac = file_path.split("/");
			if (file_name.length == 1 && file_name_mac.length != 1) {
				file_name = file_name_mac;
			}
			file_name = file_name[file_name.length - 1];
			return file_name;
		}

		function parse_extension(file_path) {
			var re = /(?:\.([^.]+))?$/;
			return re.exec(file_path);
		}

		var goodtogo = false;

		function checkAsperaInit() {
			try {
				temp = new AW4.Connect();
				goodtogo = true;
			} catch (e) {
				window.setTimeout(checkAsperaInit, 100);
			}
			if (goodtogo) {
				initiateAsperaTransfer();
			}
		}

		checkAsperaInit();



		function initiateAsperaTransfer() {
			DisneyABC.files_to_upload = 1;
			
			var file_id = $('.aspera_upload_file_id').text();
			var file_path = $('.aspera_upload_file_path').text();
			var file_name = parse_file_name(file_path);
			var file_name = "/uploads/sources/" + file_id + "_" + file_name;

			if (file_path != '') {
				DisneyABC.files_to_upload += 1;
				DisneyABC.preUploadChecks([file_path], {
					'save_name': file_name,
					'done_callback': redirectPage
				});
			}

			// Let's set up the upload for closed caption files
			file_id = $('.aspera_upload_file_id').text();
			file_path = $('.aspera_upload_cc_file_path').text();
			file_name = parse_file_name(file_path);
			file_name = "/uploads/closed_captions/" + file_id + "_" + file_name;
			if (file_path != '') {
				DisneyABC.files_to_upload += 1;
				DisneyABC.preUploadChecks([file_path], {
					'save_name': file_name,
					'done_callback': redirectPage
				});
			}

			// Let's set up the upload for preview files
			file_id = $('.aspera_upload_file_id').text();
			file_path = $('.aspera_upload_preview_file_path').text();
			file_name = parse_file_name(file_path);
			file_name = "/uploads/previews/" + file_id + "_" + file_name;
			if (file_path != '') {
				DisneyABC.files_to_upload += 1;
				DisneyABC.preUploadChecks([file_path], {
					'save_name': file_name,
					'done_callback': redirectPage
				});
			}

			redirectPage();
		}
	}


	if ($('.aspera_download_selected_button').length > 0) {
		function UpdateDownloadButton() {
			var totalSize = 0;
			$('input.fileCollector:checked').each(function() {
				totalSize += parseInt($(this).closest('tr').attr('data-filesize'));
			});

			if (totalSize > 0) {
				$('.aspera_download_selected_button').text('Download ' + DisneyABC.ReadableFilesize(totalSize)).removeAttr('disabled');
			} else {
				$('.aspera_download_selected_button').text("Nothing to download").attr('disabled', true);
			}
		}

		$('input[type="checkbox"]').change(function() {
			UpdateDownloadButton();
		});
		UpdateDownloadButton();

		$('.aspera_download_selected_button').click(function() {
			DisneyABC.AsperaInit();
			var download_list = [];
			$('input.fileCollector:checked').each(function() {

				download_list = download_list.concat([$(this).data('source')]);
				if ($(this).data('ccfile') != null && $(this).data('ccfile').length > 0) {

					download_list = download_list.concat([$(this).data('ccfile')]);
				}
			});

			DisneyABC.setupDownload(download_list);
			return false;
		});
	}

	$('.download_with_aspera').click(function() {
		DisneyABC.AsperaInit();
		var download_list = [$(this).attr('data-file-name')];

		DisneyABC.setupDownload(download_list);
		return false;
	});

	if ($('#deals_datatable').length > 0) {
		function UpdateOpenDealsText() {
			if ($('#open_deals').is(":checked")) {
				$('.open_deals_header').removeClass("hide").show();
				$('.deals_header').addClass("hide").hide();
			} else {
				$('.open_deals_header').addClass("hide").hide();
				$('.deals_header').removeClass("hide").show();
			}
		}

		$('.filterbutton').click(function(ev) {
			$('.datatables-error-div-container').hide();
			UpdateOpenDealsText();
			DisneyABC.deals_datatable.fnPageChange("first");
			DisneyABC.deals_datatable.fnDraw();
			ev.preventDefault();
			return false;
		});
		UpdateOpenDealsText();
	}

	if ($('.edit-windows-delete-all').length > 0 || $('.edit-windows-save').length > 0) {
		DisneyABC.edit_window = {};
		DisneyABC.edit_window.delete_confirmed = false;
		DisneyABC.edit_window.checkboxes_checked = {};

		$('input[type="checkbox"]:checked').each(function() {
			if ($(this).attr('data-id') !== undefined) {
				DisneyABC.edit_window.checkboxes_checked[$(this).attr('data-id')] = 1;
			}
		});

		function hasAnyWindowChanged() {
			var selected = {};
			$('input[type="checkbox"]:checked').each(function() {
				if ($(this).attr('data-id') !== undefined) {
					selected[$(this).attr('data-id')] = 1;
				}
			});
			for (var key in DisneyABC.edit_window.checkboxes_checked) {
				if (selected[key] === undefined) {
					return true;
				}
			}
			return false;
		}

		function confirmDeleteWindows(btn) {
			DisneyABC.edit_window.window_button_pressed = btn;
			$('#confirm_delete_modal').modal('show');
		}

		$('.delete_confirm').click(function(ev) {
			$('#confirm_delete_modal').modal('hide');
			DisneyABC.edit_window.delete_confirmed = true;
			DisneyABC.edit_window.window_button_pressed.click();
		});

		$('.edit-windows-delete-all').click(function(ev) {
			if (DisneyABC.edit_window.delete_confirmed === false) {
				confirmDeleteWindows($(this));
				ev.preventDefault();
				return false;
			}
		});

		$('.edit-windows-save').click(function(ev) {
			if (hasAnyWindowChanged()) {
				if (DisneyABC.edit_window.delete_confirmed === false) {
					confirmDeleteWindows($(this));
					ev.preventDefault();
					return false;
				}
			}
		});
	}

	//Gets values from a group of checkboxes contained within the element as if it was a select or multiselect.
	jQuery.fn.getCheckablesVal = function() {
		//TODO: handle radio buttons (which would return a single value not an array. not doing that yet as it is not yet necessary)
		var values = [];
		$(this).find('input:checked').each(function() {
			values.push(this.value);
		});
		return values;
	}

	//Sets values from a group of checkboxes contained within the element as if it was a select or multiselect.
	jQuery.fn.setCheckablesVal = function(values) {
		//TODO: handle radio buttons (which would return a single value not an array. not doing that yet as it is not yet necessary)
		$(this).find('input').each(function() {
			if (values && values.indexOf(this.value) != -1) {
				$(this).prop('checked', true);
			} else {
				$(this).prop('checked', false);
			}
		});
	}
});

// account/permissions
if ($('#permission_org_circle').length) {
	function setCircleUser(elem, current_user_id) {
		//get all checkboxes with a class of checkbox
		if ($('#circle_type option:selected').val() == 2) {
			if ($('input:checkbox.checkbox:checked').length > 0) {
				var urlString = "/account/permissions/" + current_user_id; // ajax url
				var userId = elem.value; // get user id from button attrib
				var circle_id = elem.getAttribute('data-checked_id');
				var request = $.ajax({ // ajax call
					url: urlString,
					data: {
						'circle_user_id': userId,
						'user_id': current_user_id,
						'circle_id': circle_id,
						'checked': elem.checked
					},
					type: 'PUT', // type POST
					dataType: 'html'
				});
				request.done(function(data) {
					data = $.parseJSON(data);
					var message = new Message(data.message);
					message.type = data.status;
					message.title = data.status;
					elem.value = data.id; // empty on delete, fill in after adding
					message.showMessage();
				});

				request.fail(function(jqXHR, textStatus) {
					var message = new Message("Failed to Save.");
					message.title = "error";
					message.showMessage();
				});


			} else {
				alert('User must be a member of at least 1 organization');
				//reset to checked
				elem.checked = 'true';
			}

		} else {
			var urlString = "/account/permissions/" + current_user_id; // ajax url
			var userId = elem.value; // get user id from button attrib
			var circle_id = elem.getAttribute('data-checked_id');
			var request = $.ajax({ // ajax call
				url: urlString,
				data: {
					'circle_user_id': userId,
					'user_id': current_user_id,
					'circle_id': circle_id,
					'checked': elem.checked
				},
				type: 'PUT', // type POST
				dataType: 'html'
			});

			request.done(function(data) {
				data = $.parseJSON(data);
				var message = new Message(data.message);
				message.type = data.status;
				message.title = data.status;
				elem.value = data.id; // empty on delete, fill in after adding
				message.showMessage();
			});

			request.fail(function(jqXHR, textStatus) {
				var message = new Message("Failed to Save.");
				message.title = "error";
				message.showMessage();
			});


		}

	}
}

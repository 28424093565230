// account > subscriptions
DisneyABC.subscriptions = (function($) {
	'use strict';

	/**
    * Things that are specific to this page
    */
	var page = {
		assetsCheckbox: '.file_type_asset',
		fileSubtype: $('#subscription_form #file_subtype'),
		userId: $('#subscription_form #file_subtype').data('user_id')
	};

	// check if file subtypes checklist should show or hide
	var toggleSubscriptionFilesubtypes = function(checkboxElement) {
		var showHide = 'hide';
		var disabled = true;
		var isCheckedAssetsCheckbox = $(page.assetsCheckbox).is(':checked');
		if (isCheckedAssetsCheckbox) {
			showHide = 'show';
			disabled = false;
		}
		page.fileSubtype[showHide]();
		page.fileSubtype.find('select,textarea,input').prop('disabled', disabled);

		if (checkboxElement) {
			toogleChildrenChecksByParent(checkboxElement, 'file_type_id', '.file-type-asset');
		}
	};

	var toogleChildrenChecksByParent = function(checkboxParent, selectorId, selectorChildren) {
		var isCheckedCheckbox = $(checkboxParent).is(':checked');
		var parentId = $(checkboxParent).data(selectorId);
		if (parentId) {
			$(selectorChildren + '-' + parentId).prop('checked', isCheckedCheckbox);
			var checkAll = $(selectorChildren + '-' + parentId + '-check-all');
			if (checkAll.length) {
				checkAll.prop('checked', isCheckedCheckbox);
			}
		}
	};

	//To validate at init if kind of assets have to be checked or not
	var validateChildrenAssetsMustBeChecked = function() {
		var amountExcludedAssets = parseInt($('#amount-excluded-assets').val());
		$(page.assetsCheckbox).each(function() {
			var idParent = $(this).data('file_type_id');
			if ($(this).is(':checked')) {
				if (amountExcludedAssets == 0) {
					$('.file-type-asset-' + idParent).prop('checked', true);
				}
			} else {
				$('.file-type-asset-' + idParent).prop('checked', false);
			}
		});
	};

	var toogleParentCheckByChildren = function(selectorContainerChecks, classToGetIdParent, dataAttrToGetParent) {
		var clickedElement = event.target;
		var checkToGetParentId = null;
		var checkboxElement = null;
		var thereAreChildrenChecked = false;
		var parentCheckAll = null;

		if (clickedElement.type && clickedElement.type == 'checkbox') {
			checkboxElement = $(clickedElement);
			checkToGetParentId = $(clickedElement);
		} else {
			return;
		}

		if (checkboxElement.hasClass('check-all')) {
			checkToGetParentId = checkboxElement.parent().next().children('input[type="checkbox"]');
			thereAreChildrenChecked = checkboxElement.is(':checked');
			parentCheckAll = checkboxElement.closest(selectorContainerChecks);
		}

		if (!checkToGetParentId) {
			return;
		}

		var idParentArray = checkToGetParentId.attr('class').split(classToGetIdParent);
		if (idParentArray.length) {
			var chidlrenChecked = [];
			if (parentCheckAll) {
				chidlrenChecked = $(selectorContainerChecks)
					.not(parentCheckAll)
					.find('.' + classToGetIdParent + idParentArray[1] + ':not(.check-all):checked');
			} else {
				if (!checkboxElement.is(':checked')) {
					checkboxElement.closest(selectorContainerChecks).find('.check-all').prop('checked', false);
				}
				chidlrenChecked = $('.' + classToGetIdParent + idParentArray[1] + ':not(.check-all):checked');
			}
			if (chidlrenChecked.length) {
				thereAreChildrenChecked = true;
			}
			$('[' + dataAttrToGetParent + '=' + idParentArray[1] + ']').prop('checked', thereAreChildrenChecked);
		}
	};

	var addClickEvents = function() {
		if ($('.business-line-subscriptions').length) {
			$('.business-line-subscriptions').click(function() {
				toogleChildrenChecksByParent(this, 'business-line-id', '.business-line');
			});
		}

		if ($('.business-lines-container').length) {
			$('.business-lines-container').click(function() {
				toogleParentCheckByChildren('.business-lines-container', 'business-line-', 'data-business-line-id');
			});
		}

		if ($('.file-subtypes-container').length) {
			$('.file-subtypes-container').click(function() {
				toogleParentCheckByChildren('.file-subtypes-container', 'file-type-asset-', 'data-file_type_id');
				toggleSubscriptionFilesubtypes(null);
			});
		}
	};

	return {
		/**
         * initialize the object
         */
		init: function() {
			if ($(page.assetsCheckbox).length) {
				// check if assets are selected on load
				toggleSubscriptionFilesubtypes(null);

				// update the assets check lists - hide whole section or add/remove groups
				$(page.assetsCheckbox).click(function() {
					toggleSubscriptionFilesubtypes(this);
				});
				validateChildrenAssetsMustBeChecked();
				addClickEvents();
			}
		}
	};
})(jQuery);

$(document).ready(function() {
	'use strict';
	DisneyABC.subscriptions.init();
});

(function($, window) {

    $.fn.datetimepicker.defaults.maxDate = moment({y:3001});
    window.DisneyABC.EventManager.subscribe('loadTimePicker', function () {
        $('.datetimepicker').datetimepicker();
    });
    $('.datetimepicker').datetimepicker();


    $('#toggleDates').click(function() {
        var $datesLabel = $('#toggleDatesLabel');
        var $datesContainer = $('.datesContainer');
        $datesContainer.toggle();

        if ($datesContainer.is(':hidden')) {
            $datesLabel.html($datesLabel.data('show-label'));
        } else {
            $datesLabel.html($datesLabel.data('hide-label'));
        }
    });

    $('.business-line').on('change', function (e) {
        var $datesContainer = $('.datesContainer');

       if ($('.business-line').select2('val') == '1'){
           $datesContainer.hide();

       } else {
           $datesContainer.show();
       }

    });
})(jQuery, window);



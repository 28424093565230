/**
 * Called by `$('#table_id').ajaxTable($options);` (note: ids can not have hyphens)
 * Must have a template either in the default template file or passed in as templatePath
 * Make columns sortable by adding the data-sortable attribute
 * The row template defaults to the selected element id with _row_template appended (i.e. circles_row_template)
 */
(function ($, document, window, templateManager, WaitState, undefined) {

    $.fn.ajaxTable = function createAjaxTable(options) {
        if (this.oldDataTable !== undefined){
            this.oldDataTable.remove();
        }
        var ajaxTable = {
            queryInProgress : false,
            ajaxUrl         : '/',
            templatePath    : '/templates/datatables.html',
            sorted          : {},
            cache           : {},
            context         : null,
            defaults        : {
                ajaxUrl      : '/',
                templatePath : '/templates/datatables.html',
                rowTemplate  : null
            },
            options         : {},
            /* Table initialisation */
            populateTableBody : function (response, templateName, tbody) {
                ajaxTable.Templates.getTemplate(templateName, response, function (template) {
                    $(tbody).html(template);
                });
            },
            setPerPage : function (context, perPage) {
                context.data('per-page', perPage);
            },
            getPerPage : function (context) {
                if (typeof context.data('per-page') == 'undefined')
                    return 25;
                else
                    return context.data('per-page');
            },
            setEndPage : function (lastPage, page) {

                if(page <= 4)
                {
                    return Math.min(7, lastPage);
                }
                else if (page >= lastPage - 3)
                {
                    return lastPage;
                }
                else
                {
                    return Math.min(page + 3, lastPage);
                }
            },
            setStartPage : function (page, lastPage) {
                if(page <= 4)
                {
                    return 1;
                }
                else if (page >= lastPage - 3)
                {
                    return Math.max(1, lastPage - 6);
                }
                else
                {
                    return Math.max(page - 3, 1);
                }
            },
            buildPaginator : function (context, page, lastPage) {
                var data = {
                        id            : context[0].id,
                        disabled_prev : page <= 1 ? ' disabled' : '',
                        disabled_next : page >= lastPage ? ' disabled' : '',
                        start         : ajaxTable.setStartPage(page, lastPage),
                        end           : ajaxTable.setEndPage(lastPage, page),
                        page          : page
                    },

                    existing = $('#' + context[0].id + '-paginator-wrapper'),
                    existingBottom = $('#' + context[0].id + '-paginator-wrapper-bottom');

                if (existing.length > 0) {
                    existing.prev('.row').remove();
                    existing.remove();
                    existingBottom.next('.row').remove();
                    existingBottom.remove();
                }

                ajaxTable.Templates.getTemplate('paginator_template', data, function (template) {
                    var paginator = $(template.trim()),
                        bottom;
                    paginator.insertBefore(context);
                    ajaxTable.attachClickEventToPagination(context);
                    bottom = paginator.clone(true);
                    bottom[0].id = paginator[0].id + '-bottom';
                    bottom.insertAfter(context);
                });
            },

            showItemsPerPage : function (response, context, templateName) {

                // All we need is our data id
                var data = {
                    id            : context[0].id
                };

                // First the items per page
                var existing_selection = $('.' + context[0].id + '-perPage-select');

                if (existing_selection.length > 0) {
                    $('.' + context[0].id + '-perPage-select-wrapper').remove();
                    existing_selection.remove();
                }

                ajaxTable.Templates.getTemplate(templateName, data, function (template) {
                    $(template.trim()).insertAfter(context);
                    $(template.trim()).insertBefore(context);
                });

                // Now detect changes on select box
                $('.' + context[0].id + '-perPage-select').change(function(){
                    var perPage = $(this).find("option:selected").attr('value');
                    ajaxTable.setPerPage(context, perPage);
                    ajaxTable.queryAjaxSource(context, {per_page : perPage, page: 1});
                });

                // Convert it to select2
                $('.' + context[0].id + '-perPage-select').each(function(index, item) {
                    $(this).find('option[value="' + ajaxTable.getPerPage(context) + '"]').attr("selected",true);
                    $(this).select2().select2("val", ajaxTable.getPerPage(context));
                });

                // Now include the modal
                ajaxTable.Templates.getTemplate('modal_go_to_page', data, function (template) {
                    $(template.trim()).insertAfter(context);
                    $('#goToPageModal').on('shown.bs.modal', function () {
                        $('#goToPageAlert').hide();
                        $('#goToPageInput').val('');
                        $('#goToPageInput').focus();
                    })
                });
            },

            showCurrentPageOfCount : function (response, context, templateName) {
                var existing = $('#' + context[0].id + '-table-info'),
                    data = {
                        id           : context[0].id,
                        current_page : response.paginator.current_page,
                        last_page    : response.paginator.total_pages,
                        total_count  : response.paginator.total_count,
                        from         : (response.paginator.current_page - 1) * response.paginator.limit + 1,
                        to           : Math.min(response.paginator.total_count, response.paginator.current_page * response.paginator.limit)
                    };

                if (existing.length > 0) {
                    existing.remove();
                }

                ajaxTable.Templates.getTemplate(templateName, data, function (template) {
                    $(template.trim()).insertAfter(context);
                    $(template.trim()).insertBefore(context);
                });
            },

            buildTable : function (response, context) {
                var tbody = context.find('tbody');
                tbody.empty();
                context.data('current-page', response.paginator.current_page);
                context.data('last-page', response.paginator.total_pages);
                var id = context[0].id;
                if(this.options.rowTemplate){ // temporary until row templates can be selected
                    id = this.options.rowTemplate;
                }
                if(typeof response.data === 'undefined' || response.data.length === 0){
                    this.reportError(context, 'No results found for your query.');
                    return;
                }
                ajaxTable.populateTableBody(response, id + '_row_template', tbody[0]);
                if (response.paginator.total_pages > 1){
                    ajaxTable.showItemsPerPage(response, context, 'paginator_select_per_page');
                    ajaxTable.showCurrentPageOfCount(response, context, 'paginator_count_of_total');
                    ajaxTable.buildPaginator(context, response.paginator.current_page, response.paginator.total_pages);
                } else {

                    ajaxTable.buildPaginator(context, 0, 0); // still must initialize this. Going from one page to two pages fails in Safari
                    $('#' + context[0].id + '-paginator-wrapper').remove();
                    $('#' + context[0].id + '-paginator-wrapper-bottom').remove();
                    $('#' + context[0].id + '-table-info').remove();
                    $('.' + context[0].id + '-perPage-select').each(function(index, item) {
                        $(item).find('option[value="' + ajaxTable.getPerPage(context) + '"]').attr("selected",true);

                        if ($(item).hasClass('select2-container')) {
                            $(item).select2('val', ajaxTable.getPerPage(context));
                        }
                    });
                }
            },

            queryAjaxSource : function (context, options) {
                var self = this,
                    cacheKey,
                    defaults = {
                        page : 1,
                        per_page: 25,
                        orderBy : ajaxTable.getCurrentOrderBy(context),
                        dir : ajaxTable.getCurrentDir(context)
                    };

                defaults = $.extend({}, defaults, this.sorted);

                options = $.extend({}, defaults, options);

                self.toggleSpinner(context);
                self.queryInProgress = true;

                cacheKey = context[0].id + '.' + JSON.stringify(options);

                if (ajaxTable.cache[cacheKey] && ! $(context).hasClass('purge-cache')) {
                    ajaxTable.buildTable(ajaxTable.cache[cacheKey], context);
                    self.queryInProgress = false;
                    self.toggleSpinner(context);
                } else {
                    if (self.options.queryStartCallback) {
                        self.options.queryStartCallback(ajaxTable);
                    }
                    $.ajax({
                        url      : ajaxTable.ajaxUrl,
                        dataType : 'JSON',
                        data : options,
                        success : function (response) {
                            ajaxTable.cache[cacheKey] = response;
                            ajaxTable.buildTable(response, context);
                            if (self.options.successCallback) {
                                self.options.successCallback(response);
                            }
                        },
                        error : function () {
                            self.reportError(context);
                        }
                    }).always(function () {
                        self.queryInProgress = false;
                        self.toggleSpinner(context);
                    });
                }
            },

            saveDefaultOrder : function(context, options) {
                if (typeof options.orderBy != 'undefined' && options.orderBy != null && options.orderBy != '') {
                    context.data('current-orderBy', options.orderBy);
                }

                if (typeof options.dir != 'undefined' && options.dir != null && options.dir != '') {
                    context.data('current-dir', options.dir);
                }

                if (typeof options.sort != 'undefined' && options.sort != null && options.sort != '') {
                    context.data('current-sort', options.sort);
                }
            },

            getCurrentOrderBy : function (context) {
                return typeof context.data('current-orderBy') == 'undefined' ? null : context.data('current-orderBy');
            },

            getCurrentDir : function (context) {
                return typeof context.data('current-dir') == 'undefined' ? 'asc' : context.data('current-dir');
            },

            getCurrentPageNumber : function (context) {
                return context.data('current-page');
            },

            getLastPageNumber : function (context) {
                return context.data('last-page');
            },

            attachClickEventToPagination : function (context) {
                $('div[id^="' + context[0].id + '-paginator"]').on('click', 'a', function (e) {
                    e.preventDefault();

                    var $this = $(this), $parent = $this.parent(), currentPage = ajaxTable.getCurrentPageNumber(context), requestedPage;

                    if ($parent.hasClass('goto')) {
                        $('#goToPageModal').modal('show');
                        $('#goToPageModalForm').submit(function( event ) {
                            event.preventDefault();

                            requestedPage = $('#goToPageInput').val();
                            $('#goToPageAlert').hide();

                            if (requestedPage.trim() == '') {
                                $('#goToPageModal').modal('hide');
                                return;
                            }

                            if (requestedPage < 1 || requestedPage > ajaxTable.getLastPageNumber(context) || isNaN(requestedPage)) {
                                $('#goToPageAlert').show();
                                return;
                            }

                            $('#goToPageModal').modal('hide');
                            ajaxTable.queryAjaxSource(context, {page : requestedPage, perPage: ajaxTable.getPerPage(context)});
                        });
                        return;
                    } else if ($parent.hasClass('first')) {
                        requestedPage = 1;
                    } else if ($parent.hasClass('last')) {
                        requestedPage = ajaxTable.getLastPageNumber(context);
                    } else if ($parent.hasClass('previous')) {
                        requestedPage = currentPage - 1;
                    } else {
                        if ($parent.hasClass('next')) {
                            requestedPage = currentPage + 1;
                        } else {
                            requestedPage = parseInt($(this).html(), 10);
                        }
                    }

                    if (!$parent.hasClass('disabled') && !ajaxTable.queryInProgress) {
                        ajaxTable.queryAjaxSource(context, {page : requestedPage, perPage: ajaxTable.getPerPage(context)});
                    }
                });
            },

            attachSortClickEvent : function (context) {

                var thead = context.find('thead');

                thead.find('th[data-sortable]').css('cursor', 'pointer');

                thead.on('click', 'th[data-sortable]', function () {
                    var $this = $(this),
                        dir = 'asc';

                    if ($this.hasClass('sorting_asc')) {
                        dir = 'desc';
                    }
                    if ($this.hasClass('sorting_date') && ! $this.hasClass('sorting_desc')) {
                        dir = 'desc';
                    }

                    if (!ajaxTable.queryInProgress) {
                        ajaxTable.saveDefaultOrder(context,
                            {
                                orderBy : this.getAttribute('data-sortable'),
                                dir     : dir
                            });
                        ajaxTable.queryAjaxSource(context,
                            {
                                page : ajaxTable.getCurrentPageNumber(context),
                                perPage: ajaxTable.getPerPage(context)
                            });
                        context.find('th').removeClass('sorting_asc sorting_desc');
                        $this.addClass('sorting_' + dir);
                    }
                });

            },

            toggleSpinner : function (context) {
                $(context).waitState();
            },

            reportError : function (context, error_message) {
                error_message = error_message || "Whoops! Looks like there was an issue with your request. Please try again later.";
                context.html($('<p class="error">'+error_message.toString()+'</p>'));
            },

            Templates : {

                stageTemplate : function () {
                    templateManager.stageTemplate(ajaxTable.templatePath);
                },

                getTemplate : function (name, data, callback) {
                    templateManager.loadRemoteTemplate(name, ajaxTable.templatePath, function (template) {
                        template = templateManager.renderTemplate(template.trim(), data);
                        callback(template);
                    });
                }
            },
            setOptions : function (options) {
                if (typeof options  === 'object') {
                    return $.extend({}, this.defaults, options);
                }
                this.defaults.ajaxUrl = options;
                return this.defaults;
            },
            remove : function () {
                ajaxTable.context.find('thead').unbind('click');
                $('div[id^="' + ajaxTable.context[0].id + '-paginator"]').unbind('click');
                delete ajaxTable.context.oldDataTable;
            },
            init   : function (options, context) {
                //ajaxTable.ajaxUrl = options;
                context = $(context);
                ajaxTable.context = context;
                ajaxTable.options = ajaxTable.setOptions(options);
                ajaxTable.ajaxUrl = ajaxTable.options.ajaxUrl;
                if (ajaxTable.options.sort !== 'none') {
                    ajaxTable.attachSortClickEvent(context, ajaxTable.options.sort);
                }

                $.when(ajaxTable.Templates.stageTemplate()).then(ajaxTable.queryAjaxSource(context, options), ajaxTable.saveDefaultOrder(context, options));

                return this;
            }
        };
        ajaxTable.init(options, this);
        this.oldDataTable = ajaxTable;
    }

})(jQuery, document, window, templateManager, WaitState);

$(document).on('click', function (e) {
    if ($(e.target).closest('.emoji-menu').length) {
        $(e.target).closest('.emoji-menu').hide();
    }
});

function doScroll(element) {
    var positionTop = $(element).offset().top;
    if($(element).hasClass('select2')){
        positionTop = $(element).parent().offset().top;
    }
    $('html,body').scrollTop(positionTop);
}

function enableDisabledElements(selector,enableDisabled) {
    $(selector).each(function(){
        $(this).attr('disabled', enableDisabled);
        
        if($(this).data().summernote != undefined){
            $(this).summernote(enableDisabled ? 'disable' : 'enable');
        }
           
        if($(this).closest('.datetimepicker').length && !enableDisabled) {
            $(this).closest('.datetimepicker').data('DateTimePicker').destroy();
            $(this).closest('.datetimepicker').datetimepicker();
        }
        
        //remove all classes error an label error
        $(this).removeClass('error');
        if ($(this).hasClass("select2")) {
            $("#s2id_" + $(this).attr("id") + " a").removeClass('error');
        }
        $(this).closest('form').find('#'+$(this).attr('id')+'-error').remove();
    });
}

function readOnlyMode() {
    if($('#read-only-mode').length){
        //don't disable items per page and input search in datatables
        var selector = ':input:not(.dataTables_length select,.dataTables_filter .search)';
        enableDisabledElements(selector,true);
    }
}

var formatDateTimeController = {
    jqDate: '.datecalendar',
    jqTime: '.datehours',
    adjustFormatDate: function(scope) {
        var jqDates = scope ? $(scope).find(this.jqDate) : $(this.jqDate);
        jqDates.keyup(function(e){
            $(this).val($(this).val().replace(/[^\d+|\/]/g, ''));
            if (e.keyCode !== 8 && e.keyCode !== 46 && ($(this).val().length == 2 || $(this).val().length == 5)){
                $(this).val($(this).val() + "/");
            }
            if($(this).val().length > 10){
                $(this).val($(this).val().substr(0,10));
            }
        });
        jqDates.change(function() {
            if($(this).val()) {
                var selectedDate = new Date($(this).val());
                var currentDate = new Date();
                currentDate.setHours(0,0,0,0);
                if($(this).data('after-today') && selectedDate.getTime() < currentDate.getTime()) {
                    alert("This date is from the past");
                    $(this).val('');
                    $(this).focus();
                }
            }
        });
    },
    adjustFormatTime: function(scope) {
        var jqTimes = scope ? $(scope).find(this.jqTime) : $(this.jqTime);
        jqTimes.keyup(function(e) {
            $(this).val($(this).val().replace(/[^\d+|:]/g, ''));
            if (e.keyCode !== 8 && e.keyCode !== 46 && $(this).val().length == 2){
                $(this).val($(this).val() + ":");
            }
            if($(this).val().length > 5){
                $(this).val($(this).val().substr(0,5));
            }
        });
    }
}

$(document).ready(function () {
    $('.datecalendar').each(function(){
        if($(this).data('after-today')) {
            var $closestDateTimepicker = $(this).closest('.datetimepicker');
            if($closestDateTimepicker.length) {
                var currentDate = new Date();
                $closestDateTimepicker.data('DateTimePicker').setMinDate(currentDate);
            }
        }
    });

    formatDateTimeController.adjustFormatDate();
    formatDateTimeController.adjustFormatTime();
});
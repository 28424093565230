$(function () {

    var programId = $('[name="program_id"]');
    $('#title-list').select2({
        ajax : {
            url         : DisneyABC.get_title_list,
            quietMillis : 200,
            data        : function (filter, page) {
                return {
                    'title_filter_input' : filter,
                    'program_id'         : programId.val(),
                    'page'               : page,
                    'per_page'           : -1
                }
            },
            results     : function (data) {
                var results = [];
                for (id in data.data) {
                    results.push({
                        id   : id,
                        text : data.data[id]
                    });
                }
                return {results : results.sort(sortText)};
            },
            cache       : true,
            type        : 'GET',
            dataType    : 'json'
        }
    });

    $('#add_program_field').select2({
        ajax : {
            url         : DisneyABC.get_program_list,
            quietMillis : 200,
            data        : function (filter, page) {
                return {
                    'name' : filter,
                    'page' : page
                }
            },
            results     : function (data) {
                var results = [];
                for (id in data.data) {
                    results.push({
                        id   : id,
                        text : data.data[id]
                    });
                }
                return {results : results.sort(sortText)};
            },
            cache       : true,
            type        : 'GET',
            dataType    : 'json'
        }
    });

    function sortText(a, b) {
        if (a.text > b.text) {
            return 1;
        }
        if (a.text < b.text) {
            return -1;
        }
        return 0;
    }
});

/**
 * Created by charliehay on 2016-07-06.
 */

/**
 * When a form is submitted mark the data
 * attribute as submitted, so that there aren't
 * multiple uploads.
 */
(function() {
    $('.singleUpload').on('submit', function(e) {
        var $form = $(this);
        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
            alert('already submitted');
            e.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            $form.data('submitted', true);
        }
        // Keep chainability
        return this;
    });

    var wysiwygGeneral = new WysiwygGeneral();
    wysiwygGeneral.init({
        idElement:'notification-body',
        toolbar: false,
        height: '100px'
    });

    var dropDownNotifications = $('#notification-to-send');

    var configSelect2Notifications = {
        element: dropDownNotifications,
        minimumInputLength: 2,
        ajax: {
            url: '/admin/notifications/dropdown-assets/',
            results: function (data, page) {
                return {
                    results :
                    data['data'].map(function (item) {
                        return {
                            id: item.id,
                            text: item.subject,
                            subject: item.subject,
                            template_id: item.template_id,
                            body: item.body,
                            email_delivery: item.email_delivery,
                            scheduled_delivery: item.scheduled_delivery,
                            scheduled_at_date: item.scheduled_at_date,
                            scheduled_at_time: item.scheduled_at_time,
                            app_banner: item.app_banner,
                            expired_at: item.expired_at
                        };
                    })
                };
            }
        }
    };

    var select2General = new Select2General(configSelect2Notifications);
    select2General.init();

    var setDataFields = function (data) {
        for(var key in data) {
            var checked = $('.notification-' + key).is(':checkbox') && data[key] == 1;
            var value = data[key] ? data[key].toString().trim() : '';
            $('.notification-' + key).each(function() {                
                $(this).prop('checked', checked);
                if($(this).data().summernote != undefined){
                    $(this).summernote('code', value);
                } else {
                    if($('.notification-' + key).is(':checkbox')) {
                        value = checked ? 1 : 0; 
                    }
                    $(this).val(value);
                }
            });
        }
    };

    var enableExpirationDateField = function () {
        var disabled = true;
        var fullDate = '';
        var $dateExpirationField = $('[name="notification_expires_at"]');
        if($('.notification-app_banner:checked').length) {
            disabled = false;
            var currentDate = new Date();
            var month = currentDate.getMonth() < 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
            var day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
            fullDate = month + '/' + day + '/' + currentDate.getFullYear();            
        }
        $dateExpirationField.prop('disabled', disabled);
        $dateExpirationField.val(fullDate);

        if(!disabled) {
            var $closestDateExpirationField = $dateExpirationField.closest('.date');
            $closestDateExpirationField.datetimepicker();
            $closestDateExpirationField.data('DateTimePicker').setMinDate(currentDate);
        }
    }

    dropDownNotifications.on('select2-selecting', function (e) {
        var dataNotification = e.choice;
        setDataFields(dataNotification);
        enableExpirationDateField();
    });

    dropDownNotifications.on('select2-removed', function (e) {
        var emptyData = {
            subject: "",
            template_id: "",
            body: "",
            email_delivery: 0,
            scheduled_delivery: 0,
            scheduled_at_date: "",
            scheduled_at_time: "",
            app_banner: "",
            expired_at: ""
        };
        setDataFields(emptyData);
        enableExpirationDateField();
    });
})();
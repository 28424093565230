/**
 * Canadian Individual Airdates admin
 * Updates the fields in the form to reflect the selected clearance
 * @type {{init}}
 */
DisneyABC.alternateDates = function ($) {
    var page = {
        form: $('#form-individual-alt-dates'),
        form2: $('#form-ranged-alt-dates'),
        airdate_type_simulcast_id: 2,
        alternate_date_offset_inputs: $('#offset_inputs'),
        clearance_triggered: false,
        titles_container: $('#alternate_container_doa'),
        dataTable: $('#alternate_container_doa').dataTable({
            "sDom": "<'row'r>t<'row'>",
            "aaSorting"       : [
                [ 3, "asc" ]
            ],
            "aoColumnDefs": [
                { 'bSortable': false, 'aTargets': [ 'no_sorting' ] }
            ],
            "bFilter": false,
            "bPaginate": false
        }),
        deleted_alternate_date_ids: $('#deleted_alternate_date_ids'),
        changed_alternate_date_comments: $('#changed_alternate_date_comments'),
        newCommentsDialog: $('#new_comments_dialog'),
        edit_mode_dialogs: $('#edit-mode-dialogs')
    };

    var deleteSuccessful = function ($row) {
        var message = new Message(quoteText($row.find('.title-name:first').text()) + " deleted successfully.");
        message.type = 'success';
        message.title = "Success";
        message.showMessage();
    };
    /**
     * Put quotation marks around text, also trim it so there isn't weird spacing
     * @param text
     * @returns {string}
     */
    var quoteText = function(text){
        return "'" + $.trim(text) + "'";
    };

    var _initHandlers = function () {
        page.form.on('change', '#individual-alt-date-type-id', function() {
            page.clearance_triggered = false;
        });
        page.form.on('change', '#individual-clearance-id', function() {
            page.clearance_triggered = true;
        });
        page.form.on('change', '#individual-clearance-id, #individual-alt-date-type-id', function() {
            var id = $('#individual-clearance-id').val();
            var airing_type_id = $('#individual-alt-date-type-id').val();
            // airing type will be determined by the clearance
            if(page.clearance_triggered) {
                airing_type_id = null;
            }
            return $.ajax({
                url: '/admin/alternate_dates/clearance/' + id,
                data: {
                    airing_type_id: airing_type_id
                },
                type: 'GET',
                dataType: 'json',

                complete: function (jqXHR) {
                    var response = $.parseJSON(jqXHR.responseText);
                    if (response.status === 'success') {
                        // only switch the airing type if clearance was changed
                        if (page.clearance_triggered) {
                            $('#individual-alt-date-type-id').select2('val', response.alternate_date_type_id);
                        }
                        $('#individual-start-date').val(response.start_date).change();
                        $('#individual-start-time').val(response.start_time).change();
                        $('#individual-end-time').val(response.end_time).change();
                        // only fill in the comment if clearance was changed
                        if (page.clearance_triggered) {
                            $('#individual-comment').val(response.comment);
                        }
                    }
                }
            });
        });
        page.form2.on('change', '#ranged-alt-date-type-id', function() {

            var val = $(this).val();

            if (val != page.airdate_type_simulcast_id) {
                page.alternate_date_offset_inputs.show();
            } else {
                page.alternate_date_offset_inputs.hide();
            }

        });

        // Open the delete modal
        page.titles_container.on("click", ".remove_alternate_date", function (e) {
            e.preventDefault();
            var $row = $(this).parents("tr");
            $row.addClass('deleting');
            $row.find('inputs').attr('disabled', true);
            $('.title_name').html(quoteText($row.find('.title-name:first').text()));
            $('#confirm_delete_modal').modal('show');
        });

        // Cancel a delete and remove the deleting style
        page.edit_mode_dialogs.on("click", ".delete_cancel", function (e) {
            var $row = page.titles_container.find('.deleting');
            $row.removeClass('deleting');
            $row.find('inputs').attr('disabled', false);
            $('#confirm_delete_modal').modal('hide');
        });

        // Confirm delete and remove the datatable row. Add a field to the form.
        page.edit_mode_dialogs.on("click", ".delete_confirm", function (e) {
            e.preventDefault();
            var $row = page.titles_container.find('.deleting');
            var $alternate_date_id = $row.find('a.remove_alternate_date').data('alternate_id');
            page.dataTable.fnDeleteRow($row);
            page.deleted_alternate_date_ids.append('<input name="deleted[]" value="' + $alternate_date_id + '" />');
            deleteSuccessful($row);
            $('#confirm_delete_modal').modal('hide');
        });

        // Executes after you submit a comment. Add a field to the form.
        // adds on to method in add-product.js
        page.newCommentsDialog.on("click", "#add_new_comments", function (e) {
            var $newNoteContent = $('#new_comments_dialog');
            var $this_id = $newNoteContent.find('#new_id').val();
            var $comments = $('#new_comments_'+ $this_id).val();

            page.changed_alternate_date_comments.append('<input name="updated[' + $this_id + ']" value="' + $comments + '" />');
        });
    };
    return {
        /**
         * initialize the object
         */
        "init": function () {
            _initHandlers();
        }
    };
}(jQuery);

$(document).ready(function () {
    DisneyABC.alternateDates.init();
    // update the info for the first clearance auto selected
    if ($('#form-individual-alt-dates').length) {
        // don't fire on page error
        if ($('#form-individual-alt-dates div.error').length === 0) {
            $('#individual-clearance-id').change();
        }
        if ($('#form-ranged-alt-dates div.error').length > 0) {
            $('#ranged-alt-date-type-id').change();
        }
    }
});

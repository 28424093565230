var WysiwygGeneral = function() {
    document.emojiSource = $('body').data('baseurl') + '/vendors/tam-emoji/img';
    //document.emojiType = 'unicode'; //doesn't import the image, it uses unicode character

    var defaultConfig = {
        height: 150,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'hr']],
            ['insert', ['emoji']],
            ['tool', ['undo', 'redo']],
            ['help', ['help']],
        ],
    }
    return {
        init: function(config) {
            var newConfig = Object.assign(defaultConfig,config);
            $('#' + config.idElement).summernote(newConfig);
            if ($('#' + config.idElement).is(':disabled')) {
                $('#' + config.idElement).summernote('disable');
            }
        }
    }
};
DisneyABC.macros = {
    /**
     * Catches changes in the first dropdown and applies the new values to the second one
     */
    dependentSelect: function(){
        var $dropDowns = $('.dependent-select');
        if($dropDowns.length){
            $dropDowns.change(function(){
                var $element = $('#'+$(this).data('update-target')); // the select element you are working with
                var businessLine = $(this).val();

                $element.prop('disabled',true);

                var data = $(this).data('select-options')[$(this).val()];

                DisneyABC.select2.changeOptions($element, data);
            });
        }
    }
};

$().ready(function(){
    DisneyABC.macros.dependentSelect();
});

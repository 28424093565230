(function ($, document, window, undefined) {

    var WaitState = function WaitState (elem, options) {
        this.elem = elem;
        this.$elem = $(elem);
        this.options = options;
    }

    WaitState.prototype = {
        toggle : function () {
            var spinner = this.$elem.siblings('.loading-spinner-with-overlay');
            if (spinner.length > 0) {
                spinner.toggle();
            } else {
                this.$elem.parent().css('position', 'relative');
                spinner = $('<div class="loading-spinner-with-overlay"></div>');
                spinner.insertBefore(this.$elem);
            }
        }
    }

    $.fn.waitState = function (options) {
        new WaitState(this, options).toggle();
        return this;
    }

    window.WaitState = WaitState;
})(jQuery, document, window);

/**
 * Controls for the form presets partial
 * @type {{init: Function}}
 */
DisneyABC.formPresets = (function($) {
    'use strict';

    /**
     * Whether or not init has been run
     * @type {boolean}
     */
    var initted = false;
    /**
     *
     * @type {null}
     */
    var template = null;
    /**
     * The url
     */
    var templateUrl = null;
    /**
     * The id of the currently selected template
     */
    var currentTemplateId = null;
    /**
     * Whether or not the code is being debugged
     */
    var debugging = false;
    /**
     * @type jQuery object
     */
    var $templateSelect = null;

    /**
     * The key for the form we are looking at
     * @type string
     */
    var formKey;
    /**
     * Set up the click handlers, set up the local variables
     * @public
     */
    var init = function(debugOn) {
        addClickHandlers();
        setLocalVariables();
        if (debugOn) {
            debug();
        }

        initted = true;

        if ($templateSelect.find('option:selected').length > 0) {
            apply();
        }
    };
    /**
     * Debugging mode on, sets a debugging flag thing as well
     * @private
     */
    var debug = function() {
        debugging = true;
        log('debugging mode');
        log('==================');
        $('.save-to-template').parent().css({
            'font-weight': 'bold',
            'color': 'blue'
        });
    };
    /**
     * Setup the local variables
     * @private
     */
    var setLocalVariables = function() {
        // the init function is happening before this exists?
        if (typeof DisneyABC.baseUrl === 'undefined') {
            DisneyABC.baseUrl = $('body').attr('data-baseurl').toString();
        }

        $templateSelect = $('#template_select');
        var $formTemplates = $('#form_templates');
        templateUrl = DisneyABC.baseUrl + '/form_presets/template/';
        formKey = $formTemplates.attr('data-form-key');

        //Check if a template is being used from previous page load (validation errors, etc.)
        var currentTemplateName = $('#current_template_name').val();
        if (currentTemplateName !== '') {

            $templateSelect.find('option').filter(function() {
                return ($(this).text() === currentTemplateName);
            }).prop('selected', true);

            currentTemplateId = $templateSelect.val();
        }

        if (empty(formKey)) {
            throw 'No form key defined.';
        }

    };
    /**
     * Add the click handlers for the form preset functionality
     * @private
     */
    var addClickHandlers = function() {
        $('.form-presets .apply-template').click(function(e) {
            apply();
            e.preventDefault();
        });

        $('.form-presets .create-template').click(function(e) {
            create();
            e.preventDefault();
        });

        $('.form-presets .delete-template').click(function(e) {
            remove();
            e.preventDefault();
        });

        $('.form-presets .update-template').click(function(e) {
            update();
            e.preventDefault();
        });

    };
    /**
     * Apply the selected "template" to the form
     * @private
     */
    var apply = function() {
        var templateId = $templateSelect.val();
        var templateName = $templateSelect.find(':selected').text();
        currentTemplateId = templateId;

        if (!templateId) return;

        template = $('#template-' + templateId).data('field_data');
        if (template) {
            fillForm(template);
            var $currentTemplateName = $('#current_template_name');
            $currentTemplateName.val(templateName);
            $currentTemplateName.data('id', templateId);
            $currentTemplateName.closest('div').removeClass('error');
            $currentTemplateName.closest('.control-group').find('.val_error').text('');
        } else {
            var urlString = templateUrl + templateId;
            var request = $.ajax({
                url: urlString,
                type: 'GET',
                data: {
                    templateId: templateId
                }
            });

            request.done(function(data) {
                var message = new Message(data.message);
                if (data.status === 'success') {
                    message.type = data.status;
                    message.title = data.status;
                    message.showMessage();
                    template = $.parseJSON(data.field_data);
                    fillForm(template);
                    var $currentTemplateName = $('#current_template_name');
                    $currentTemplateName.val(templateName);
                    $currentTemplateName.data('id', templateId);
                    $currentTemplateName.closest('div').removeClass('error');
                    $currentTemplateName.closest('.control-group').find('.val_error').text('');
                    return false;
                } else {
                    message.type = data.status;
                    message.title = data.status;
                    message.showMessage();
                    return false;
                }

            });

            // Fail
            request.fail(function(jqXHR, data) {
                var message = new Message(data.message);
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
                return false;
            });
        }
    };
    /**
     * Fill out the form with the contents of the "template"
     * @param template
     * @private
     */
    var fillForm = function(template) {

        if (typeof template === 'string') {
            template = $.parseJSON(template);
        }

        for (var i in template) {
            if (template.hasOwnProperty(i)) {
                log('setting ' + i + ' to ' + template[i]);
                var $formElement = $('#' + i);
                if ($formElement.length < 1) {
                    log('could not find ' + i);
                }

                // fires the change event so that select2 picks it up
                $formElement.val(template[i]).change();
                var $row = $formElement.parents('.audio-channel');
                if ($row.hasClass('hide')) {
                    $row.show();
                    $row.find('input,select').prop('disabled', false);
                }
            }
        }

        if ($('.audio-channel:not(.hide)').length > 0) {
            $('#audio-channel-widget').find('.extra-fields').prop('disabled', false);
        }

        $('ul.save-to-template').each(function() {
            if (this.id in template && template[this.id].length > 0) {

                //loop through each value recorded in the template, and check the appropriate checkbox.
                for (i = 0; i < template[this.id].length; ++i) {
                    var value = template[this.id][i];
                    $(this).find('input[value="' + value + '"]').prop('checked', true);
                }
            }
        });
    };
    /**
     * Create and save a new preset
     * @private
     */
    var create = function() {
        var templateData;
        var templateName;
        var urlString;

        // clear the errors
        var $newTemplateName = $('#new_template_name');
        $newTemplateName.closest('div').removeClass('error');
        $newTemplateName.closest('.control-group').find('.val_error').text('');

        if ($newTemplateName.val() === '') {
            $newTemplateName.closest('div').addClass('error');
            $newTemplateName.closest('.control-group').find('.val_error').text('Template name required.');
            return;
        }

        template = buildTemplateFromPage();

        templateData = JSON.stringify(template);
        templateName = $newTemplateName.val();

        urlString = templateUrl;
        var request = $.ajax({
            url: urlString,
            type: 'POST',
            data: {
                templateData: templateData,
                templateName: templateName,
                templateFormKey: formKey
            }
        });

        request.done(function(data) {

            //data = $.parseJSON(data);
            var message = new Message(data.message);
            if (data.status === 'success') {
                message.type = data.status;
                message.title = data.status;
                message.showMessage();

                var opt = $(document.createElement('option'));
                $(opt).text(data.name);
                $(opt).attr('id', 'template-' + data.id);
                $(opt).data('id', data.id);
                $(opt).attr('value', data.id);
                $(opt).data('field_data', $.parseJSON(data.field_data));

                $templateSelect.append(opt);
                $('#no_templates_warning').hide();
                $('#template_management').show();
                currentTemplateId = data.id;
                var $currentTemplateName = $('#current_template_name');
                $currentTemplateName.val(data.name);
                $currentTemplateName.data('id', data.id);
                $templateSelect.change();
            } else {
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
            }

            return false;
        });

        // Fail
        request.fail(function(jqXHR, data) {
            var message = new Message(data.message);
            message.type = data.status;
            message.title = data.status;
            message.showMessage();
            return false;
        });
    };
    /**
     * Delete a template from the list
     * @private
     */
    var remove = function() {

        if (!confirm('Really delete template?')) {
            return;
        }

        var templateId = $templateSelect.val();
        currentTemplateId = templateId;

        //get template

        var urlString = templateUrl + templateId;
        var request = $.ajax({
            url: urlString,
            type: 'DELETE'
        });

        request.done(function(data) {
            var message = new Message(data.message);
            if (data.status === 'success') {
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
                $('#template-' + data.id).remove();
                $templateSelect.change();
                var $currentTemplateName = $('#current_template_name');
                if (data.id === $currentTemplateName.data('id')) {
                    $currentTemplateName.data('id', '');
                    $currentTemplateName.val('');
                }

                if (!$('#template_select option').length) {
                    $('#template_management').hide();
                }

                return false;

            } else {
                message.type = data.status;
                message.title = data.status;
                message.showMessage();
                return false;
            }

        });

        // Fail
        request.fail(function(jqXHR, data) {
            var message = new Message(data.message);
            message.type = data.status;
            message.title = data.status;
            message.showMessage();
            return false;
        });
    };
    /**
     * Update the template from the fields that are filled out
     * @private
     */
    var update = function() {
        var $currentTemplateName = $('#current_template_name');

        $currentTemplateName.closest('div').removeClass('error');
        $currentTemplateName.closest('.control-group').find('.val_error').text('');

        // empty text box
        if ($currentTemplateName.val() === '') {
            $currentTemplateName.closest('div').addClass('error');
            $currentTemplateName.closest('.control-group').find('.val_error').text('Template name required.');
            return;
        }

        // no template selected
        if (currentTemplateId === null) {
            var message = new Message();
            message.type = 'warning';
            message.title = 'No template selected';
            message.showMessage();
            $currentTemplateName.closest('div').addClass('error');
            $currentTemplateName.closest('.control-group').find('.val_error').text('No template selected');
            return;
        }

        var templateData;
        var templateName;
        var urlString;
        var fileSubtypeId;
        template = buildTemplateFromPage();

        templateData = JSON.stringify(template);
        templateName = $currentTemplateName.val();
        fileSubtypeId = $('#file_subtype_id').val();

        urlString = templateUrl + currentTemplateId;

        var request = $.ajax({
            url: urlString,
            type: 'PUT',
            data: {
                templateData: templateData,
                fileSubtypeId: fileSubtypeId,
                templateName: templateName
            }
        });

        request.done(function(data) {
            var message = new Message(data.message);
            if (data.status === 'success') {
                message.type = data.status;
                message.title = data.status;
                message.showMessage();

                $('#template-' + currentTemplateId).data('json', templateData);
                $('#template-' + currentTemplateId).text(templateName);
                $('#template-' + currentTemplateId).text(templateName);
                $templateSelect.change();

                $currentTemplateName.closest('div').removeClass('error');
                $('#current_template_name').closest('.control-group').find('.val_error').text('');

                return false;
            } else {
                $('#current_template_name').closest('div').addClass('error');
                $('#current_template_name').closest('.control-group').find('.val_error').text(data.message);

                message.type = data.status;
                message.title = data.status;
                message.showMessage();

                return false;
            }
        });

        // Fail
        request.fail(function(jqXHR, data) {
            var message = new Message(data.message);
            message.type = data.status;
            message.title = data.status;
            message.showMessage();
            return false;
        });

    };
    /**
     * Build a template from the currently filled out fields
     * @returns {{}}
     * @private
     */
    var buildTemplateFromPage = function() {
        var template = {};
        $('.save-to-template:enabled').each(function() {
            var key = $(this).attr('id');
            var value = $(this).val();
            if (key && value) {
                template[key] = value;
            }
        });

        // @todo = I don't know what this is... why not just add the save-to-template class to the checkboxes?
        //add checkablelists to template. :enabled only works on form inputs so will have been ignored by previous loop.
        $('ul.save-to-template').filter(function() {
            return $(this).closest('.hide').length < 1;
        }).each(function() {
            var checkableValues = [];
            var key = $(this).attr('id');
            $('#' + $(this).attr('id') + ' input:checked').each(function() {
                checkableValues.push(this.value);
            });

            if (checkableValues.length > 0) {
                template[key] = checkableValues;
            }
        });

        return template;
    };
    /**
     * Log a message to the console if this is in debug mode
     * @param message
     * @private
     */
    var log = function(message) {
        if (debugging) {
            console.info(message);
        }
    };

    return {
        'init': function() {
            if (!initted && $('#template_select').length) {
                init();
            }
        }
    };
}(jQuery));

(function() {
    'use strict';
    DisneyABC.formPresets.init();
}());

var NotificationDetail = (function() {
    var initWysiwygField = function() {
        var wysiwygGeneral = new WysiwygGeneral();
        wysiwygGeneral.init({idElement:'notification-body-summernote'});
    };

    var loadTemplateFromTemplateDetail = function() {
        if($('#id-template-from-templates').length) {
            $('#notification-template').val($('#id-template-from-templates').val());
            $('#notification-template').change();
        }
    }

    var assignEventLoadValuesByTemplate = function() {
        $('#notification-template').change(function() {
            var $subjectElement = $('#notification-subject');
            var $bodyElement = $('#notification-body-summernote');

            $subjectElement.val("");
            $bodyElement.summernote('code', "");

            if($(this).val()) {
                var loading = $('<div/>',{'class':'loading-spinner-with-overlay'});
                $('body').append(loading);

                $.get('/admin/notifications/templates/id/' + $(this).val())
                .success(function(response) {
                    var subject = "";
                    var body = "";

                    if(!Array.isArray(response)){
                        subject = response.subject;
                        body = response.body;
                    }

                    $subjectElement.val(subject).change();
                    $bodyElement.summernote('code', body);

                    $('.loading-spinner-with-overlay').remove();
                })
                .fail(function(){
                    alert('Oops an error ocurred');
                    $('.loading-spinner-with-overlay').remove();
                });
            }
        });
    };

    var assignEventCheckboxes = function() {
        if (!$('#notification-email-delivery').is(':checked')) {
            $('.options-email').hide();
        }

        if (!$('#notification-appbanner').is(':checked')) {
            $('.options-appbanner').hide();
        }
        
        $('#notification-email-delivery').click(function() {
            showHideOptions(this,'.options-email');
            validateShowButtons();
        });

        $('#notification-appbanner').click(function() {
            showHideOptions(this,'.options-appbanner');
            validateShowButtons();
        });
    };

    var validateShowButtons = function() {
        var disableButtons = false;
        if(!$('#notification-email-delivery').is(':checked') && !$('#notification-appbanner').is(':checked')){
            disableButtons = true;
        }
        $('#action-buttons')[disableButtons ? 'hide' : 'show']();
        enableDisabledElements('#action-buttons [type="button"]',disableButtons);
    }
    
    var assignEventRadios = function() {
        $('#deliver-immediately').click(function() {
            enableDisabledElements('.schedule-delivery',true);
        });
        $('#schedule').click(function() {
            enableDisabledElements('.schedule-delivery',false);
        });
    };

    var showHideOptions = function(checkbox,optionsSelector){
        var animation = 'slideUp';
        var disable = true;
        if($(checkbox).is(':checked')){
            animation = 'slideDown';
            disable = false;   
        }
        $(optionsSelector)[animation]();
        enableDisabledElements('.' + $(checkbox).data('elements-to-enable'),disable);
    };

    return {
        initDetail: function() {
            initWysiwygField();
            assignEventLoadValuesByTemplate();
            assignEventCheckboxes();
            assignEventRadios();
            loadTemplateFromTemplateDetail();
            NotificationDetailFilters.init();
            NotificationDetailButtons.init();
            readOnlyMode();
        }
    }
})();
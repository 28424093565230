$().ready(function() {
    'use strict';
    (function() {
        var $form = $('#add_circle_to_product');
        var totalClearancesBeingCreated = 0;
        var MAX_CLEARANCES = 7500;
        var $submit = $form.find('input[type="submit"]');
        var $clearanceCount = $form.find('.clearance-count:first');

        $form.find('input').on('change', function() {
            var checked = $form.find('input:checked').length;

            if ($form.find('input[name="all_clearances"]').is(':checked')) {
                checked -= 1;
            }

            var circles = $('.select2-search-choice').length;

            totalClearancesBeingCreated = checked * circles;

            if (totalClearancesBeingCreated > 0) {
                $clearanceCount.text('Creating ' + totalClearancesBeingCreated + ' windows.');
            }

            if (totalClearancesBeingCreated > MAX_CLEARANCES) {
                $submit.attr('disable', true)
                    .addClass('disabled');

                $clearanceCount.addClass('error')
                    .removeClass('success')
                    .text('You cannot create more than ' + MAX_CLEARANCES + ' windows at a time.');

            } else {
                $submit.attr('disable', false)
                    .removeClass('disabled');

                $clearanceCount.removeClass('error')
                    .addClass('success')
                    .text('This will create ' + totalClearancesBeingCreated + ' windows.');
            }
        });

        $form.on('submit', function(e) {
            if (totalClearancesBeingCreated > MAX_CLEARANCES) {
                e.preventDefault();
            }
        });
    })();
});

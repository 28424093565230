DisneyABC.AudioChannels = (function($) {
    'use strict';

    /**
     * Whether or not we have rows
     * @type {boolean}
     */
    var hasRows = false;

    /**
     * The widget that we are working on
     * @type {*|HTMLElement}
     */
    var $audioChannelWidget = $('#audio-channel-widget');

    /**
     * The default language id
     */
    var defaultLanguageId;

    /**
     * Attach event listeners to the audio
     */
    function addListeners() {
        $audioChannelWidget.find('.add-another-channel')
            .click(function() {
                addChannel();
            });

        $audioChannelWidget.find('.channel-list .remove')
            .click(function() {
                removeChannel($(this));
            });

        $audioChannelWidget.find('.clear-all-channels')
            .click(function() {
                clearAllChannels();
            });

        $audioChannelWidget.find('.language-toggle')
            .change(function() {
                setDefaultLanguageId();
                updateChannelLanguages();
            });

        $audioChannelWidget.find('.apply-audio-template')
            .click(function() {
                applyAudioTemplate($(this));
            });
    }

    /**
     * Apply an audio template to the channels
     * @param $button
     */
    function applyAudioTemplate($button) {
        var template = $button.data('json');
        clearAllChannels();

        for (var i = 0; i < template.channels.length; ++i) {
            addChannel();
            applyChannelData(template.channels[i]);
        }

        updateChannelLanguages();
    }

    /**
     * Apply a row of channel data
     * @param data
     */
    function applyChannelData(data) {
        var $row = $audioChannelWidget
            .find('.channel-list .audio-channel:not(.hide):last');

        $row.find('.audio_type_id')
            .val(data.audio_type_id)
            .change();
        $row.find('.audio_mix_id')
            .val(data.audio_mix_id)
            .change();
        $row.find('.audio_language_id')
            .val(defaultLanguageId)
            .change();
    }

    /**
     * Add a new row to the list
     */
    function addChannel() {
        var $row = $audioChannelWidget
            .find('.channel-list .audio-channel.hide:first');
        $row.show();
        $row.find('.audio_language_id')
            .val(defaultLanguageId)
            .change();

        rowChangeEvent();
    }

    /**
     * Hide a row from the list
     * @param $removeButton
     */
    function removeChannel($removeButton) {
        var channelId = $removeButton.data('remove-channel');
        $audioChannelWidget
            .find('.audio-channel[data-channel-id="' + channelId + '"]')
            .addClass('hide')
            .hide();
        rowChangeEvent();
    }

    /**
     * Update all of the language inputs
     */
    function updateChannelLanguages() {
        $audioChannelWidget
            .find('.audio-channel:not(.hide) .audio_language_id')
            .val(defaultLanguageId)
            .change();
    }

    /**
     * If the number of rows changes, this should be called to enable / disable the extra fields
     */
    function rowChangeEvent() {
        hasRows = getRowCount() > 0;
        setDefaultLanguageId();
        toggleRowFields();
        toggleExtraFields();
    }

    /**
     * Set the default language id for the widget
     */
    function setDefaultLanguageId() {
        defaultLanguageId = $audioChannelWidget.find('select.language-toggle').val();
    }

    /**
     * Toggle all of the row fields, clear out the values for the hidden ones then disable them
     */
    function toggleRowFields() {
        $audioChannelWidget
            .find('.channel-list .audio-channel.hide')
            .find('input,select')
            .attr('disabled', true);
        $audioChannelWidget
            .find('.channel-list .audio-channel:not(.hide)')
            .find('input,select')
            .attr('disabled', false);
    }

    /**
     * Return the number of visible rows (up to 12 are possible)
     * @returns int
     */
    function getRowCount() {
        return $audioChannelWidget
            .find('.channel-list .audio-channel:not(.hide)')
            .length;
    }

    /**
     * Toggle the status of the extra fields, this will be fired on row change events
     */
    function toggleExtraFields() {
        if (hasRows > 0) {
            $audioChannelWidget
                .find('.extra-fields')
                .prop('disabled', false);
        } else {
            $audioChannelWidget
                .find('.extra-fields')
                .prop('disabled', true);
        }
    }

    /**
     * Clear all of the audio channels out
     */
    function clearAllChannels() {
        $audioChannelWidget
            .find('.audio-channel')
            .addClass('hide')
            .hide();
        rowChangeEvent();
    }

    return {
        init: function() {
            if ($('#audio-channel-widget').length > 0) {
                addListeners();
                rowChangeEvent();
            }
        }
    };
})(jQuery);

$('document').ready(function() {
    'use strict';
    DisneyABC.AudioChannels.init();
});
